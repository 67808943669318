import { axiosInstance } from "@/api/api";
import {
  CreateDirectorySchemaType,
  EditDirectoryType,
} from "@/schemas/directory.schema";
import { DirectoryI, GetPermissionsI } from "@/types/files";
import { DirectoryDirI } from "@/types/files";

export type getDirectoryPermissionsResponseT = {
  users: string[];
  groups: string[];
};

export const getDirectory = async (
  directoryId: string,
): Promise<DirectoryI> => {
  return await axiosInstance.get("/api/fs/dir", {
    params: { id: directoryId },
  });
};

export const getSharedDirectory = async (): Promise<any> => {
  return await axiosInstance.get("/api/fs/dir/getShared");
};

export const getDirectoryPermissions = async (
  id?: string,
): Promise<GetPermissionsI> => {
  return await axiosInstance.get("/api/fs/dir/getPermissions", {
    params: { id },
  });
};

export const createDirectory = async (
  data: CreateDirectorySchemaType,
): Promise<DirectoryDirI> => {
  return await axiosInstance.put("/api/fs/dir", data);
};

export const editDirectory = async (
  data: EditDirectoryType,
): Promise<string> => {
  return await axiosInstance.post("/api/fs/dir", data);
};

export const deleteDirectory = async (id: string): Promise<string> => {
  return await axiosInstance.delete("/api/fs/dir", { data: { id } });
};

type getBase64T = {
  fileType: string;
  base64: string;
  clear?: boolean;
};

export function getBase64({
  fileType,
  base64,
  clear = false,
}: getBase64T): string {
  if (clear) {
    return `data:${fileType + ";base64," + base64}`;
  }
  return `url('data:${fileType + ";base64," + base64}')`;
}

export type { getBase64T };

import GroupElementHeader from "@/components/features/groups/layout/GroupElementHeader";
import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer/Drawer";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { ChevronRight } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";

type GroupGradesProps = {
  className?: string;
};

export default function GroupGrades(props: GroupGradesProps) {
  const { className } = props;
  const [open, setOpen] = useState<boolean>(false);

  const handleNavigate = () => {};

  const onOpenCb = useCallback(() => {
    setOpen(true);
  }, []);

  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setData([]);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const [dataSliced, isSliced] = useMemo(() => {
    const res = data.slice(0, 5);
    return [res, res.length < data.length];
  }, [data]);

  const noDataRender = useMemo(() => {
    return (
      <div className={"flex h-full flex-col items-center justify-center gap-2"}>
        <p className={"text-sm text-fg-muted"}>
          Brak przypisanych ocen do grupy
        </p>
      </div>
    );
  }, []);

  return (
    <>
      <GroupGradesDialog open={open} setOpen={setOpen} data={data} />
      <div
        className={cn(
          "flex flex-col rounded-lg border-1 border-border bg-bg-container",
          className,
        )}
      >
        <GroupElementHeader
          label={"Ostatnie oceny"}
          callback={handleNavigate}
        />
        <div
          className={
            "flex flex-col items-center gap-2 overflow-auto px-4 pb-4 sm:snap-x sm:snap-mandatory sm:scroll-pl-4 sm:flex-row sm:overflow-x-auto"
          }
        >
          {noDataRender}
          {dataSliced.map((_, index) => (
            <Skeleton
              key={index}
              className="h-12 min-h-12 w-full min-w-[200px] snap-start snap-always rounded-sm"
            />
          ))}
          <Button
            onClick={onOpenCb}
            disabled={!isSliced}
            className={cn(
              "w-full shrink-0 sm:w-fit",
              isSliced ? "opacity-100" : "!opacity-0",
            )}
            size={"sm"}
            variant={"ghost"}
            variantColor={"muted"}
            iconPosition={"right"}
            icon={<ChevronRight />}
          >
            Więcej
          </Button>
        </div>
      </div>
    </>
  );
}

type GroupGradesDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: any[];
};

function GroupGradesDialog(props: GroupGradesDialogProps) {
  const { open, setOpen, data } = props;
  const breakpoint = useMediaQueryHook("sm");

  const onCloseCb = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (breakpoint) {
    return (
      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>Ostatnie oceny</DrawerTitle>
          </DrawerHeader>
          <DrawerBody className={"flex flex-col gap-2"}>
            {data.map((_, index) => (
              <Skeleton
                key={index}
                className="h-14 min-h-14 w-full min-w-[200px] snap-start snap-always rounded-sm"
              />
            ))}
          </DrawerBody>
          <DrawerFooter>
            <Button onClick={onCloseCb} variant={"flat"} variantColor={"muted"}>
              Zamknij
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className={"max-w-[30rem]"}>
        <DialogHeader>
          <DialogTitle>Ostatnie oceny</DialogTitle>
        </DialogHeader>
        <DialogBody className={"flex flex-col gap-2"}>
          {data.map((_, index) => (
            <Skeleton
              key={index}
              className="h-14 min-h-14 w-full min-w-[200px] snap-start snap-always rounded-sm"
            />
          ))}
        </DialogBody>
        <DialogFooter>
          <Button onClick={onCloseCb} variant={"flat"} variantColor={"muted"}>
            Zamknij
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

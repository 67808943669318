import { GetMeetingsPropsT } from "@/api/endpoints/meetings";
import { useGetAssignedMeetingsInfiniteQuery } from "@/api/queries/meetingsQuery";
import CalendarCard from "@/components/features/calendar/CalendarCard";
import { endOfDay, startOfDay } from "date-fns";
import { useState } from "react";

export default function HomeCalendarCard() {
  const [queryParams, setQueryParams] = useState<GetMeetingsPropsT>({
    after: startOfDay(new Date()).toISOString(),
    before: endOfDay(new Date()).toISOString(),
    sortOrder: "ASC",
    page: 0,
    pageSize: 15,
  });

  const query = useGetAssignedMeetingsInfiniteQuery(queryParams);

  return (
    <CalendarCard
      query={query}
      setQueryParams={setQueryParams}
      defaultType={"day"}
      defaultScheduleParams={{
        before: endOfDay(
          new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
        ).toISOString(),
      }}
      renderNavigateContextMenu={undefined}
      className={
        "max-viewport-height sticky top-4 min-h-[356px] w-[24rem] max-w-[24rem]"
      }
    />
  );
}

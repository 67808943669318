import { httpErrorHandler } from "@/api/api";
import { useGetGroupQuery } from "@/api/queries/groupsQueries";
import GroupGrades from "@/components/features/groups/GroupGrades";
import GroupLessons from "@/components/features/groups/GroupLessons";
import GroupMeetings from "@/components/features/groups/GroupMeetings";
import GroupMembers from "@/components/features/groups/GroupMembers";
import GroupQuickMenu from "@/components/features/groups/GroupQuickMenu";
import GroupTasks from "@/components/features/groups/GroupTasks";
import GroupHeader from "@/components/features/groups/layout/GroupHeader";
import { useToast } from "@/components/ui/toast/useToast";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export default function GroupPage() {
  const breakpointSm = useMediaQueryHook("sm");

  const { lvl } = useCredentials();

  const { id } = useParams();
  const { toast } = useToast();

  const { data, isError, error } = useGetGroupQuery(Number(id));

  const isUserView = useMemo(() => {
    return lvl <= 0;
  }, [lvl]);

  useEffect(() => {
    if (isError) {
      const { title, detail } = httpErrorHandler(error);
      toast({
        title: title,
        description: detail,
        variant: "destructive",
      });
    }
  }, [error, isError, toast]);

  if (breakpointSm) {
    return (
      <div className={"default-page-col flex flex-col gap-4"}>
        <GroupHeader className={""} group={data} />
        <GroupQuickMenu group={data} className={"h-min shrink-0"} />
        <GroupMeetings
          groupId={Number(id)}
          group={data}
          className={"sm:h-[456px] sm:min-h-[456px]"}
        />
        <GroupLessons className={"w-full"} />
        <GroupTasks className={"w-full"} />
        {isUserView ? <GroupGrades className={"w-full"} /> : null}
        <GroupMembers groupId={Number(id)} className={"w-full"} />
      </div>
    );
  }

  return (
    <div className={"default-page-col relative flex flex-1 flex-col gap-4"}>
      <div
        className={
          "grid min-h-full w-full grid-cols-12 grid-rows-[min-content_min-content_1fr] gap-4 overflow-y-auto overflow-x-hidden"
        }
      >
        <GroupHeader className={"col-span-8 md_lg:col-span-12"} group={data} />
        <GroupMeetings
          groupId={Number(id)}
          group={data}
          className={cn(
            "col-span-4 row-span-4 h-full min-h-[456px] shrink-0 overflow-hidden md_lg:col-span-12",
            isUserView && "row-span-5",
          )}
        />

        <GroupQuickMenu
          group={data}
          className={cn(
            "col-span-8 row-start-2 w-full md_lg:col-span-12",
            isUserView && "col-span-4",
          )}
        />

        <GroupLessons
          className={cn(
            "col-span-4 row-span-3 h-full min-h-[256px] w-full overflow-hidden md_lg:col-span-6 sm:col-span-12",
            isUserView && "row-span-4",
          )}
        />
        <GroupTasks
          className={cn(
            "col-span-4 row-span-3 h-full min-h-[256px] w-full overflow-hidden md_lg:col-span-6 sm:col-span-12",
            isUserView && "md_lg:row-span-4",
          )}
        />
        {isUserView ? (
          <GroupGrades
            className={
              "col-span-4 row-span-2 row-start-2 w-full md_lg:col-span-12 md_lg:row-start-8 sm:col-span-12"
            }
          />
        ) : null}

        <GroupMembers
          groupId={Number(id)}
          className={"col-span-4 col-start-9 md_lg:col-span-12"}
        />
      </div>
    </div>
  );
}

type Card = {
  id: number;
  content: JSX.Element | React.ReactNode | string;
  className: string;
  thumbnail?: string;
};

export const LayoutGrid = ({ cards }: { cards: Card[] }) => {
  const [selected, setSelected] = useState<Card | null>(null);
  const [lastSelected, setLastSelected] = useState<Card | null>(null);

  const handleClick = (card: Card) => {
    setLastSelected(selected);
    setSelected(card);
  };

  const handleOutsideClick = () => {
    setLastSelected(selected);
    setSelected(null);
  };

  return (
    <div className="relative mx-auto grid h-full w-full max-w-7xl grid-cols-1 gap-4 p-10 md:grid-cols-3">
      {cards.map((card, i) => (
        <div key={i} className={cn(card.className, "")}>
          <motion.div
            onClick={() => handleClick(card)}
            className={cn(
              card.className,
              "relative overflow-hidden",
              selected?.id === card.id
                ? "absolute inset-0 z-50 m-auto flex h-1/2 w-full cursor-pointer flex-col flex-wrap items-center justify-center rounded-lg md:w-1/2"
                : lastSelected?.id === card.id
                  ? "bg-white z-40 h-full w-full rounded-xl"
                  : "bg-white h-full w-full rounded-xl",
            )}
            layoutId={`card-${card.id}`}
          >
            {selected?.id === card.id && <SelectedCard selected={selected} />}
            <ImageComponent card={card} />
          </motion.div>
        </div>
      ))}
      <motion.div
        onClick={handleOutsideClick}
        className={cn(
          "bg-black absolute left-0 top-0 z-10 h-full w-full opacity-0",
          selected?.id ? "pointer-events-auto" : "pointer-events-none",
        )}
        animate={{ opacity: selected?.id ? 0.3 : 0 }}
      />
    </div>
  );
};

const ImageComponent = ({ card }: { card: Card }) => {
  return (
    <motion.img
      layoutId={`image-${card.id}-image`}
      src={card.thumbnail}
      height="500"
      width="500"
      className={cn(
        "absolute inset-0 h-full w-full object-cover object-top transition duration-200",
      )}
      alt="thumbnail"
    />
  );
};

const SelectedCard = ({ selected }: { selected: Card | null }) => {
  return (
    <div className="relative z-[60] flex h-full w-full flex-col justify-end rounded-lg bg-transparent shadow-2xl">
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 0.6,
        }}
        className="bg-black absolute inset-0 z-10 h-full w-full opacity-60"
      />
      <motion.div
        layoutId={`content-${selected?.id}`}
        initial={{
          opacity: 0,
          y: 100,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        exit={{
          opacity: 0,
          y: 100,
        }}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
        className="relative z-[70] px-8 pb-4"
      >
        {selected?.content}
      </motion.div>
    </div>
  );
};

import { getWeek } from "@/utils/dateFormat";
import { format } from "date-fns";
import pl from "date-fns/locale/pl";
import { useMemo } from "react";

interface CalendarContentNavMonthProps {
  date: Date;
}

export default function CalendarHeaderMonth({
  date,
}: CalendarContentNavMonthProps) {
  const weekArray = useMemo(() => getWeek(date), [date]);
  const content = weekArray.map((date) => {
    const weekDay = format(date, "EEE", { locale: pl });
    return (
      <div
        className={"w-full text-center text-sm text-fg-secondary"}
        key={date.toString()}
      >
        {weekDay}
      </div>
    );
  });
  return <div className={"flex flex-row"}>{content}</div>;
}

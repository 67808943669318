import { RefObject, useEffect, useState } from "react";

interface SwipeStateI {
  x: number;
  y: number;
  isSwiping: boolean;
  direction: string;
}

const useSwipe = <T extends HTMLElement>(
  ref: RefObject<T>,
  thresholdPX: number = 100,
): SwipeStateI => {
  const [swipeState, setSwipeState] = useState<SwipeStateI>({
    x: 0,
    y: 0,
    isSwiping: false,
    direction: "",
  });

  useEffect(() => {
    const element = ref.current;

    const handleTouchStart = (event: TouchEvent): void => {
      setSwipeState({
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
        isSwiping: true,
        direction: "",
      });
    };
    const handleMouseStart = (event: MouseEvent): void => {
      setSwipeState({
        x: event.clientX,
        y: event.clientY,
        isSwiping: true,
        direction: "",
      });
    };

    const handleTouchMove = (event: TouchEvent): void => {
      if (!swipeState.isSwiping) return;

      const x = event.touches[0].clientX;
      const y = event.touches[0].clientY;
      const dx = x - swipeState.x;
      const dy = y - swipeState.y;
      const absX = Math.abs(dx);
      const absY = Math.abs(dy);

      if (absX < thresholdPX && absY < thresholdPX) return;

      setSwipeState({
        x,
        y,
        isSwiping: false,
        direction:
          absX > absY ? (dx > 0 ? "right" : "left") : dy > 0 ? "down" : "up",
      });
    };
    const handleMouseMove = (event: MouseEvent): void => {
      if (!swipeState.isSwiping) return;

      const x = event.clientX;
      const y = event.clientY;
      const dx = x - swipeState.x;
      const dy = y - swipeState.y;
      const absX = Math.abs(dx);
      const absY = Math.abs(dy);

      if (absX < thresholdPX && absY < thresholdPX) return;

      setSwipeState({
        x,
        y,
        isSwiping: false,
        direction:
          absX > absY ? (dx > 0 ? "right" : "left") : dy > 0 ? "down" : "up",
      });
    };

    const handleEnd = (): void => {
      setSwipeState({ x: 0, y: 0, isSwiping: false, direction: "" });
    };

    if (element) {
      element.addEventListener("touchstart", handleTouchStart, {
        passive: true,
      });
      element.addEventListener("touchmove", handleTouchMove, { passive: true });
      element.addEventListener("touchend", handleEnd, { passive: true });
      element.addEventListener("mousedown", handleMouseStart, {
        passive: true,
      });
      element.addEventListener("mousemove", handleMouseMove, { passive: true });
      element.addEventListener("mouseup", handleEnd, { passive: true });
    }

    return () => {
      if (element) {
        element.removeEventListener("touchstart", handleTouchStart);
        element.removeEventListener("touchmove", handleTouchMove);
        element.removeEventListener("touchend", handleEnd);
        element.removeEventListener("mousedown", handleMouseStart);
        element.removeEventListener("mousemove", handleMouseMove);
        element.removeEventListener("mouseup", handleEnd);
      }
    };
  }, [ref, thresholdPX, swipeState.isSwiping, swipeState.x, swipeState.y]);

  return swipeState;
};

export default useSwipe;

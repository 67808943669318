import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import React from "react";

const badgeVariants = cva(
  "relative inline-flex w-fit h-fit whitespace-nowrap  font-medium truncate items-center hover:opacity-80 transition-opacity duration-200",
  {
    variants: {
      variant: {
        brand: "[&>*]:stroke-fg-brand bg-bg-brand-subtle text-fg-brand",
        brandFilled: "[&>*]:stroke-fg-brand-on bg-bg-brand text-fg-brand-on",
        muted: "[&>*]:stroke-fg-muted bg-bg-muted-subtle text-fg-muted",
        outline:
          "[&>*]:stroke-fg-primary bg-transparent text-fg-primary border-1 border-border",
        accent: "[&>*]:stroke-fg-accent bg-bg-accent-subtle text-fg-accent",
        success: "[&>*]:stroke-fg-success bg-bg-success-subtle text-fg-success",
        warning: "[&>*]:stroke-fg-warning bg-bg-warning-subtle text-fg-warning",
        destructive:
          "[&>*]:stroke-fg-destructive bg-bg-destructive-subtle text-fg-destructive",
      },
      size: {
        sm: "px-2 gap-1 py-0.5 text-xs rounded-sm [&>svg]:flex-shrink-0 [&>svg]:w-3 [&>svg]:h-3",
        md: "px-3 gap-2 py-1 text-xs rounded-sm [&>svg]:flex-shrink-0 [&>svg]:w-4 [&>svg]:h-4",
      },
    },
    defaultVariants: {
      size: "sm",
      variant: "brand",
    },
  },
);

interface badgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  children: React.ReactNode;
  className?: string;
  contentLeft?: React.ReactNode;
  contentRight?: React.ReactNode;
}

const Badge = React.forwardRef<HTMLDivElement, badgeProps>(
  (
    { children, className, variant, size, contentLeft, contentRight, ...props },
    ref,
  ) => {
    return (
      <div
        role={"button"}
        ref={ref}
        className={cn(badgeVariants({ size, variant }), className)}
        {...props}
      >
        {contentLeft}
        {children}
        {contentRight}
      </div>
    );
  },
);
Badge.displayName = "Badge";

export { Badge, badgeVariants };

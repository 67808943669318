import UserMassImport from "@/components/features/manage/manage-users/UserMassImport";
import UserMassImportContent from "@/components/features/manage/manage-users/UserMassImportContent";
import { UserMassImportError } from "@/components/features/manage/manage-users/UserMassImportError";
import TanstackTableDynamic from "@/components/features/manage/tanstack-table-dynamic/TanstackTableDynamic";
import TanstackTableDynamicCell from "@/components/features/manage/tanstack-table-dynamic/TanstackTableDynamicCell";
import { TanstackTableDynamicEditCell } from "@/components/features/manage/tanstack-table-dynamic/TanstackTableDynamicEditCell";
import { Badge } from "@/components/ui/badge/Badge";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb/Breadcrumb";
import { Button } from "@/components/ui/button/Button";
import { AlertDialog } from "@/components/ui/dialog/AlertDialog";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog/Dialog";
import { Separator } from "@/components/ui/separator/Separator";
import { phoneRegex } from "@/lib/regex";
import { ColumnDef } from "@tanstack/react-table";
import { format, isValid } from "date-fns";
import { Check, ChevronLeft, HelpCircle, Import } from "lucide-react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ValidationError, object, string } from "yup";

const validationObject = object({
  name: string().required("Imię jest wymagane"),
  surname: string().required("Nazwisko jest wymagane"),
  email: string().email("Niepoprawny email").required("Email jest wymagany"),
  phone: string()
    .matches(phoneRegex, {
      message: "Niepoprawny numer telefonu",
      excludeEmptyString: true,
    })
    .trim()
    .optional(),
  birthdate: string().optional(),
});

export type UsersMassImportRowT = {
  id: string;
  lvl: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  birthdate: string;
};

export type ErrorsT = {
  errors: string[];
  data: UsersMassImportRowT;
  index: number;
};

export default function ManageUsersMassImport() {
  const navigate = useNavigate();

  const [openSubmitDialog, setOpenSubmitDialog] = useState<boolean>(false);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);

  const [userList, setUserList] = useState<UsersMassImportRowT[]>([]);
  const [errors, setErrors] = useState<ErrorsT[]>([]);

  const handleOpenImportDialog = () => {
    setOpenImportDialog(true);
  };

  const handleSaveChanges = () => {
    const isError = checkErrors();
    if (isError) {
      setOpenErrorDialog(true);
    } else {
      setOpenSubmitDialog(true);
    }
  };

  const checkErrors = () => {
    const errorsArray: ErrorsT[] = [];
    userList.forEach((user, index) => {
      try {
        validationObject.validateSync(user, { abortEarly: false });
      } catch (error) {
        if (error instanceof ValidationError) {
          errorsArray.push({ errors: error.errors, data: user, index: index });
        }
      }
    });
    setErrors(errorsArray);
    return errorsArray.length !== 0;
  };

  const columns: ColumnDef<UsersMassImportRowT>[] = useMemo(
    () => [
      {
        id: "lvl",
        accessorKey: "lvl",
        cell: (props) => (
          <TanstackTableDynamicCell
            {...props}
            placeholder={"Stanowisko"}
            type={"select"}
            options={[
              { value: "0", label: "Uczeń" },
              { value: "1", label: "Nauczyciel" },
              { value: "2", label: "Administrator" },
            ]}
            viewCell={() => {
              const value = props.getValue();

              if (value === "1")
                return <Badge variant={"brand"}>Nauczyciel</Badge>;
              if (value === "2")
                return <Badge variant={"accent"}>Administrator</Badge>;

              return <Badge variant={"muted"}>Uczeń</Badge>;
            }}
          />
        ),
        header: () => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              className={"w-fit"}
            >
              Rola
            </Button>
          );
        },
      },
      {
        id: "name",
        accessorKey: "name",
        cell: (props) => (
          <TanstackTableDynamicCell
            {...props}
            type={"text"}
            placeholder={"Imię"}
          />
        ),
        header: () => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              className={"w-fit"}
            >
              Imię
            </Button>
          );
        },
      },
      {
        id: "surname",
        accessorKey: "surname",
        cell: (props) => (
          <TanstackTableDynamicCell
            {...props}
            placeholder={"Nazwisko"}
            type={"text"}
          />
        ),
        header: () => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              className={"w-fit"}
            >
              Nazwisko
            </Button>
          );
        },
      },
      {
        id: "email",
        accessorKey: "email",
        cell: (props) => (
          <TanstackTableDynamicCell
            {...props}
            placeholder={"Email"}
            type={"email"}
          />
        ),
        header: () => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              className={"w-fit"}
            >
              Email
            </Button>
          );
        },
      },
      {
        id: "phone",
        accessorKey: "phone",
        cell: (props) => (
          <TanstackTableDynamicCell
            {...props}
            placeholder={"Nr. tel"}
            type={"phone"}
          />
        ),
        header: () => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              className={"w-fit"}
            >
              Nr.tel
            </Button>
          );
        },
      },
      {
        id: "birthdate",
        accessorKey: "birthdate",
        cell: (props) => (
          <TanstackTableDynamicCell
            {...props}
            type={"date"}
            viewCell={() => {
              const date = props?.getValue();
              if (typeof date === "string" && isValid(new Date(date))) {
                return format(new Date(date), "dd.MM.yyyy");
              }
              if (date === undefined || date === "") return "";
              return "Invalid date format";
            }}
          />
        ),
        header: () => {
          return (
            <Button
              size={"sm"}
              variant={"ghost"}
              variantColor={"muted"}
              className={"w-fit"}
            >
              Data ur.
            </Button>
          );
        },
      },
      {
        id: "actions",
        enableHiding: false,
        enableSorting: false,
        cell: ({ row }) => <TanstackTableDynamicEditCell row={row} />,
      },
    ],
    [],
  );

  return (
    <>
      <Dialog open={openImportDialog} onOpenChange={setOpenImportDialog}>
        <UserMassImportContent
          setData={setUserList}
          onOpenChange={setOpenImportDialog}
        />
      </Dialog>
      <Dialog open={openSubmitDialog} onOpenChange={setOpenSubmitDialog}>
        <UserMassImport data={userList} onOpenChange={setOpenSubmitDialog} />
      </Dialog>
      <AlertDialog open={openErrorDialog} onOpenChange={setOpenErrorDialog}>
        <UserMassImportError errors={errors} />
      </AlertDialog>
      <div className={"default-page-col"}>
        <div
          className={
            "viewport-height sticky top-4 flex flex-col gap-4 md:h-auto sm:relative"
          }
        >
          <header
            className={
              "flex flex-col gap-3 rounded-xl border-1 border-border bg-bg-container p-4"
            }
          >
            <div className={"flex w-full items-center justify-between"}>
              <div className={"flex items-center gap-1"}>
                <Button
                  size={"sm"}
                  variant={"outline"}
                  variantColor={"muted"}
                  iconPosition={"only"}
                  icon={<ChevronLeft />}
                  onClick={() => navigate("/manage/users")}
                />
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbItem>
                      <BreadcrumbLink href="/manage/users">
                        Użytkownicy
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbPage>Masowy import</BreadcrumbPage>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
              </div>
              <Button
                variant={"solid"}
                variantColor={"brand"}
                icon={<Check />}
                iconPosition={"left"}
                onClick={handleSaveChanges}
                disabled={!userList.length}
              >
                Zapisz
              </Button>
            </div>
            <Separator />
            <div className={"w-ful flex gap-3"}>
              <div className={"w-ful flex shrink-0 gap-3"}>
                <Button
                  icon={<Import />}
                  iconPosition={"left"}
                  variantColor={"muted"}
                  variant={"outline"}
                  className={"w-fit"}
                  onClick={handleOpenImportDialog}
                >
                  Importuj dane
                </Button>
              </div>
              <div className={"flex w-full justify-end"}>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button
                      variant={"outline"}
                      variantColor={"muted"}
                      icon={<HelpCircle />}
                      iconPosition={"only"}
                    />
                  </DialogTrigger>
                  <DialogContent className={"max-w-[45ch]"}>
                    <DialogHeader>
                      <DialogTitle>Nazwy kolumn</DialogTitle>
                    </DialogHeader>
                    <DialogBody>
                      <ul className={"flex list-disc flex-col gap-1 pl-4"}>
                        <li className={"text-sm text-fg-muted"}>
                          Imię / imie / name
                        </li>
                        <li className={"text-sm text-fg-muted"}>
                          nazwisko / surname
                        </li>
                        <li className={"text-sm text-fg-muted"}>
                          data urodzenia / birthdate / birthday
                        </li>
                        <li className={"text-sm text-fg-muted"}>email</li>
                        <li className={"text-sm text-fg-muted"}>
                          telefon / nr tel / numer telefonu / phone number /
                          phone
                        </li>
                        <li className={"text-sm text-fg-muted"}>
                          poświadczenia / rola / lvl / permissions
                        </li>
                      </ul>
                    </DialogBody>
                    <DialogFooter></DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          </header>

          <TanstackTableDynamic
            data={userList}
            setData={setUserList}
            columns={columns}
            newRowTemplate={{
              id: uuidv4(),
              lvl: "0",
              name: "",
              surname: "",
              email: "",
              phone: "",
              birthdate: "",
            }}
          />
        </div>
      </div>
    </>
  );
}

import { MediaViewerFileT } from "@/components/features/media/MediaViewer";
import { Button } from "@/components/ui/button/Button";
import { DialogBody, DialogFooter } from "@/components/ui/dialog/Dialog";
import Image from "@/components/ui/image/Image";
import { Link } from "@/components/ui/link/Link";
import getFileUrl from "@/utils/getFileUrl";
import { Download } from "lucide-react";
import { useMemo } from "react";

export default function MediaImageViewer({ file }: MediaViewerFileT) {
  const isFilesInstance = "id" in file;

  const { fileURL, type } = useMemo(() => {
    if (!file || isFilesInstance) {
      return { fileURL: getFileUrl(file.id), type: file.fileType };
    }
    return { fileURL: URL.createObjectURL(file), type: file.type };
  }, [file, isFilesInstance]);

  return (
    <>
      <DialogBody>
        <Image
          src={fileURL}
          alt={file.name}
          thumbnail={isFilesInstance ? file.thumbnail : undefined}
          type={type}
          className={"rounded-sm"}
          layoutClassName={"rounded-sm h-full w-full justify-center"}
        />
      </DialogBody>
      <DialogFooter>
        {isFilesInstance ? (
          <Link href={fileURL}>
            <Button
              variant={"ghost"}
              variantColor={"muted"}
              className={"rounded-sm"}
              icon={<Download />}
              iconPosition={"only"}
            />
          </Link>
        ) : null}
      </DialogFooter>
    </>
  );
}

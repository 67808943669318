import { FileUploadTrigger } from "@/components/ui/file-upload/FileUpload";
import { useFilesStorage } from "@/store/filesStore";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuGroup,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@components/ui/context-menu/ContextMenu";
import { FileUp, FolderPlus, FolderUp } from "lucide-react";
import { useCallback } from "react";

type FilePageContextMenuProps = {
  children: React.ReactNode;
  disabled?: boolean;
};

export default function FilePageContextMenu(props: FilePageContextMenuProps) {
  const { children, disabled = false } = props;

  const { setOpenCreateDir, permissions } = useFilesStorage();

  const handleOnCreateDir = useCallback(() => {
    setOpenCreateDir(true);
  }, []);

  return (
    <ContextMenu>
      <ContextMenuTrigger disabled={disabled}>{children}</ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuGroup>
          <ContextMenuItem
            disabled={disabled || !permissions.write}
            onClick={handleOnCreateDir}
          >
            <FolderPlus className={"h-4 w-4"} />
            Stwórz folder
          </ContextMenuItem>
          <FileUploadTrigger
            multiple={true}
            disabled={disabled || !permissions.write}
          >
            <ContextMenuItem>
              <FileUp className={"h-4 w-4"} />
              Prześlij pliki
            </ContextMenuItem>
          </FileUploadTrigger>
          <FileUploadTrigger
            multiple={true}
            webkitdirectory={"true"}
            directory={"true"}
            disabled={disabled || !permissions.write}
          >
            <ContextMenuItem>
              <FolderUp className={"h-4 w-4"} />
              Prześlij folder
            </ContextMenuItem>
          </FileUploadTrigger>
        </ContextMenuGroup>
      </ContextMenuContent>
    </ContextMenu>
  );
}

import TanstackTableActions from "@/components/features/manage/tanstack-table/TanstackTableActions";
import TanstackTableLoading from "@/components/features/manage/tanstack-table/TanstackTableLoading";
import TanstackTableRows from "@/components/features/manage/tanstack-table/TanstackTableRows";
import TanstackTableSearch from "@/components/features/manage/tanstack-table/TanstackTableSearch";
import {
  TanstackTableViewOptions,
  TanstackTableViewOptionsContent,
  TanstackTableViewOptionsContentFilter,
  TanstackTableViewOptionsContentSort,
  TanstackTableViewOptionsContentVisibility,
  TanstackTableViewOptionsTrigger,
} from "@/components/features/manage/tanstack-table/TanstackTableViewOptions";
import { Button } from "@/components/ui/button/Button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Separator } from "@/components/ui/separator/Separator";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table/Table";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { useMainLayoutPageContext } from "@/page/MainLayout";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ColumnsIcon, ListFilter } from "lucide-react";
import {
  ComponentType,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { TanstackTableFacetedFilters } from "./TanstackTableFacetedFilters";

export type tableAddGroupT = {
  id: number;
  label: string;
  icon?: ReactElement;
  func?: () => void;
};

export type filterByT = {
  id: string;
  title: string;
  options: {
    label: string;
    value: string | number | boolean;
    icon?: ComponentType<{ className?: string }>;
  }[];
};

export type DataTablePropsT<TData, TValue> = {
  name: string;
  label?: string;
  columns: ColumnDef<TData, TValue>[];
  defaultColumnVisibility?: VisibilityState;
  data: TData[];
  isFetching?: boolean;
  isLoading?: boolean;
  addGroup: tableAddGroupT[];
  filterBy?: filterByT[];
  className?: string;
  getSelectedRows?: (rows: TData[]) => void;
};

export function TanstackTable<TData, TValue>({
  className,
  name,
  label,
  columns,
  defaultColumnVisibility = {},
  data,
  getSelectedRows,
  addGroup,
  filterBy,
  isLoading,
}: DataTablePropsT<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const [columnFilters, setColumnFilters] = useLocalStorage<ColumnFiltersState>(
    `tanstackTable-${name}-columnFilters`,
    [],
  );
  const [columnVisibility, setColumnVisibility] = useLocalStorage(
    `tanstackTable-${name}-columnVisibility`,
    defaultColumnVisibility,
  );

  const [showShadow, setShowShadow] = useState<boolean>(false);

  const breakpoint = useMediaQueryHook("md");
  const { ref } = useMainLayoutPageContext();

  const shadowSetCb = useCallback((element: HTMLElement) => {
    if (element.scrollTop > 80) {
      setShowShadow(true);
    } else {
      setShowShadow(false);
    }
  }, []);

  useEffect(() => {
    const element = ref?.current;
    element?.addEventListener("scroll", () => shadowSetCb(element));
    return () => {
      element?.removeEventListener("scroll", () => shadowSetCb(element));
    };
  }, [ref, shadowSetCb]);

  const handleDropRowsClick = () => {
    getSelectedRows?.(
      table.getSelectedRowModel().rows.map((row) => row.original),
    );
  };
  const table = useReactTable<TData>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    //Row selection
    enableRowSelection: true,
    // onRowSelectionChange: setRowSelection,
    //Columns visibility
    onColumnVisibilityChange: setColumnVisibility,
    //Global filter
    enableFilters: true,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    //Column filters
    onColumnFiltersChange: setColumnFilters,
    //Sorting
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility,
      globalFilter,
      columnFilters,
    },
  });

  const trigger = useMemo(() => {
    if (breakpoint) {
      return (
        <Button
          variant={"outline"}
          variantColor={"muted"}
          icon={<ListFilter />}
          iconPosition={"left"}
        >
          Filtry
        </Button>
      );
    }
    return (
      <Button
        variant={"outline"}
        variantColor={"muted"}
        icon={<ColumnsIcon />}
        iconPosition={"left"}
      >
        Kolumny
      </Button>
    );
  }, [breakpoint]);

  return (
    <div className={cn(className)}>
      <header
        className={cn(
          "sticky top-4 z-20 flex flex-col gap-3 rounded-xl border-1 border-border p-4 transition-transform duration-300 ease-in-out sm:top-3",
          breakpoint && showShadow
            ? "scale-[0.98] bg-bg-container/80 backdrop-blur-[0.875rem] backdrop-saturate-[200%]"
            : "bg-bg-container",
        )}
      >
        <div className={"flex w-full items-center justify-between"}>
          <h2 className={"text-xl font-semibold"}>{label}</h2>
          <div className={"flex gap-3"}>
            {breakpoint ? null : (
              <TanstackTableSearch setGlobalFilter={setGlobalFilter} />
            )}

            {breakpoint ? (
              <TanstackTableViewOptions table={table}>
                <TanstackTableViewOptionsTrigger>
                  {trigger}
                </TanstackTableViewOptionsTrigger>
                <TanstackTableViewOptionsContent>
                  <TanstackTableViewOptionsContentFilter filterBy={filterBy} />
                  <TanstackTableViewOptionsContentSort />
                  <TanstackTableViewOptionsContentVisibility />
                </TanstackTableViewOptionsContent>
              </TanstackTableViewOptions>
            ) : (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>{trigger}</DropdownMenuTrigger>
                <DropdownMenuContent align={"end"}>
                  <DropdownMenuGroup>
                    {table
                      .getAllColumns()
                      .filter(
                        (column) =>
                          typeof column.accessorFn !== "undefined" &&
                          column.getCanHide(),
                      )
                      .map((column) => {
                        return (
                          <DropdownMenuCheckboxItem
                            key={column.id}
                            className="capitalize"
                            checked={column.getIsVisible()}
                            onCheckedChange={(value) => {
                              column.toggleVisibility(value);
                            }}
                            onSelect={(e) => e.preventDefault()}
                          >
                            {column?.columnDef?.meta?.toString() ?? ""}
                          </DropdownMenuCheckboxItem>
                        );
                      })}
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
        <Separator />
        <div className={"flex justify-between gap-2"}>
          {breakpoint ? (
            <TanstackTableSearch setGlobalFilter={setGlobalFilter} />
          ) : (
            <div className="flex shrink-0 items-center space-x-2">
              {filterBy &&
                filterBy.map(({ id, title, options }) => {
                  return (
                    <TanstackTableFacetedFilters
                      key={id}
                      column={table.getColumn(id)}
                      title={title}
                      options={options}
                    />
                  );
                })}
            </div>
          )}
          <TanstackTableActions
            addGroup={addGroup}
            isSelection={table.getSelectedRowModel().rows.length > 0}
            removeRows={handleDropRowsClick}
          />
        </div>
      </header>
      {breakpoint ? (
        <div className={"flex h-full flex-col gap-2"}>
          {isLoading ? (
            <TanstackTableLoading table={table} />
          ) : (
            <TanstackTableRows table={table} colSpan={columns.length} />
          )}
        </div>
      ) : (
        <Table>
          <TableHeader className={"z-10"}>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                className={"hover:bg-bg-muted-subtle/50"}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead className={"px-3 py-1"} key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TanstackTableLoading table={table} />
            ) : (
              <TanstackTableRows table={table} colSpan={columns.length} />
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
}

import { NotificationsSheet } from "@/components/features/notifications/NotificationsSheet";
import { Button } from "@/components/ui/button/Button";
import {
  NavigationAvatarImage,
  NavigationProps,
} from "@/components/ui/navigation/Navigation";
import {
  NavigationContext,
  NavigationItem,
} from "@/components/ui/navigation/Navigation";
import { ToggleThemeMode } from "@/components/ui/navigation/ThemeToggle";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover/Popover";
import { Separator } from "@/components/ui/separator/Separator";
import { useHover } from "@/hooks/useHover";
import { cn } from "@/lib/utils";
import {
  ArrowRight,
  Bell,
  CalendarDays,
  CandlestickChart,
  ChevronRight,
  ClipboardList,
  FolderClosed,
  Home,
  Library,
  LogOut,
  Map,
  MessageCircle,
  Newspaper,
  Settings,
  Settings2,
  User,
  Users,
} from "lucide-react";

function Sidebar({
  open,
  onOpenChange,
  logout,
  isLoading,
  userData: { name, surname, userRole, lvl, avatarURL },
}: NavigationProps) {
  const { isHovered, setIsHovered, bind } = useHover({ delay: 250 });

  return (
    <aside className={"top-0 h-dvh shrink-0 py-4 pl-4"}>
      <nav
        data-open={open}
        className={cn(
          "box-form-pages flex h-full flex-col justify-between rounded-lg border-1 border-border bg-bg-container transition-all duration-200",
          open ? "w-[13rem] min-w-[13rem]" : "w-[4.75rem] min-w-[4.75rem]",
        )}
      >
        <div className={cn("flex w-full px-4 pt-4")}>
          <div
            data-open={open}
            className="grid h-11 w-11 place-items-center rounded-md bg-bg-muted-subtle p-1 data-[open='false']:hidden"
          >
            <img src={"/logo.png"} className={"aspect-1 h-full w-full"} />
          </div>
          <Button
            data-open={open}
            className={"ml-auto [&[data-open='true']>svg]:rotate-[180deg]"}
            variant={"flat"}
            variantColor={"brand"}
            size={"md"}
            iconPosition={"only"}
            icon={
              <ArrowRight className={"transition-transform duration-200"} />
            }
            onClick={() => onOpenChange(!open)}
          />
        </div>
        <NavigationContext.Provider value={{ open }}>
          <div
            className={
              "flex flex-1 flex-col overflow-y-auto overflow-x-hidden px-4 py-5"
            }
          >
            <NavigationItem icon={<Home />} text={"Strona Główna"} to={"/"} />

            <Separator className={"my-1"} />

            <NotificationsSheet>
              <NavigationItem
                isNavigation={false}
                icon={<Bell />}
                text={"Powiadomiena"}
              />
            </NotificationsSheet>
            <NavigationItem
              icon={<MessageCircle />}
              text={"Chat"}
              to={"/chat"}
            />
            <NavigationItem
              icon={<CalendarDays />}
              text={"Kalendarz"}
              to={`/calendar/`}
            />
            <NavigationItem icon={<Users />} text={"Grupy"} to={"/groups"} />
            <NavigationItem
              icon={<CandlestickChart />}
              text={"Oceny"}
              to={"/grades"}
            />
            <NavigationItem
              icon={<ClipboardList />}
              text={"Zadania"}
              to={"/assigments"}
            />

            {lvl > 0 && (
              <Popover open={isHovered} onOpenChange={setIsHovered}>
                <PopoverTrigger asChild>
                  <NavigationItem
                    {...bind}
                    type={"list"}
                    chevron={<ChevronRight />}
                    icon={<Settings2 />}
                    text={"Zarządzaj"}
                    isNavigation={false}
                  />
                </PopoverTrigger>
                <PopoverContent
                  {...bind}
                  side={"right"}
                  align={"start"}
                  sideOffset={8}
                  className={"max-w-[13rem] p-1"}
                >
                  <div className={"p-3"}>
                    <h5>Zarządzaj:</h5>
                  </div>
                  <NavigationItem
                    icon={<User />}
                    text={"Użytkownicy"}
                    to={"/manage/users"}
                    preventHideText
                  />
                  <NavigationItem
                    icon={<Users />}
                    text={"Grupy"}
                    to={"/manage/groups"}
                    preventHideText
                  />
                  <NavigationItem
                    icon={<Newspaper />}
                    text={"Posty"}
                    to={"/manage/posts"}
                    preventHideText
                  />
                  <NavigationItem
                    icon={<Library />}
                    text={"Lekcje"}
                    to={"/manage/lessons"}
                    preventHideText
                  />
                  <NavigationItem
                    icon={<Map />}
                    text={"Lokacje"}
                    to={"/manage/locations"}
                    preventHideText
                  />
                </PopoverContent>
              </Popover>
            )}

            <NavigationItem icon={<FolderClosed />} text={"Pliki"} to={"/fs"} />
          </div>
        </NavigationContext.Provider>

        <NavigationPopoverFooter
          open={open}
          onOpenChange={onOpenChange}
          logout={logout}
          isLoading={isLoading}
          userData={{ name, surname, userRole, lvl, avatarURL }}
        />
      </nav>
    </aside>
  );
}
Sidebar.displayName = "Sidebar";

function NavigationPopoverFooter({
  open,
  logout,
  isLoading,
  userData: { name, surname, userRole, avatarURL },
}: NavigationProps) {
  const closePopover = () => {
    document?.getElementById("navbar-popover")?.click();
  };

  return (
    <Popover data-side={"right"}>
      <PopoverTrigger id="navbar-popover" data-side="right" asChild>
        <div
          className={cn(
            "flex w-full cursor-pointer items-center gap-3 overflow-hidden border-t border-border p-4 transition-all hover:bg-bg-muted-subtle/30",
          )}
        >
          <NavigationAvatarImage avatarURL={avatarURL} />
          <div
            className={cn(
              "flex flex-col gap-0.5 overflow-hidden transition-all duration-200",
              open ? "w-full" : "w-0",
            )}
          >
            <h5 className={"truncate text-sm font-semibold text-fg-primary"}>
              {name + " " + surname}
            </h5>
            <small className={"truncate text-xs text-fg-muted"}>
              {userRole}
            </small>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent
        side={"right"}
        align={"end"}
        sideOffset={8}
        className={"flex w-[16rem] flex-col gap-0 rounded-xl p-0"}
      >
        <div className={"mb-3 flex gap-3 px-4 pt-4"}>
          <NavigationAvatarImage avatarURL={avatarURL} />
          <div className={"flex flex-col gap-0.5"}>
            <h5 className={"font-semibold text-fg-primary"}>
              {name + " " + surname}
            </h5>
            <small className={"text-sm text-fg-muted"}>{userRole}</small>
          </div>
        </div>
        <ToggleThemeMode />
        <div
          className={"flex flex-1 flex-col gap-1 overflow-auto px-4 pb-4 pt-3"}
        >
          <NavigationItem
            icon={<Settings />}
            text={"Ustawienia"}
            to={"/settings"}
            onClick={closePopover}
            preventHideText
          />
          <NavigationItem
            icon={<LogOut />}
            text={"Wyloguj"}
            isNavigation={false}
            onClick={logout}
            preventHideText
            isLoading={{
              state: isLoading,
              message: "Wylogowywanie",
            }}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}
NavigationPopoverFooter.displayName = "NavigationPopoverFooter";

export { Sidebar };

import { filterByT } from "@/components/features/manage/tanstack-table/TanstackTable";
import {
  Button,
  ButtonPropsWithHTMLAttributes,
} from "@/components/ui/button/Button";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
} from "@/components/ui/drawer/Drawer";
import { Separator } from "@/components/ui/separator/Separator";
import { Sheet, SheetBody, SheetContent } from "@/components/ui/sheet/Sheet";
import { Toggle } from "@/components/ui/toggle/Toggle";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { Slot } from "@radix-ui/react-slot";
import { Table as TableT } from "@tanstack/react-table";
import { ArrowDownIcon, ArrowUpDown, ArrowUpIcon } from "lucide-react";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  forwardRef,
  useContext,
  useState,
} from "react";

type TanstackTableViewOptionsContextT = {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  table: TableT<any>;
};

const TanstackTableViewOptionsContext =
  createContext<TanstackTableViewOptionsContextT | null>(null);

const useTanstackTableViewOptionsContext = () => {
  const context = useContext(TanstackTableViewOptionsContext);
  if (!context) {
    throw new Error(
      "useTanstackTableViewOptionsContext must be used within a TanstackTableViewOptionsContext.Provider",
    );
  }
  return context;
};

type TanstackTableViewOptionsProps<T> = {
  table: TableT<T>;

  children?: ReactNode;
};

function TanstackTableViewOptions<TData>({
  table,
  children,
}: TanstackTableViewOptionsProps<TData>) {
  const [open, onOpenChange] = useState<boolean>(false);

  return (
    <TanstackTableViewOptionsContext.Provider
      value={{ open, onOpenChange, table }}
    >
      {children}
    </TanstackTableViewOptionsContext.Provider>
  );
}

const TanstackTableViewOptionsTrigger = forwardRef<
  HTMLElement,
  ButtonPropsWithHTMLAttributes
>(({ children, ...props }, ref) => {
  const { open, onOpenChange } = useTanstackTableViewOptionsContext();

  const onClick = () => {
    onOpenChange(!open);
  };

  return (
    <Slot {...props} ref={ref} onClick={onClick}>
      {children}
    </Slot>
  );
});
TanstackTableViewOptionsTrigger.displayName = "TanstackTableViewOptionsTrigger";

type TanstackTableViewOptionsContentProps = {
  children: ReactNode;
};

function TanstackTableViewOptionsContent({
  children,
}: TanstackTableViewOptionsContentProps) {
  const { open, onOpenChange } = useTanstackTableViewOptionsContext();

  const breakpoint = useMediaQueryHook("sm");

  if (breakpoint) {
    return (
      <Drawer open={open} onOpenChange={onOpenChange}>
        <DrawerContent>
          <DrawerBody className="gap-6">{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className={"max-w-[450px]"}>
        <SheetBody className={"flex flex-col gap-6 p-4"}>{children}</SheetBody>
      </SheetContent>
    </Sheet>
  );
}

type TanstackTableViewOptionsContentFilterProps = {
  filterBy?: filterByT[];
};

function TanstackTableViewOptionsContentFilter({
  filterBy,
}: TanstackTableViewOptionsContentFilterProps) {
  const { table } = useTanstackTableViewOptionsContext();

  if (!filterBy) return null;

  return (
    <div className={"flex flex-col gap-3"}>
      <h3 className={"text-lg font-medium"}>Filtry</h3>
      <Separator />
      <div className={"flex flex-col gap-5"}>
        {filterBy.map(({ id, title, options }) => {
          const column = table.getColumn(id);

          let selectedValues: Set<string | number | boolean> = new Set();
          const filterValue = column?.getFilterValue();

          if (Array.isArray(filterValue) && filterValue.length > 0) {
            if (typeof filterValue[0] === "string") {
              selectedValues = new Set<string>(filterValue as string[]);
            } else if (typeof filterValue[0] === "number") {
              selectedValues = new Set<number>(filterValue as number[]);
            } else if (typeof filterValue[0] === "boolean") {
              selectedValues = new Set<boolean>(filterValue as boolean[]);
            }
          }

          return (
            <div key={id} className={"flex flex-col gap-4"}>
              <div className={"flex h-10 items-center justify-between"}>
                <p className={"font-md text-md"}>{title}</p>
                {selectedValues.size > 0 && (
                  <Button
                    variant={"flat"}
                    variantColor={"muted"}
                    size={"sm"}
                    onClick={() => column?.setFilterValue(undefined)}
                  >
                    Wyczyść
                  </Button>
                )}
              </div>
              <div className={"flex flex-wrap gap-2"}>
                {options.map((option) => {
                  const isSelected = selectedValues.has(option.value);
                  return (
                    <Toggle
                      key={option.value.toString()}
                      data-state={isSelected ? "on" : "off"}
                      aria-checked={isSelected}
                      variant={"outline"}
                      onClick={() => {
                        if (isSelected) {
                          selectedValues.delete(option.value);
                        } else {
                          selectedValues.add(option.value);
                        }
                        const filterValues = Array.from(selectedValues);
                        column?.setFilterValue(
                          filterValues.length ? filterValues : undefined,
                        );
                      }}
                    >
                      {option.label.toUpperCase()}
                    </Toggle>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
function TanstackTableViewOptionsContentSort() {
  const { table } = useTanstackTableViewOptionsContext();

  return (
    <div className={"flex flex-col gap-3"}>
      <h3 className={"text-lg font-medium"}>Sortowanie</h3>
      <Separator />
      <div className={"flex flex-wrap gap-2"}>
        {table
          .getAllColumns()
          .filter(
            (column) =>
              typeof column.accessorFn !== "undefined" && column.getCanSort(),
          )
          .map((column) => {
            return (
              <Button
                key={column.id}
                variant={"outline"}
                variantColor={"muted"}
                size={"sm"}
                className={"w-fit"}
                iconPosition={"left"}
                icon={
                  column.getCanSort() && column.getIsSorted() === "desc" ? (
                    <ArrowDownIcon aria-hidden="true" />
                  ) : column.getIsSorted() === "asc" ? (
                    <ArrowUpIcon aria-hidden="true" />
                  ) : (
                    <ArrowUpDown aria-hidden="true" />
                  )
                }
                onClick={() => {
                  column.toggleSorting(column.getIsSorted() === "asc");
                }}
              >
                {column?.columnDef?.meta?.toString().toUpperCase() ?? ""}
              </Button>
            );
          })}
      </div>
    </div>
  );
}
function TanstackTableViewOptionsContentVisibility() {
  const { table } = useTanstackTableViewOptionsContext();

  return (
    <div className={"flex flex-col gap-3"}>
      <h3 className={"text-lg font-medium"}>Kolumny</h3>
      <Separator />
      <div className={"flex flex-wrap gap-2"}>
        {table
          .getAllColumns()
          .filter(
            (column) =>
              typeof column.accessorFn !== "undefined" && column.getCanHide(),
          )
          .map((column) => {
            const isSelected = column.getIsVisible();
            return (
              <Toggle
                key={column.id}
                data-state={isSelected ? "on" : "off"}
                aria-checked={isSelected}
                className={"capitalize"}
                onClick={() => {
                  column.toggleVisibility();
                }}
              >
                {column?.columnDef?.meta?.toString().toUpperCase() ?? ""}
              </Toggle>
            );
          })}
      </div>
    </div>
  );
}

export {
  TanstackTableViewOptions,
  TanstackTableViewOptionsContent,
  TanstackTableViewOptionsTrigger,
  TanstackTableViewOptionsContentFilter,
  TanstackTableViewOptionsContentSort,
  TanstackTableViewOptionsContentVisibility,
};

import { httpErrorHandler } from "@/api/api";
import {
  useGetSignupQuery,
  usePostSignupMutation,
} from "@/api/queries/authQueries";
import { EditAvatar } from "@/components/features/user/EditAvatar";
import { BackgroundWrapper } from "@/components/ui/background-wrapper/BackgroundWrapper";
import { Button } from "@/components/ui/button/Button";
import { Dialog } from "@/components/ui/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form/Form";
import ImageCropper from "@/components/ui/image-cropper/ImageCropper";
import { Input } from "@/components/ui/input/Input";
import { DatetimePicker } from "@/components/ui/input/datetime-picker/DatetimePicker";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { useGetURLParams } from "@/hooks/useGetURLParams";
import { useLvl } from "@/hooks/useLvl.js";
import { cn } from "@/lib/utils";
import { SignupSchema, SignupSchemaType } from "@/schemas/auth.schema";
import { useCredentials, useSetCredentials } from "@/store/authStore";
import { CredentialsI } from "@/types/credentials";
import { getAvatar } from "@/utils/getAvatar";
import { SerializeData } from "@/utils/serializeData";
import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import { AlertCircle, ArrowRight, Mail, PhoneIcon } from "lucide-react";
import { Suspense, lazy, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

const LoginId = lazy(
  () => import("@/assets/images/auth/login-id-elements-img.svg?react"),
);
const LoginPhone = lazy(
  () => import("@/assets/images/auth/login-phone-img.svg?react"),
);
const Bubble = lazy(() => import("@/assets/images/auth/bubble.svg?react"));

export default function SignupPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const authToken = useGetURLParams().get("t") as string;
  const setCredentials = useSetCredentials();
  const { avatarURL } = useCredentials();
  const [open, setOpen] = useState<boolean>(false);
  const [avatarSrc, setAvatarSrc] = useState<string | undefined>(undefined);

  const { mutateAsync, isPending, isError, error } = usePostSignupMutation();

  const { data, ...getSignupResults } = useGetSignupQuery(authToken);
  const userRole = useLvl(data?.lvl);

  useEffect(() => {
    if (avatarURL) {
      setAvatarSrc(getAvatar(avatarURL));
    }
  }, [avatarURL]);

  useEffect(() => {
    if (data) {
      setAvatarSrc(getAvatar(data.avatarURL));
    }
  }, [data]);

  const { detail } = httpErrorHandler(error);

  const form = useForm<SignupSchemaType>({
    values: {
      authToken: authToken,
      ...SignupSchema.cast(SerializeData(data)),
    },
    resetOptions: {
      keepDirtyValues: false,
    },
    mode: "onBlur",
    resolver: yupResolver(SignupSchema),
  });

  const checkIfCanGoBack = (): boolean => {
    return location.key !== "default";
  };

  const onSubmit = async (formData: SignupSchemaType) => {
    mutateAsync(formData).then(() => {
      const newCredentials = { ...data, ...formData } as CredentialsI;
      setCredentials(newCredentials);
      setAvatarSrc(getAvatar(newCredentials.avatarURL));
      navigate(`/auth/passwordChange?t=${authToken}`);
    });
  };

  return (
    <>
      <BackgroundWrapper>
        <Suspense fallback={null}>
          <LoginId
            className={
              "absolute -left-14 top-2/4 -translate-y-2/4 animate-fade-in opacity-60 duration-200 sm:hidden"
            }
          />
        </Suspense>

        <Suspense fallback={null}>
          <LoginPhone
            className={
              "absolute -right-14 top-2/4 -translate-y-2/4 animate-fade-in opacity-60 duration-200 sm:hidden"
            }
          />
        </Suspense>
        <Suspense fallback={null}>
          <Bubble
            className={
              "absolute -right-14 top-0 hidden animate-fade-in opacity-60 duration-200 sm:block"
            }
          />
        </Suspense>
      </BackgroundWrapper>
      <Dialog open={open} onOpenChange={setOpen}>
        <ImageCropper onOpenChange={setOpen} open={open} />
      </Dialog>
      <div
        className={
          "flex min-h-[100dvh] w-full flex-1 items-center justify-center overflow-auto p-6 sm:items-end sm:p-0 sm:pt-6"
        }
      >
        <section
          className={
            "relative z-30 flex w-[clamp(380px,50dvw,420px)] flex-col items-center gap-7 overflow-hidden rounded-2xl border-1 border-border bg-bg-container px-6 py-6 shadow-md-sharp-border sm:min-h-[90dvh] sm:w-full sm:rounded-b-none sm:border-0 sm:border-t-1 sm:px-4"
          }
        >
          {getSignupResults.isFetching && (
            <div
              className={
                "absolute inset-0 z-20 m-auto flex flex-wrap items-center justify-center gap-3 bg-bg-container"
              }
            >
              <Spinner size={"lg"} />
              <h5 className={"font-medium"}>Trwa ładowanie danych...</h5>
            </div>
          )}

          <h1 className={"w-full py-3 text-4xl font-semibold text-fg-primary"}>
            Zarejestruj się
          </h1>

          <div className={"flex w-full flex-col gap-4"}>
            <h5 className={"text-lg font-medium text-fg-primary"}>
              Zmień swój awatar
            </h5>
            <div className={"flex flex-row items-center gap-4"}>
              <EditAvatar
                src={avatarSrc}
                onClick={() => setOpen(true)}
                size={"sm"}
              />
              {getSignupResults.isSuccess && (
                <div className={"flex flex-col gap-1"}>
                  <p className={"font-medium text-fg-secondary"}>
                    {data?.name + " " + data?.surname}
                  </p>
                  <p className={"text-sm text-fg-muted"}>{userRole}</p>
                </div>
              )}
            </div>
          </div>
          <div className={"flex w-full flex-grow flex-col gap-4"}>
            <h5 className={"text-lg font-medium text-fg-primary"}>
              Podstawowe dane
            </h5>

            <Form {...form}>
              <form
                className={"flex w-full flex-grow flex-col gap-3"}
                onSubmit={form.handleSubmit(onSubmit)}
                noValidate
              >
                <FormField
                  name={"email"}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          required
                          placeholder={"Email"}
                          endContent={<Mail className={"mr-2"} />}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name={"birthdate"}
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>
                          Data urodzenia{" "}
                          <small className={"text-sm text-fg-muted"}>
                            (opcionalne)
                          </small>
                        </FormLabel>
                        <FormControl>
                          <DatetimePicker
                            jsDate={field.value}
                            onJsDateChange={field.onChange}
                            aria-label={"birthdate-picker"}
                            max={new Date()}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  name={"phone"}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Numer telefonu{" "}
                        <small className={"text-sm text-fg-muted"}>
                          (opcionalne)
                        </small>
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type={"phone"}
                          placeholder={"Numer telefonu"}
                          endContent={<PhoneIcon className={"mr-2"} />}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{
                    opacity: isError ? 1 : 0,
                    height: isError ? "auto" : 0,
                  }}
                  transition={{ duration: 0.2 }}
                  className={
                    "flex flex-row items-center gap-2 overflow-hidden text-fg-destructive"
                  }
                >
                  <AlertCircle className={"h-4 w-4"} />
                  <p className={"truncate text-sm"}>{isError && detail}</p>
                </motion.div>

                <footer className={"mt-auto flex flex-row gap-3"}>
                  <Button
                    type={"button"}
                    size={"lg"}
                    variant={"ghost"}
                    variantColor={"muted"}
                    className={cn(checkIfCanGoBack() ? "w-full" : "hidden")}
                    onClick={() => navigate(-1)}
                  >
                    Cofnij
                  </Button>
                  <Button
                    type={"submit"}
                    size={"lg"}
                    isLoading={{
                      state: isPending,
                    }}
                    variant={"solid"}
                    variantColor={"brand"}
                    iconPosition={"right"}
                    icon={<ArrowRight />}
                    className={"w-full"}
                    onClick={form.handleSubmit(onSubmit)}
                  >
                    Dalej
                  </Button>
                </footer>
              </form>
            </Form>
          </div>
        </section>
      </div>
    </>
  );
}

import { httpErrorHandler } from "@/api/api";
import { useInfiniteQueryResult } from "@/api/api-utils";
import {
  getGroupPrefetch,
  useGetAssignedGroupsInfiniteQuery,
} from "@/api/queries/groupsQueries";
import Empty from "@/assets/images/empty.svg?react";
import {
  GroupBoxSkeleton,
  GroupCard,
} from "@/components/features/groups/GroupCard";
import GroupsHeader from "@/components/features/groups/layout/GroupsHeader";
import { InfiniteScroll } from "@/components/ui/infinite-scroll/InfiniteScroll";
import { useToast } from "@/components/ui/toast/useToast";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { AssignedGroupI } from "@/types/groups";
import { Fragment, useEffect, useState } from "react";

export default function GroupsPage() {
  const { toast } = useToast();

  const [searchValue, setSearchValue] = useState<string>("");
  const searchDebouncedValue = useDebounceValue(searchValue, 400);

  const {
    data,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
  } = useGetAssignedGroupsInfiniteQuery({
    name: searchDebouncedValue,
  });

  const { data: groups, isEmpty: groupsIsEmpty } = useInfiniteQueryResult(
    data,
    searchDebouncedValue,
  );

  const next = async () => {
    await fetchNextPage();
  };

  const prefetch = async (id: number, groupData: AssignedGroupI) => {
    getGroupPrefetch({ id: id, initialData: groupData });
  };

  useEffect(() => {
    if (isError) {
      const { title, detail } = httpErrorHandler(error);
      toast({
        title: title,
        description: detail,
        variant: "destructive",
      });
    }
  }, [isError, error, toast]);

  return (
    <div className={"default-page-col relative flex flex-1 flex-col gap-4"}>
      <GroupsHeader onSearchChange={setSearchValue} searchValue={searchValue} />
      <div
        className={
          "relative grid grow auto-rows-min grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-4"
        }
      >
        {isSuccess && groupsIsEmpty && (
          <div
            className={
              "absolute inset-0 flex h-full w-full flex-col items-center justify-center"
            }
          >
            <Empty className={"h-[200px]"} />
            <h2 className={"mt-2 text-2xl font-bold"}>
              Nie znalezionono żadnej grupy
            </h2>
            <p
              className={
                "text-muted-foreground h-6.5 mb-6 mt-2 w-[35ch] whitespace-pre-wrap text-center text-xs"
              }
            >
              Brak przypisanych grup lub zmień opcje wyszukiwania
            </p>
          </div>
        )}
        {isLoading && (
          <Fragment>
            <GroupBoxSkeleton />
            <GroupBoxSkeleton />
            <GroupBoxSkeleton />
            <GroupBoxSkeleton />
            <GroupBoxSkeleton />
          </Fragment>
        )}
        {groups.map((group) => (
          <GroupCard
            key={group.id}
            group={group}
            onFocus={() => prefetch(group.id, group)}
            onMouseEnter={() => prefetch(group.id, group)}
          />
        ))}
        <InfiniteScroll
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          isFetching={isFetching}
          next={next}
          threshold={0.65}
          root={null}
        >
          {hasNextPage && <GroupBoxSkeleton />}
        </InfiniteScroll>
      </div>
    </div>
  );
}

import { httpErrorHandler } from "@/api/api";
import { useDeleteDirectoriesMutation } from "@/api/queries/directoryQueries";
import { useDeleteFilesMutation } from "@/api/queries/filesQueries";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/dialog/AlertDialog";
import { useToast } from "@/components/ui/toast/useToast";
import { DirectoryElementsI } from "@/types/files";
import { useMemo } from "react";

type DeleteFSEntryProps = {
  elements: DirectoryElementsI;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  submitCallback?: () => void;
  directoryId?: string;
};

export default function DeleteFSEntry({
  elements,
  open,
  onOpenChange,
  submitCallback,
  directoryId,
}: DeleteFSEntryProps) {
  const { toast } = useToast();
  const { executeMutations: directoryMutations } =
    useDeleteDirectoriesMutation(directoryId);
  const { executeMutations: filesMutations } =
    useDeleteFilesMutation(directoryId);

  const handleClose = () => {
    onOpenChange(false);
  };

  const handleSubmit = async () => {
    const { files, directories } = { ...elements };
    handleClose();
    submitCallback?.();
    filesMutations(files.map(({ id }) => id)).catch((error) => {
      const { title, detail } = httpErrorHandler(error);
      toast({
        title: title,
        description: detail,
        variant: "destructive",
      });
    });
    directoryMutations(directories.map(({ id }) => id)).catch((error) => {
      const { title, detail } = httpErrorHandler(error);
      toast({
        title: title,
        description: detail,
        variant: "destructive",
      });
    });
  };

  const elementsLen = useMemo(() => {
    return elements.directories.length + elements.files.length;
  }, [elements]);

  const elementsNames = useMemo(() => {
    return [...elements.directories, ...elements.files].map(
      (element) => element.name,
    );
  }, [elements]);

  return (
    <AlertDialog onOpenChange={onOpenChange} open={open}>
      <AlertDialogContent className="w-[45ch]">
        <AlertDialogHeader>
          <AlertDialogTitle>
            Usunąć {"( " + elementsLen + " )"} ?
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogBody className={"flex flex-col gap-4"}>
          <p className="text-sm text-fg-muted">
            Czy na pewno chcesz usunąć wybrane elementy ?
          </p>
          <div className={"flex flex-wrap gap-2"}>
            {elementsNames.map((name, idx) => (
              <Badge key={name + "_" + idx} variant={"muted"} size={"sm"}>
                {name}
              </Badge>
            ))}
          </div>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button variant={"flat"} variantColor={"muted"} onClick={handleClose}>
            Anuluj
          </Button>
          <Button
            variant={"flat"}
            variantColor={"destructive"}
            onClick={handleSubmit}
          >
            Potwierdź
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

import { cn } from "@/lib/utils";
import { Check } from "lucide-react";

interface ThemeModeButtonProps {
  className?: string;
  label: string;
  active: boolean;
  themeMode: string;
  setThemeMode: () => void;
}

export default function ThemeModeButton({
  className,
  label,
  active,
  themeMode,
  setThemeMode,
}: ThemeModeButtonProps) {
  return (
    <div className={"flex h-[8rem] w-[12rem] shrink flex-col gap-2"}>
      <button
        data-theme={themeMode}
        onClick={setThemeMode}
        className={cn(
          "relative flex h-full w-full cursor-pointer items-end justify-end rounded-lg border border-border bg-bg",
          active && "border-fg-brand",
          className,
        )}
      >
        <div
          className={
            "ml-4 mt-4 flex h-[5rem] w-[7rem] shrink rounded-lg border border-border-layout bg-bg-container p-3"
          }
        >
          <p className={"text-md font-medium text-fg-primary"}>Aa</p>
        </div>
        {active && (
          <span
            className={"absolute -right-1 -top-1 rounded-full bg-bg-brand p-1"}
          >
            <Check className={"h-3 w-3 stroke-fg-brand-on"} />
          </span>
        )}
      </button>
      <p className={"text-sm text-fg-secondary"}>{label}</p>
    </div>
  );
}

import {
  ContextMenuContent,
  ContextMenuGroup,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuSeparator,
} from "@/components/ui/context-menu/ContextMenu";
import {
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { useFilesStorage } from "@/store/filesStore";
import { DirectoryDirI, DirectoryFileI } from "@/types/files";
import { getIsFSEntityFile } from "@/utils/files";
import {
  Download,
  FileEdit,
  FolderSymlink,
  Info,
  Share2,
  Trash2,
} from "lucide-react";
import { Fragment } from "react";

type FSEntitiesMenuProps = {
  element?: DirectoryFileI | DirectoryDirI;
  type: "file" | "directory";
  showContextMenu: boolean;
  showDropdownMenu: boolean;
};

export default function FSEntitiesMenu({
  element,
  showContextMenu,
  showDropdownMenu,
}: FSEntitiesMenuProps) {
  const permissions = element?.permissions;
  const isFile = getIsFSEntityFile(element);

  const {
    selectedSize,
    setOpenEditName,
    setOpenShare,
    setOpenDownload,
    setOpenMove,
    setOpenMoreInfo,
    setOpenDelete,
  } = useFilesStorage();

  const size = selectedSize();

  const openEditName = () => {
    setOpenEditName(true);
  };
  const openEditShare = () => {
    setOpenShare(true);
  };

  const openDownload = () => {
    setOpenDownload(true);
  };

  const openMove = () => {
    setOpenMove(true);
  };
  const openMoreInfo = () => {
    setOpenMoreInfo(true);
  };

  const openDelete = () => {
    setOpenDelete(true);
  };

  if (element === undefined || permissions === undefined) return null;

  return (
    <Fragment>
      {showDropdownMenu ? (
        <DropdownMenuContent align={"end"}>
          <DropdownMenuLabel>
            {isFile ? "Ustawienia Pliku" : "Ustawienia Folderu"}
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem
              disabled={!permissions.edit || size > 1}
              onClick={openEditName}
            >
              <FileEdit className={"h-4 w-4"} />
              Zmień nazwę
            </DropdownMenuItem>
            <DropdownMenuItem
              disabled={!permissions.edit}
              onClick={openEditShare}
            >
              <Share2 className={"h-4 w-4"} />
              Udostępnij
            </DropdownMenuItem>
            <DropdownMenuItem onClick={openDownload}>
              <Download className={"h-4 w-4"} />
              Pobierz
            </DropdownMenuItem>
            <DropdownMenuItem disabled={!permissions.edit} onClick={openMove}>
              <FolderSymlink className={"h-4 w-4"} />
              Przenieś
            </DropdownMenuItem>
            <DropdownMenuItem disabled={size > 1} onClick={openMoreInfo}>
              <Info className={"h-4 w-4"} />
              Więcej informacji
            </DropdownMenuItem>
            <DropdownMenuItem
              disabled={isFile ? !permissions?.write : !permissions?.edit}
              className={"text-destructive"}
              onClick={openDelete}
            >
              <Trash2 className={"h-4 w-4"} />
              Usuń
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      ) : null}
      {showContextMenu ? (
        <ContextMenuContent>
          <ContextMenuLabel>
            {isFile ? "Ustawienia Pliku" : "Ustawienia Folderu"}
          </ContextMenuLabel>
          <ContextMenuSeparator />
          <ContextMenuGroup>
            <ContextMenuItem
              disabled={!permissions.edit || size > 1}
              onClick={openEditName}
            >
              <FileEdit className={"h-4 w-4"} />
              Zmień nazwę
            </ContextMenuItem>
            <ContextMenuItem
              disabled={!permissions.edit}
              onClick={openEditShare}
            >
              <Share2 className={"h-4 w-4"} />
              Udostępnij
            </ContextMenuItem>
            <ContextMenuItem onClick={openDownload}>
              <Download className={"h-4 w-4"} />
              Pobierz
            </ContextMenuItem>
            <ContextMenuItem disabled={!permissions.edit} onClick={openMove}>
              <FolderSymlink className={"h-4 w-4"} />
              Przenieś
            </ContextMenuItem>
            <ContextMenuItem disabled={size > 1} onClick={openMoreInfo}>
              <Info className={"h-4 w-4"} />
              Więcej informacji
            </ContextMenuItem>
            <ContextMenuItem
              disabled={isFile ? !permissions?.write : !permissions?.edit}
              className={"text-destructive"}
              onClick={openDelete}
            >
              <Trash2 className={"h-4 w-4"} />
              Usuń
            </ContextMenuItem>
          </ContextMenuGroup>
        </ContextMenuContent>
      ) : null}
    </Fragment>
  );
}

import { useGetLocationsQuery } from "@/api/queries/locationsQueries";
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion/Accordion";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command/Command";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormMessages,
} from "@/components/ui/form/Form";
import { Input, InputActionButton } from "@/components/ui/input/Input";
import { TimePicker } from "@/components/ui/input/datetime-picker/DatetimePicker";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/input/select/Select";
import { Label } from "@/components/ui/label/Label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover/Popover";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "@/components/ui/toggle/ToggleGroup";
import { cn } from "@/lib/utils";
import { CalendarFormSchemaType } from "@/schemas/meeting.schema";
import { format } from "date-fns";
import pl from "date-fns/locale/pl";
import {
  ArrowRight,
  Check,
  ChevronDown,
  ClipboardPaste,
  Clock,
  Trash2,
} from "lucide-react";
import { useEffect } from "react";
import {
  UseFieldArrayRemove,
  UseFormReturn,
  useFormContext,
} from "react-hook-form";

type CalendarScheduleElementProps = {
  form: UseFormReturn<CalendarFormSchemaType>;
  remove: UseFieldArrayRemove;
  index: number;
};

export default function ScheduleElement({
  form,
  remove,
  index,
}: CalendarScheduleElementProps) {
  const { data: locations = [] } = useGetLocationsQuery();

  const { watch, setValue } = form;

  const type = watch(`schedule.${index}.type`);
  const day = watch(`schedule.${index}.day`);
  const start = watch(`schedule.${index}.start`);
  const end = watch(`schedule.${index}.end`);

  useEffect(() => {
    if (type === "remote") {
      setValue(`schedule.${index}.locationId`, undefined);
    } else if (type === "stationary") {
      setValue(`schedule.${index}.meetingURL`, undefined);
    }
  }, [type, setValue, index]);

  const handleFormatTime = (time: string) => {
    return time.substring(0, 5);
  };

  const handlePaste = async () => {
    if (!navigator.clipboard) {
      return;
    }
    const text = await navigator.clipboard.readText();
    if (text) {
      setValue(`schedule.${index}.meetingURL`, text);
    }
  };

  function getDayName(dayNumber: number) {
    const date = new Date();
    date.setDate(date.getDate() - date.getDay() + dayNumber);
    return format(date, "EEE", { locale: pl });
  }

  const {
    formState: { errors },
  } = useFormContext();

  const isError =
    errors[`schedule[${index}].end`] ||
    errors[`schedule[${index}].start`] ||
    errors[`schedule[${index}].day`];

  return (
    <AccordionItem
      value={`Accordion.${index}`}
      className={cn(
        "group gap-4 rounded-md border border-border",
        isError && "border-fg-destructive",
      )}
    >
      <AccordionHeader
        className={cn(
          "flex h-full grow border-border p-1 group-data-[state=open]:border-b-1",
          isError && "group-data-[state=open]:border-fg-destructive",
        )}
      >
        <AccordionTrigger
          className={cn(
            "peer flex w-full flex-row justify-between gap-3 overflow-hidden p-1 pl-3 hover:no-underline",
          )}
          chevronHide
        >
          {isError ? (
            <p className={"text-md font-normal text-fg-destructive"}>
              Popraw dane
            </p>
          ) : (
            <div className={"flex shrink gap-1 overflow-hidden"}>
              <Badge variant={"muted"} size={"sm"}>
                {day && getDayName(Number(day))}
              </Badge>
              {type && (
                <Badge variant={"muted"} size={"sm"}>
                  {type === "remote" ? "Zdalnie" : "Stacionarnie"}
                </Badge>
              )}
              {start && end && (
                <Badge variant={"muted"} size={"sm"}>
                  {handleFormatTime(start)} - {handleFormatTime(end)}
                </Badge>
              )}
            </div>
          )}

          <div className={"flex shrink-0 items-center gap-2"}>
            <ChevronDown className={"h-4 w-4 stroke-fg-muted"} />
          </div>
        </AccordionTrigger>
        <Button
          size={"sm"}
          variant={"ghost"}
          variantColor={"destructive"}
          iconPosition={"only"}
          onClick={() => remove(index)}
          icon={<Trash2 />}
        />
      </AccordionHeader>

      <AccordionContent className={"flex flex-col gap-0 p-0"}>
        <div className={"flex flex-col gap-4 p-4"}>
          <div className={"flex h-10 items-center justify-between"}>
            <Label>Data:</Label>
          </div>
          <div className={"flex flex-col gap-3"}>
            <FormField
              control={form.control}
              name={`schedule.${index}.day`}
              render={({ field }) => (
                <FormItem>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger className={"h-12"}>
                        <SelectValue placeholder={"Wybierz dzień tygodnia"} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value={"1"}>Poniedziałek</SelectItem>
                      <SelectItem value={"2"}>Wtorek</SelectItem>
                      <SelectItem value={"3"}>Środa</SelectItem>
                      <SelectItem value={"4"}>Czwartek</SelectItem>
                      <SelectItem value={"5"}>Piątek</SelectItem>
                      <SelectItem value={"6"}>Sobota</SelectItem>
                      <SelectItem value={"7"}>Niedziela</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
            <fieldset className={"flex w-full items-center gap-2"}>
              <FormField
                name={`schedule.${index}.start`}
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormItem className={"w-full"}>
                      <FormControl>
                        <TimePicker
                          hourCycle={24}
                          aria-label={"time-start"}
                          startContent={<Clock className={"ml-2"} />}
                          jsTime={field.value}
                          jsTimeOnChange={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  );
                }}
              />
              <ArrowRight className={"h-4 w-4 shrink-0 stroke-fg-muted"} />
              <FormField
                name={`schedule.${index}.end`}
                control={form.control}
                render={({ field }) => (
                  <FormItem className={"w-full"}>
                    <FormControl>
                      <TimePicker
                        hourCycle={24}
                        aria-label={"time-end"}
                        startContent={<Clock className={"ml-2"} />}
                        jsTime={field.value}
                        jsTimeOnChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </fieldset>
            <FormMessages
              keys={[
                `schedule[${index}].day`,
                `schedule[${index}].start`,
                `schedule[${index}].end`,
              ]}
              listView
            />
          </div>
        </div>
        <div className={"flex flex-col gap-4 p-4"}>
          <div className={"flex h-10 items-center justify-between"}>
            <Label>Lokalizacja:</Label>
            <FormField
              name={`schedule.${index}.type`}
              control={form.control}
              render={({ field }) => {
                const handleChange = (value: string) => {
                  if (value.length > 0) field.onChange(value);
                };
                return (
                  <FormItem>
                    <FormControl>
                      <ToggleGroup
                        type={"single"}
                        onValueChange={handleChange}
                        value={field.value}
                        className={"gap-1"}
                      >
                        <ToggleGroupItem value={"remote"}>
                          Zdalnie
                        </ToggleGroupItem>
                        <ToggleGroupItem value={"stationary"}>
                          Stacionarnie
                        </ToggleGroupItem>
                      </ToggleGroup>
                    </FormControl>
                  </FormItem>
                );
              }}
            />
          </div>

          {type === "remote" && (
            <FormField
              name={`schedule.${index}.meetingURL`}
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={"Link do spotkania"}
                      endContent={
                        <InputActionButton
                          icon={<ClipboardPaste />}
                          onClick={handlePaste}
                        />
                      }
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {type === "stationary" && (
            <FormField
              control={form.control}
              name={`schedule.${index}.locationId`}
              render={({ field }) => (
                <FormItem className={"flex flex-col"}>
                  <Popover modal={true}>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          variantColor={"muted"}
                          role={"combobox"}
                          className={cn(
                            "justify-start",
                            !field.value && "text-fg-muted",
                          )}
                          iconPosition={"right"}
                          icon={<ChevronDown className={"ml-auto"} />}
                        >
                          {field.value
                            ? locations.find(({ id }) => id === field.value)
                                ?.name
                            : "Wybierz lokalizację..."}
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent
                      align={"start"}
                      className={"h-[25vh] max-h-[300px] border-none p-0"}
                    >
                      <Command>
                        <CommandInput placeholder={"Wyszukaj lokalizację..."} />
                        <CommandEmpty>Nie znaleziono lokalizacji.</CommandEmpty>
                        <CommandGroup className={"overflow-auto"}>
                          {locations.map(({ id, name }) => (
                            <CommandItem
                              value={name}
                              key={id}
                              onSelect={() => {
                                form.setValue(
                                  `schedule.${index}.locationId`,
                                  id,
                                );
                              }}
                            >
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  id === field.value
                                    ? "opacity-100"
                                    : "opacity-0",
                                )}
                              />
                              {name}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
}

import {
  useEditMeetingMutation as useEditMeeting,
  useGetMeetingQuery as useGetMeeting,
} from "@/api/queries/meetingsQuery";
import DeleteMeeting from "@/components/features/calendar/form/DeleteMeeting";
import EditMeeting from "@/components/features/calendar/form/EditMeeting";
import MeetingInfoPopover from "@/components/features/meeting/MeetingInfoPopover";
import { Button } from "@/components/ui/button/Button";
import { ContextMenu } from "@/components/ui/context-menu/ContextMenu";
import {
  ContextMenuContent,
  ContextMenuGroup,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from "@/components/ui/context-menu/ContextMenu";
import { AlertDialog } from "@/components/ui/dialog/AlertDialog";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog/Dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from "@/components/ui/sheet/Sheet";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { useCredentials } from "@/store/authStore";
import { MeetingsI } from "@/types/meetings";
import {
  ArchiveRestore,
  Ban,
  ChevronDown,
  FileEdit,
  Pen,
  Trash2,
} from "lucide-react";
import * as React from "react";
import { Fragment, forwardRef, useState } from "react";
import { Link } from "react-router-dom";

interface MeetingProps {
  meeting: MeetingsI;
  queryParams: { before: string; after: string };
  children: React.ReactNode;
}

const Meeting = forwardRef<HTMLDivElement, MeetingProps>(
  ({ children, meeting, queryParams }, ref) => {
    const isMobile = useMediaQueryHook("sm");
    const { id, startDate, endDate, name, type, desc, isCancelled } = meeting;
    const { lvl } = useCredentials();
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const [openEditDialog, setEditDialog] = useState<boolean>(false);

    const [openDeletePopover, setDeletePopover] = useState<boolean>(false);

    const { data: meetingData = {}, isPending, isSuccess } = useGetMeeting(id);
    const { mutate } = useEditMeeting(queryParams);

    // const [editMeeting, results] = useEditMeetingMutation();

    const content = (
      <div>
        <MeetingInfoPopover
          basicData={{ startDate, endDate, type, desc, isCancelled }}
          data={meetingData}
          isSuccess={isSuccess}
          isFetching={isPending}
        />

        <div className={"flex flex-row gap-3 border-t-1 border-border p-4"}>
          <Link to={`/calendar/meeting/${meeting.id}`} className="mr-auto">
            <Button variant={"outline"} size={"sm"} variantColor={"muted"}>
              Więcej
            </Button>
          </Link>
          {lvl > 0 ? (
            <>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant={"ghost"}
                    variantColor={"muted"}
                    icon={<ChevronDown />}
                    iconPosition={"right"}
                    size={"sm"}
                  >
                    Edytuj
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent ref={ref} align={"end"}>
                  <DropdownMenuGroup>
                    <DropdownMenuItem onClick={() => setEditDialog(true)}>
                      <FileEdit className={"mr-2 h-4 w-4"} />
                      Edytuj
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() =>
                        mutate({
                          ...meetingData,
                          id,
                          isCancelled: !isCancelled,
                        })
                      }
                    >
                      {isCancelled ? (
                        <Fragment>
                          <ArchiveRestore className={"h-4 w-4"} /> Przywróć
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Ban className={"h-4 w-4"} /> Odwołaj
                        </Fragment>
                      )}
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
              <Button
                onClick={() => setDeletePopover(true)}
                variant={"ghost"}
                variantColor={"destructive"}
                icon={<Trash2 />}
                iconPosition={"left"}
                size={"sm"}
              >
                Usuń
              </Button>
            </>
          ) : null}
        </div>
      </div>
    );
    const mobileView = (
      <SheetContent ref={ref} side={"bottom"} className={"p-0"}>
        <SheetHeader
          className={
            "border-b-1 border-border p-4 text-xl font-semibold text-fg-primary"
          }
        >
          {name}
        </SheetHeader>
        {content}
      </SheetContent>
    );
    const desktopView = (
      <DialogContent
        ref={ref}
        className={"max-w-[45ch]"}
        overflowTransparent={true}
      >
        <DialogHeader className={"p-0"}>
          <DialogTitle
            className={
              "border-b-1 border-border p-4 text-xl font-semibold text-fg-primary"
            }
          >
            {name}
          </DialogTitle>
        </DialogHeader>
        <DialogBody className={"p-0"}>{content}</DialogBody>
      </DialogContent>
    );

    return (
      <>
        <Dialog open={openEditDialog} onOpenChange={setEditDialog}>
          <EditMeeting
            id={id}
            open={openEditDialog}
            onOpenChange={setEditDialog}
            queryParams={queryParams}
          />
        </Dialog>
        <AlertDialog open={openDeletePopover} onOpenChange={setDeletePopover}>
          <DeleteMeeting data={meeting} queryParams={queryParams} />
        </AlertDialog>
        <ContextMenu>
          <Dialog open={openPopover} onOpenChange={setOpenPopover}>
            <Sheet open={openPopover} onOpenChange={setOpenPopover}>
              <ContextMenuTrigger asChild>
                <DialogTrigger asChild>
                  <SheetTrigger asChild>{children}</SheetTrigger>
                </DialogTrigger>
              </ContextMenuTrigger>
              {isMobile ? mobileView : desktopView}
            </Sheet>
          </Dialog>

          {lvl > 0 && (
            <ContextMenuContent>
              <ContextMenuLabel>Ustawienia</ContextMenuLabel>
              <ContextMenuSeparator />
              <ContextMenuGroup>
                <ContextMenuItem
                  onClick={() => setDeletePopover(true)}
                  className={"text-fg-destructive"}
                >
                  <Trash2 className={"h-4 w-4"} />
                  Usuń
                </ContextMenuItem>
                <ContextMenuItem onClick={() => setEditDialog(true)}>
                  <Pen className={"h-4 w-4"} />
                  Edytuj
                </ContextMenuItem>
                <ContextMenuItem
                  onClick={() => mutate({ id, isCancelled: !isCancelled })}
                >
                  {isCancelled ? (
                    <Fragment>
                      <ArchiveRestore className={"h-4 w-4"} /> Przywróć
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Ban className={"h-4 w-4"} /> Odwołaj
                    </Fragment>
                  )}
                </ContextMenuItem>
              </ContextMenuGroup>
            </ContextMenuContent>
          )}
        </ContextMenu>
      </>
    );
  },
);

Meeting.displayName = "CalendarMeeting";

export default Meeting;

import { memo } from "react";

const FilesPageNoResults = memo(() => {
  return (
    <div className={"my-6"}>
      <h2 className={"text-center text-2xl font-semibold text-fg-primary"}>
        Brak wyników
      </h2>
      <p className={"mt-4 text-center text-xs text-fg-muted"}>
        Brak wyników spełniających kryteria wyszukiwania
      </p>
    </div>
  );
});

FilesPageNoResults.displayName = "FilesPageNoResults";

export default FilesPageNoResults;

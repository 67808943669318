import { Alert, AlertDescription } from "@/components/ui/alert/Alert";
import { Button } from "@/components/ui/button/Button";
import {
  AlertDialogBody,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/dialog/AlertDialog";
import { ErrorsT } from "@/page/pages/manage/ManageUsersMassImport";

export function UserMassImportError({ errors }: { errors: ErrorsT[] }) {
  return (
    <AlertDialogContent className={"max-w-[50ch]"}>
      <AlertDialogHeader>
        <AlertDialogTitle>Wystąpił problem z walidacją danych</AlertDialogTitle>
      </AlertDialogHeader>
      <AlertDialogBody className={"flex flex-col gap-4"}>
        <p className={"text-sx text-fg-secondary"}>
          Wystąpił problem z walidacją danych, sprawdź czy wszystkie pola są
          poprawnie uzupełnione
        </p>
        <div className={"flex flex-col gap-2"}>
          {errors.map(({ errors, data, index }, idx) => (
            <Alert key={idx}>
              <AlertDescription
                className={"flex flex-col gap-2 overflow-hidden"}
              >
                <h5 className={"text-sm font-medium"}>Indeks: {index}</h5>
                <p className={"w-full truncate text-sm"}>
                  {data.name}, {data.surname}, {data.email}, {data.phone}
                </p>
                <div>
                  {errors.map((error, idx) => (
                    <p key={idx} className={"text-xs text-fg-destructive"}>
                      *{error}
                    </p>
                  ))}
                </div>
              </AlertDescription>
            </Alert>
          ))}
        </div>
      </AlertDialogBody>
      <AlertDialogFooter>
        <AlertDialogCancel asChild>
          <Button>Zamknij</Button>
        </AlertDialogCancel>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
}

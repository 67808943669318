import ChatTimeToPresent from "@/components/features/chat/ChatTimeToPresent";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { Button } from "@/components/ui/button/Button";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuGroup,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/components/ui/context-menu/ContextMenu";
import Image from "@/components/ui/image/Image";
import { Link } from "@/components/ui/link/Link";
import AudioPlayer from "@/components/ui/media-viewer/AudioPlayer";
import VideoPlayer from "@/components/ui/media-viewer/VideoPlayer";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { useChatStore } from "@/store/chatStore";
import { MessageI, conversationTypeT } from "@/types/chat";
import { getAvatar } from "@/utils/getAvatar";
import getFileUrl from "@/utils/getFileUrl";
import { AudioMimeType, VideoMimeType } from "@vidstack/react/types/vidstack";
import { AnimatePresence, motion } from "framer-motion";
import { Copy, Download, File, Reply, Trash, User } from "lucide-react";
import { ReactNode, forwardRef, useMemo, useRef } from "react";

type ChatMessageProps = {
  message: MessageI;
  onDelete: (id: number) => void;
  conversationType: conversationTypeT;
};

const Message = forwardRef<HTMLDivElement, ChatMessageProps>(
  ({ message, conversationType, onDelete }, ref) => {
    const { tempUUID, sender, createdAt, id, type } = message;
    const { id: userId } = useCredentials();
    const isUserMessage = useMemo(
      () => sender?.id === userId,
      [sender, userId],
    );
    const userName = useMemo(() => {
      if (sender) {
        return isUserMessage
          ? "Ty"
          : sender.name + " " + sender.surname?.at(0) + ".";
      } else {
        return "Użytkownik został usunięty";
      }
    }, [isUserMessage, sender]);
    return (
      <div
        ref={ref}
        className={cn(
          "flex select-none items-center justify-start gap-2",
          isUserMessage ? "flex-row-reverse" : "flex-row",
        )}
        id={String(id)}
        data-message-date={createdAt}
        data-message-sender={sender?.id}
      >
        <ChatMessageMoreContext
          message={message}
          onDelete={onDelete}
          isUserMessage={isUserMessage}
        >
          <div
            className={cn(
              "flex",
              isUserMessage ? "flex-row" : "flex-row-reverse",
            )}
          >
            <div className={"w-10 min-w-10"} />
            <div
              className={cn(
                "flex max-w-[50ch] flex-col gap-1 rounded-md border-1 border-border p-2",
                isUserMessage
                  ? "border-bg-muted-subtle/60 bg-bg-muted-subtle/60"
                  : "bg-bg-container",
                type === "error" && "bg-bg-destructive-subtle",
              )}
            >
              <ChatMessageHeader
                avatarURL={sender?.avatarURL}
                userName={userName}
                isUserMessage={isUserMessage}
                conversationType={conversationType}
                createdAt={createdAt}
              />
              <ChatMessageContent
                userName={userName}
                message={message}
                isUserMessage={isUserMessage}
                conversationType={conversationType}
              />
              <ChatMessageFooter tempUUID={tempUUID} />
            </div>
          </div>
        </ChatMessageMoreContext>
      </div>
    );
  },
);

Message.displayName = "Message";

function ChatMessageMoreContext({
  children,
  onDelete,
  message,
  isUserMessage,
}: {
  onDelete: (id: number) => void;
  message: MessageI;
  isUserMessage: boolean;
  children: ReactNode;
}) {
  const downloadButtonRef = useRef<HTMLAnchorElement>(null);

  const { id, type, attachment, content } = message;
  const setReplyingTo = useChatStore.useSetReplyingTo();

  const handleDeleteMessage = () => {
    if (id) onDelete(id);
  };

  const handleReplyTo = () => {
    setReplyingTo(message);
  };

  const handleDownload = () => {
    if (!downloadButtonRef.current) return;
    downloadButtonRef.current.click();
  };

  const handleCopyText = async () => {
    await navigator.clipboard.writeText(content);
  };

  if (type === "deleted" || type === "error") {
    return children;
  }

  return (
    <ContextMenu>
      <ContextMenuTrigger asChild>{children}</ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuGroup>
          <ContextMenuItem onClick={handleReplyTo} disabled={!id}>
            <Reply className={"h-4 w-4"} />
            Odpowiedz
          </ContextMenuItem>
          {type === "text" ? (
            <ContextMenuItem onClick={handleCopyText}>
              <Copy className={"h-4 w-4"} />
              Kopiuj tekst
            </ContextMenuItem>
          ) : null}
          {attachment ? (
            <ContextMenuItem onClick={handleDownload}>
              <a
                ref={downloadButtonRef}
                href={getFileUrl(attachment.id)}
                className={"hidden"}
              />
              <Download className={"h-4 w-4"} />
              Pobierz
            </ContextMenuItem>
          ) : null}
          {isUserMessage ? (
            <ContextMenuItem
              onClick={handleDeleteMessage}
              disabled={!id}
              className={"text-fg-destructive"}
            >
              <Trash className={"h-4 w-4"} />
              Usuń
            </ContextMenuItem>
          ) : null}
        </ContextMenuGroup>
      </ContextMenuContent>
    </ContextMenu>
  );
}

function ChatMessageContent({
  message,
  conversationType,
  userName,
  isUserMessage,
}: {
  message: MessageI;
  isUserMessage: boolean;
  conversationType: conversationTypeT;
  userName: string;
}) {
  const { type, content, replyingTo, attachment, file, tempUUID } = message;

  const { fileURL, fileURLObject, mimeType } = useMemo(() => {
    if (attachment) {
      return {
        fileURL: getFileUrl(attachment.id),
        fileURLObject: undefined,
        mimeType: attachment.fileType,
      };
    } else if (file) {
      return {
        fileURL: undefined,
        fileURLObject: URL.createObjectURL(file),
        mimeType: file.type,
      };
    }
    return {
      fileURL: undefined,
      fileURLObject: undefined,
      mimeType: undefined,
    };
  }, [file, attachment]);

  const messageReplyToContent = useMemo(() => {
    if (!replyingTo) return;
    return (
      <div className={"flex gap-1"}>
        <span
          className={"w-1 max-w-1 shrink-0 grow rounded-md bg-bg-element"}
        />
        <div className={"flex flex-col gap-1"}>
          {conversationType !== "individual" ? (
            <p className={"text-xs text-fg-primary"}>{userName}</p>
          ) : null}

          <p
            className={
              "line-clamp-5 max-h-[10rem] text-ellipsis text-sm italic text-fg-muted"
            }
          >
            {replyingTo.content}
          </p>
        </div>
      </div>
    );
  }, [conversationType, replyingTo, userName]);
  const messageContent = useMemo(() => {
    switch (type) {
      case "text":
        return (
          <p
            className={
              "overflow-hidden whitespace-pre-line break-words text-sm"
            }
          >
            {content}
          </p>
        );
      case "deleted":
        return <p className={"text-sm italic text-fg-muted"}>{content}</p>;
      case "error":
        return (
          <p className={"text-sm italic text-fg-destructive"}>{content}</p>
        );
      case "image":
        return (
          <Image
            src={fileURL || fileURLObject}
            alt={content}
            thumbnail={attachment?.thumbnail}
            type={attachment?.fileType}
            className={"rounded-sm"}
            layoutClassName={"rounded-sm"}
            height={200}
          />
        );
      case "video":
        return (
          <VideoPlayer
            src={{
              src: fileURL || (fileURLObject as string),
              type: mimeType as VideoMimeType,
            }}
            title={attachment?.name ? attachment?.name : "video file"}
            className={cn("h-[204px]")}
          />
        );
      case "audio":
        return (
          <AudioPlayer
            src={{
              src: fileURL || (fileURLObject as string),
              type: mimeType as AudioMimeType,
            }}
            title={attachment?.name ? attachment?.name : "audio file"}
          />
        );
      case "file":
        return (
          <div
            className={cn(
              "flex items-center gap-2 rounded-sm p-2",
              isUserMessage ? "bg-bg-container" : "bg-bg-element",
            )}
          >
            {fileURL ? (
              <Link href={fileURL}>
                <Button
                  disabled={!!tempUUID}
                  variant={"ghost"}
                  variantColor={"muted"}
                  className={"rounded-sm"}
                  icon={<Download />}
                  iconPosition={"only"}
                />
              </Link>
            ) : (
              <div className={"grid h-11 w-11 place-content-center"}>
                <File className={"h-4 w-4"} />
              </div>
            )}
            <p className={"truncate text-sm"}>{content}</p>
          </div>
        );
    }
  }, [
    content,
    type,
    fileURL,
    fileURLObject,
    mimeType,
    attachment,
    tempUUID,
    isUserMessage,
  ]);
  return (
    <div className={"flex w-full flex-col gap-2"}>
      {messageReplyToContent}
      {messageContent}
    </div>
  );
}

type ChatMessageHeaderProps = {
  isUserMessage: boolean;
  conversationType: conversationTypeT;
  createdAt: string;
  userName: string;
  avatarURL?: string;
};

function ChatMessageHeader(props: ChatMessageHeaderProps) {
  const { conversationType, isUserMessage, createdAt, userName, avatarURL } =
    props;
  return (
    <div
      className={cn(
        "flex w-full items-center gap-2",
        isUserMessage ? "flex-row-reverse" : "flex-row",
      )}
    >
      {conversationType !== "individual" && !isUserMessage ? (
        <Avatar size={"sm"} className={"h-6 min-h-6 w-6 min-w-6 rounded-sm"}>
          <AvatarImage src={getAvatar(avatarURL)} />
          <AvatarFallback>
            <User className={"h-3 w-3"} />
          </AvatarFallback>
        </Avatar>
      ) : null}

      {conversationType !== "individual" ? (
        <p className={"text-xs font-medium text-fg-primary"}>{userName}</p>
      ) : null}
      <ChatTimeToPresent
        className={"text-xs text-fg-muted"}
        createdAt={createdAt}
      />
    </div>
  );
}

function ChatMessageFooter({ tempUUID }: { tempUUID: string | undefined }) {
  return (
    <AnimatePresence mode={"sync"}>
      {tempUUID && (
        <motion.div
          key={"sending-key"}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
          transition={{ type: "spring" }}
          className={"flex items-center gap-1 text-xs italic text-fg-muted"}
        >
          <Spinner variant={"muted"} size={"sm"} />
          Wysyłanie...
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export const ChatMessageSkeleton = forwardRef<HTMLDivElement>(
  (props = {}, ref) => {
    return (
      <div
        ref={ref}
        className={"w-full p-2 text-center text-sm italic text-fg-muted"}
        {...props}
      >
        Ładowanie...
      </div>
    );
  },
);

ChatMessageSkeleton.displayName = "ChatMessageSkeleton";

export default Message;

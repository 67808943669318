import { MutationStatus, useBatchMutation } from "@/api/api-utils";
import {
  FileUploadParams,
  deleteFile,
  editFile,
  getFile,
  getFilePermissions,
  uploadFile,
} from "@/api/endpoints/files";
import { queryClient } from "@/api/query-client";
import { EditFileType } from "@/schemas/files.schema";
import { DirectoryFileI, DirectoryI } from "@/types/files";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosProgressEvent } from "axios";
import { useCallback, useRef, useState } from "react";

export const useGetFileQuery = (id?: string) => {
  return useQuery({
    queryKey: ["files", id],
    queryFn: () => getFile(id),
    enabled: !!id,
  });
};

export const useGetFilePermissionsQuery = (
  id?: string,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: ["filePermissions", id],
    queryFn: () => getFilePermissions(id),
    enabled: enabled && !!id,
  });
};

export const useGetFilePermissionsQueries = (
  ids: string[],
  enabled: boolean = true,
) => {
  return useQueries({
    queries: ids.map((id) => ({
      queryKey: ["filePermissions", id],
      queryFn: () => getFilePermissions(id),
      enabled: enabled && !!id,
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
      };
    },
  });
};

export const useFileUploadMutation = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [progress, setProgress] = useState<number>(0);

  const progressCallBack = useCallback(
    (progressEvent: AxiosProgressEvent) => {
      const { loaded, total } = progressEvent;
      if (total) {
        setProgress(Math.round((loaded * 100) / total));
      }
    },
    [setProgress],
  );

  const mutation = useMutation<
    DirectoryFileI,
    AxiosError,
    Omit<FileUploadParams, "progressCallBack">
  >({
    mutationFn: (data) => {
      abortControllerRef.current = new AbortController();
      return uploadFile({
        ...data,
        abortController: abortControllerRef.current,
        progressCallBack,
      });
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData<DirectoryI>(
        ["directory", variables.directoryId],
        (prev) => {
          if (!prev) return;
          return {
            ...prev,
            files: [...prev.files, data],
          };
        },
      );
    },
    onSettled: (data, error, params) => {
      queryClient.invalidateQueries({
        queryKey: ["directory", params.directoryId],
      });
    },
  });

  const abort = useCallback(() => {
    setProgress(0);
    abortControllerRef.current?.abort();
    mutation.reset();
  }, [mutation, setProgress]);

  return { ...mutation, progress, abort };
};

export const useFilesUploadMutation = () => {
  const abortControllersRef = useRef<Map<string, AbortController>>(new Map());
  const [statuses, setStatuses] = useState<
    MutationStatus<Omit<FileUploadParams, "progressCallBack">, DirectoryFileI>[]
  >([]);

  const progressCallBack = useCallback(
    (id: string, progressEvent: AxiosProgressEvent) => {
      const { loaded, total } = progressEvent;
      if (total) {
        setStatuses((prev) =>
          prev.map((element) => {
            if (element.params.tempUUID === id) {
              return {
                ...element,
                progress: Math.round((loaded * 100) / total),
              };
            }
            return element;
          }),
        );
      }
    },
    [setStatuses],
  );

  const mutation = useBatchMutation({
    state: statuses,
    setState: setStatuses,
    mutationFn: (data) => {
      const tempUUID = data.tempUUID || "";
      const abortController = new AbortController();
      abortControllersRef.current.set(tempUUID, abortController);
      return uploadFile({
        ...data,
        abortController: abortControllersRef.current.get(
          tempUUID,
        ) as AbortController,
        progressCallBack: (event) => progressCallBack(tempUUID, event),
      });
    },
    onMutate: (data) => {
      const tempUUID = data.tempUUID || "";
      setStatuses((prev) =>
        prev.map((status) => {
          if (status.params.tempUUID === tempUUID) {
            return {
              ...status,
              abort: () => {
                const controller = abortControllersRef.current.get(tempUUID);
                controller?.abort();
              },
            };
          }
          return status;
        }),
      );
    },
    // onSuccess: (data, variables) => {
    //   console.log(data, variables);
    //   queryClient.setQueryData<DirectoryI>(
    //     ["directory", data.directoryId],
    //     (prev) => {
    //       if (!prev) return;
    //       return {
    //         ...prev,
    //         files: [...prev.files, data],
    //       };
    //     },
    //   );
    // },

    onSettled: (data, error, params) => {
      queryClient.invalidateQueries({
        queryKey: ["directory", params.directoryId],
      });
    },
  });

  const abortAll = useCallback(() => {
    abortControllersRef.current.forEach((controller) => controller.abort());
    abortControllersRef.current.clear();
    mutation.reset();
  }, [mutation, setStatuses]);

  const abort = useCallback(
    (tempUUID: string) => {
      const status = statuses.find(
        (status) => status.params.tempUUID === tempUUID,
      );
      if (status && status.abort) {
        status.abort();
      }
    },
    [statuses],
  );

  const clearStatuses = useCallback(() => {
    setStatuses([]);
  }, []);

  return { ...mutation, abort, abortAll, clearStatuses };
};

export const useEditFileMutation = (directoryId?: string) => {
  return useMutation({
    mutationKey: ["editFile"],
    mutationFn: (data: EditFileType) => editFile(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["directory", directoryId],
      });
    },
  });
};

export const useEditFilesMutation = (directoryId?: string) => {
  return useBatchMutation({
    mutationKey: ["editFile"],
    mutationFn: (data: EditFileType) => editFile(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["directory", directoryId],
      });
    },
  });
};

export const useDeleteFileMutation = (directoryId?: string) => {
  return useMutation({
    mutationKey: ["deleteFile"],
    mutationFn: (id: string) => deleteFile(id),
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: ["directory", directoryId] });
      const prevDirectory = queryClient.getQueryData<DirectoryI>([
        "directory",
        directoryId,
      ]);

      if (prevDirectory) {
        queryClient.setQueryData<DirectoryI>(
          ["directory", directoryId],
          (prev) => {
            if (!prev) return;
            return {
              ...prev,
              files: prev.files.filter((file) => file.id !== id),
            };
          },
        );
      }

      return { prevDirectory };
    },
    onError: (err, variables, context) => {
      if (context?.prevDirectory) {
        queryClient.setQueryData(
          ["directory", directoryId],
          context.prevDirectory,
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["directory", directoryId],
      });
    },
  });
};

export const useDeleteFilesMutation = (directoryId?: string) => {
  return useBatchMutation({
    mutationKey: ["deleteFile"],
    mutationFn: (id: string) => deleteFile(id),
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: ["directory", directoryId] });
      const prevDirectory = queryClient.getQueryData<DirectoryI>([
        "directory",
        directoryId,
      ]);

      if (prevDirectory) {
        queryClient.setQueryData<DirectoryI>(
          ["directory", directoryId],
          (prev) => {
            if (!prev) return;
            return {
              ...prev,
              files: prev.files.filter((file) => file.id !== id),
            };
          },
        );
      }

      return { prevDirectory };
    },
    onError: (err, variables, context) => {
      if (context?.prevDirectory) {
        queryClient.setQueryData(
          ["directory", directoryId],
          context?.prevDirectory,
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["directory", directoryId],
      });
    },
  });
};

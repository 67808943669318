import { Button } from "@/components/ui/button/Button";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { cn } from "@/lib/utils";
import { isEmptyString } from "@/utils/assertion";
import { getBase64 } from "@/utils/getBase64";
import { RefreshCcw } from "lucide-react";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { ImgHTMLAttributes, SyntheticEvent } from "react";

type NativeImageProps = ImgHTMLAttributes<HTMLImageElement>;

type ImageProps = {
  className?: string;
  layoutClassName?: string;
  loading?: NativeImageProps["loading"];
  onLoad?: NativeImageProps["onLoad"];
  onError?: NativeImageProps["onError"];
  type?: string;
  thumbnail?: string;
} & NativeImageProps;

export interface UseImageProps {
  src?: string;
  srcSet?: string;
  sizes?: string;
  onLoad?: NativeImageProps["onLoad"];
  onError?: NativeImageProps["onError"];
  ignoreFallback?: boolean;
  crossOrigin?: NativeImageProps["crossOrigin"];
  loading?: NativeImageProps["loading"];
}

type Status = "loading" | "failed" | "pending" | "loaded";
type ImageEvent = SyntheticEvent<HTMLImageElement, Event>;

function useImage({
  loading,
  src,
  srcSet,
  onLoad,
  onError,
  crossOrigin,
  sizes,
  ignoreFallback,
}: UseImageProps) {
  const [status, setStatus] = useState<Status>("pending");

  useEffect(() => {
    setStatus(src ? "loading" : "pending");
  }, [src]);

  const imageRef = useRef<HTMLImageElement | null>();

  const load = useCallback(() => {
    if (!src) return;

    flush();

    const img = new window.Image();

    img.src = src;
    if (crossOrigin) img.crossOrigin = crossOrigin;
    if (srcSet) img.srcset = srcSet;
    if (sizes) img.sizes = sizes;
    if (loading) img.loading = loading;

    img.onload = (event) => {
      flush();
      setStatus("loaded");
      onLoad?.(event as unknown as ImageEvent);
    };
    img.onerror = (error) => {
      flush();
      setStatus("failed");
      onError?.(error as any);
    };

    imageRef.current = img;
  }, [src, crossOrigin, srcSet, sizes, onLoad, onError, loading]);

  const flush = () => {
    if (imageRef.current) {
      imageRef.current.onload = null;
      imageRef.current.onerror = null;
      imageRef.current = null;
    }
  };

  useEffect(() => {
    if (ignoreFallback) return undefined;

    if (status === "loading") {
      load();
    }

    return () => {
      flush();
    };
  }, [status, load, ignoreFallback]);

  return { imageStatus: ignoreFallback ? "loaded" : status };
}

const Image = forwardRef<HTMLImageElement, ImageProps>(
  (
    {
      src,
      alt,
      type,
      thumbnail,
      className,
      layoutClassName,
      loading,
      onError,
      onLoad,
      srcSet,
      sizes,
      crossOrigin,
      ...props
    },
    ref,
  ) => {
    const [imageSrc, setImageSrc] = useState(src);

    const { imageStatus } = useImage({
      src: imageSrc,
      loading,
      onError,
      onLoad,
      ignoreFallback: false,
      srcSet,
      sizes,
      crossOrigin,
    });
    const isLoading = imageStatus === "loading";
    const isLoaded = imageStatus === "loaded";
    const isFailed = imageStatus === "failed";

    const retryFetch = () => {
      setImageSrc(`${src}?retry=${Date.now()}`);
    };

    const { w, h } = useMemo(() => {
      return {
        w: props.width
          ? typeof props.width === "number"
            ? `${props.width}px`
            : props.width
          : "fit-content",
        h: props.height
          ? typeof props.height === "number"
            ? `${props.height}px`
            : props.height
          : "auto",
      };
    }, [props?.width, props?.height]);

    const base64 = useMemo(() => {
      if (type && thumbnail) {
        return getBase64({
          fileType: type,
          base64: thumbnail,
          clear: true,
        });
      }
      return "";
    }, [thumbnail, type]);

    const source = useMemo(() => {
      if (isLoaded) {
        return imageSrc;
      }
      if (!isEmptyString(base64)) {
        return base64;
      }
    }, [base64, isLoaded, imageSrc]);
    return (
      <div className={cn("relative flex overflow-hidden", layoutClassName)}>
        <img
          ref={ref}
          src={source}
          alt={alt}
          className={cn(
            "rounded-sm object-contain text-center text-sm",
            !source && "opacity-0",
            className,
          )}
          style={{ height: h, width: w }}
          {...props}
        />

        <Spinner
          variant={"muted"}
          size={"lg"}
          className={cn(
            isLoading && source
              ? "absolute left-2/4 top-2/4 block -translate-x-2/4 -translate-y-2/4"
              : "hidden",
          )}
        />
        <Skeleton
          className={cn(className, source && "hidden")}
          style={{ height: h, width: w }}
        />

        <div
          className={cn(
            "absolute inset-0 z-10 flex h-full w-full flex-col items-center justify-center gap-2 bg-bg-container/40 backdrop-blur-sm",
            !isFailed && "hidden",
            className,
          )}
        >
          <Button
            onClick={(event) => {
              event.preventDefault();
              retryFetch();
            }}
            size={"lg"}
            variant={"ghost"}
            variantColor={"muted"}
            icon={<RefreshCcw />}
            iconPosition={"only"}
          />
        </div>
      </div>
    );
  },
);

Image.displayName = "Image";

export default Image;

import DirectoryCard from "@/components/features/files/DirectoryCard";
import FSEntitiesMenu from "@/components/features/files/FSEntitiesMenu";
import { Button } from "@/components/ui/button/Button";
import {
  ContextMenu,
  ContextMenuTrigger,
} from "@/components/ui/context-menu/ContextMenu";
import {
  DropdownMenu,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import useLongPress from "@/hooks/useLongPress";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { useFilesStorage } from "@/store/filesStore";
import { DirectoryDirI } from "@/types/files";
import { MoreVertical } from "lucide-react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

type FileStorageFileElementProps = {
  directory: DirectoryDirI;
};

export default function Directory({ directory }: FileStorageFileElementProps) {
  const breakpoint = useMediaQueryHook("sm");
  const [openDropdownMenu, setOpenDropdownMenu] = useState<boolean>(false);
  const [, setOpenContextMenu] = useState<boolean>(false);
  const navigate = useNavigate();

  const { OnFSEntityClick, isSelected, selectedSize } = useFilesStorage();

  const size = selectedSize();

  const isSelectedDirectory = isSelected(directory.id, "directory");

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter" && directory.permissions.read) {
        navigate(`/fs/dir/${directory?.id}`, { state: directory?.id });
      }
    },
    [directory, navigate],
  );

  const onDbClick = useCallback(() => {
    if (directory.permissions.read) {
      navigate(`/fs/dir/${directory?.id}`, { state: directory?.id });
    }
  }, [directory, navigate]);

  const onClick = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      const isTouch = event.nativeEvent.pointerType !== "mouse";
      if ((isTouch && size > 0) || !isTouch) {
        OnFSEntityClick({ directory: directory, event: event });
      } else if (isTouch) {
        navigate(`/fs/dir/${directory?.id}`, { state: directory?.id });
      }
    },
    [directory, navigate, OnFSEntityClick, size],
  );

  const onHold = useCallback(
    (event: React.PointerEvent) => {
      OnFSEntityClick({ directory: directory, event: event });
    },
    [directory, OnFSEntityClick],
  );

  const { onPointerDown, onPointerMove, onPointerUp } = useLongPress(onHold, {
    preventDefault: true,
  });

  const handleOpenDropdownMenu = useCallback(
    (bool: boolean) => {
      setOpenDropdownMenu(bool);
      OnFSEntityClick({ directory: directory });
    },
    [directory],
  );
  const handleOpenContextMenu = useCallback(
    (bool: boolean) => {
      setOpenContextMenu(bool);
      OnFSEntityClick({ directory: directory });
    },
    [directory],
  );

  return (
    <ContextMenu onOpenChange={handleOpenContextMenu}>
      <DropdownMenu
        onOpenChange={handleOpenDropdownMenu}
        open={openDropdownMenu}
      >
        <ContextMenuTrigger disabled={breakpoint} asChild>
          <DirectoryCard
            tabIndex={0}
            aria-selected={isSelectedDirectory}
            onClick={onClick}
            onDoubleClick={onDbClick}
            onKeyDown={onKeyDown}
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}
            directory={directory}
            menuButton={
              <DropdownMenuTrigger asChild>
                <Button
                  disabled={size > 1}
                  variant={"ghost"}
                  variantColor={"muted"}
                  icon={<MoreVertical />}
                  iconPosition={"only"}
                  size={"sm"}
                />
              </DropdownMenuTrigger>
            }
            className={cn(
              "bg-bg-container",
              isSelectedDirectory && "bg-bg-element-selected border-fg-brand",
            )}
          />
        </ContextMenuTrigger>
        <FSEntitiesMenu
          element={directory}
          type={"directory"}
          showContextMenu={!breakpoint}
          showDropdownMenu={true}
        />
      </DropdownMenu>
    </ContextMenu>
  );
}

import CreatePost from "@/components/features/manage/manage-posts/CreatePost";
import { Button } from "@/components/ui/button/Button";
import { Dialog } from "@/components/ui/dialog/Dialog";
import { Label } from "@/components/ui/label/Label";
import { FileUp, ImagePlus, Video } from "lucide-react";
import { Fragment, useState } from "react";

export default function HomeCreatePost() {
  const [openPostAdd, setOpenPostAdd] = useState<boolean>(false);
  const [, setForceFocus] = useState<
    "input" | "image" | "video" | "file" | undefined
  >(undefined);

  return (
    <Fragment>
      <Dialog open={openPostAdd} onOpenChange={setOpenPostAdd}>
        <CreatePost onOpenChange={setOpenPostAdd} />
      </Dialog>
      <div
        className={
          "flex w-full flex-col gap-5 rounded-lg border-1 border-border bg-bg-container p-4"
        }
      >
        <Label className={"text-xl font-semibold text-fg-primary"}>
          Utwórz Post
        </Label>
        <div className={"flex flex-col gap-3"}>
          <button
            onClick={() => {
              setForceFocus("input");
              setOpenPostAdd(true);
            }}
            className={
              "transition-color inline-flex min-h-13 w-full cursor-pointer rounded-md border-1 bg-bg-container px-4 py-2 text-md text-fg-muted ring-offset-bg duration-100 hover:bg-bg-element/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ring"
            }
          >
            Napisz wiadomość...
          </button>
          <div className={"flex w-full justify-end gap-2"}>
            <Button
              type={"button"}
              size={"sm"}
              variant={"flat"}
              variantColor={"muted"}
              icon={<ImagePlus />}
              iconPosition={"only"}
              onClick={() => {
                setForceFocus("image");
                setOpenPostAdd(true);
              }}
            />
            <Button
              type={"button"}
              size={"sm"}
              variant={"flat"}
              variantColor={"muted"}
              icon={<Video />}
              iconPosition={"only"}
              onClick={() => {
                setForceFocus("video");
                setOpenPostAdd(true);
              }}
            />
            <Button
              type={"button"}
              size={"sm"}
              variant={"flat"}
              variantColor={"muted"}
              icon={<FileUp />}
              iconPosition={"only"}
              onClick={() => {
                setForceFocus("file");
                setOpenPostAdd(true);
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

import { useInfiniteQueryResult } from "@/api/api-utils";
import { useGetGroupsInfiniteQuery } from "@/api/queries/groupsQueries";
import { useGetLocationsQuery } from "@/api/queries/locationsQueries";
import { useGetUsersInfiniteQuery } from "@/api/queries/usersQueries";
import ScheduleElement from "@/components/features/calendar/form/ScheduleElement";
import { Accordion } from "@/components/ui/accordion/Accordion";
import { Button } from "@/components/ui/button/Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command/Command";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogBody,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/dialog/AlertDialog";
import {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormMessages,
} from "@/components/ui/form/Form";
import { Input, InputActionButton } from "@/components/ui/input/Input";
import { Checkbox } from "@/components/ui/input/checkbox/Checkbox";
import {
  DatetimePicker,
  TimePicker,
} from "@/components/ui/input/datetime-picker/DatetimePicker";
import { Textarea } from "@/components/ui/input/textarea/Textarea";
import { Label } from "@/components/ui/label/Label";
import {
  LiElement,
  LiElementSeparator,
} from "@/components/ui/list/ListElement";
import SelectDataList from "@/components/ui/list/SelectDataList";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover/Popover";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs/Tabs";
import {
  ToggleDeleteEntity,
  ToggleGroup,
  ToggleGroupItem,
  ToggleParseToIds,
} from "@/components/ui/toggle/ToggleGroup";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { cn } from "@/lib/utils";
import {
  CalendarFormSchema,
  CalendarFormSchemaType,
} from "@/schemas/meeting.schema";
import { GroupI } from "@/types/groups";
import { UserI, UsersI } from "@/types/users";
import { DialogTitle } from "@radix-ui/react-dialog";
import { TabsContent } from "@radix-ui/react-tabs";
import {
  ArrowRight,
  Check,
  ChevronDown,
  ChevronLeft,
  ClipboardPaste,
  Clock,
  Plus,
  Search,
  X,
} from "lucide-react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { AnyObjectSchema, ValidationError } from "yup";

const useCalendarResolver = (
  validationSchema: AnyObjectSchema,
  method: "once" | "schedule",
) =>
  useCallback(
    async (data: CalendarFormSchemaType) => {
      try {
        const values = await (method === "once"
          ? validationSchema
              .pick([
                "name",
                "desc",
                "date",
                "startTime",
                "endTime",
                "type",
                "meetingURL",
                "locationId",
              ])
              .validate(data, { abortEarly: false })
          : validationSchema
              .pick([
                "name",
                "desc",
                "startDate",
                "endDate",
                "excludeHolidays",
                "schedule",
              ])
              .validate(data, { abortEarly: false }));

        return {
          values,
          errors: {},
        };
      } catch (error: unknown) {
        if (error instanceof ValidationError) {
          return {
            values: {},
            errors: error.inner.reduce(
              (
                allErrors: Record<string, { type: string; message: string }>,
                currentError,
              ) => ({
                ...allErrors,
                [currentError.path as string]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {},
            ),
          };
        }
        return {
          values: {},
          errors: {
            _error: {
              type: "unknown",
              message: "An unknown error occurred.",
            },
          },
        };
      }
    },
    [validationSchema, method],
  );

type DialogCalendarContentProps = {
  callback: (data: CalendarFormSchemaType) => void;
  onOpenChange: (open: boolean) => void;
  formData?: CalendarFormSchemaType | undefined;
  defaultSelectedUsers?: UserI[] | undefined;
  defaultSelectedGroups?: GroupI[] | undefined;
  defaultValues?: Partial<CalendarFormSchemaType>;
};

export default function ManipulateMeetingContent({
  callback,
  onOpenChange,
  formData,
  defaultSelectedUsers,
  defaultSelectedGroups,
  defaultValues,
}: DialogCalendarContentProps) {
  const [methodType, setMethodType] = useState<"once" | "schedule">("once");
  const form = useForm<CalendarFormSchemaType>({
    mode: "onChange",
    defaultValues: {
      name: "",
      desc: "",
      method: "once",
      type: "remote",
      ...defaultValues,
    },
    values: formData,
    resolver: useCalendarResolver(CalendarFormSchema, methodType),
  });

  const { watch, setValue, register, unregister } = form;
  const { name, type, method } = watch();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "schedule",
  });

  useEffect(() => {
    if (type === "remote") {
      setValue("locationId", undefined);
    } else if (type === "stationary") {
      setValue("meetingURL", undefined);
    }
  }, [type, setValue]);

  useEffect(() => {
    if (method == "once") {
      setMethodType("once");
      unregister(["excludeHolidays", "schedule", "startDate", "endDate"], {
        keepValue: true,
        keepIsValid: true,
      });
    } else {
      setMethodType("schedule");
      unregister(
        ["date", "startTime", "endTime", "type", "meetingURL", "locationId"],
        { keepValue: true, keepIsValid: true },
      );
    }
  }, [register, unregister, method]);

  const { data: locations = [] } = useGetLocationsQuery();

  const onSubmit = (data: CalendarFormSchemaType) => {
    callback(data);
  };

  const handleAddNewScheduleEntity = () => {
    append({
      day: "1",
      type: "remote",
      locationId: undefined,
      meetingURL: undefined,
      start: "",
      end: "",
    });
  };

  const handlePaste = async () => {
    if (!navigator.clipboard) {
      return;
    }
    const text = await navigator.clipboard.readText();
    if (text) {
      setValue("meetingURL", text);
    }
  };

  const [openCloseAlert, setOpenCloseAlert] = useState<boolean>(false);

  const handleCloseDialog = ({ isProtected = false } = {}) => {
    if (isProtected) {
      setOpenCloseAlert(true);
    } else {
      onOpenChange(false);
    }
  };
  const handleCloseConfirmationAlert = () => {
    setOpenCloseAlert(false);
    onOpenChange(false);
  };

  //tabs
  const [tab, setTab] = useState("main");

  //search value
  const [searchUserValue, setSearchUserValue] = useState<string>("");
  const searchUserDebouncedValue = useDebounceValue(searchUserValue, 400);

  const [searchGroupValue, setSearchGroupValue] = useState<string>("");
  const searchGroupDebouncedValue = useDebounceValue(searchGroupValue, 400);

  //selected Entities
  const [selectedUsers, setSelectedUsers] = useState<UsersI[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<GroupI[]>([]);

  const selectedUsersValue = ToggleParseToIds(selectedUsers);
  const selectedGroupsValue = ToggleParseToIds(selectedGroups);

  const handleDeleteUser = (value: string[]) => {
    ToggleDeleteEntity(setSelectedUsers, value);
  };
  const handleDeleteGroup = (value: string[]) => {
    ToggleDeleteEntity(setSelectedGroups, value);
  };

  useEffect(() => {
    setValue(
      "userArr",
      selectedUsers.map(({ id }) => id),
    );
  }, [selectedUsers, setValue]);
  useEffect(() => {
    setValue(
      "groupArr",
      selectedGroups.map(({ id }) => id),
    );
  }, [selectedGroups, setValue]);

  useEffect(
    () => defaultSelectedUsers && setSelectedUsers(defaultSelectedUsers),
    [defaultSelectedUsers],
  );
  useEffect(
    () => defaultSelectedGroups && setSelectedGroups(defaultSelectedGroups),
    [defaultSelectedGroups],
  );

  const getUsersInfiniteQuery = useGetUsersInfiniteQuery({
    name: searchUserDebouncedValue,
  });

  const {
    data: users,
    isEmpty: usersIsEmpty,
    noResults: usersNoResults,
  } = useInfiniteQueryResult(
    getUsersInfiniteQuery.data,
    searchUserDebouncedValue,
  );

  const getGroupsInfiniteQuery = useGetGroupsInfiniteQuery({
    name: searchGroupDebouncedValue,
  });

  const {
    data: groups,
    isEmpty: groupsIsEmpty,
    noResults: groupsNoResults,
  } = useInfiniteQueryResult(
    getGroupsInfiniteQuery.data,
    searchGroupDebouncedValue,
  );

  return (
    <>
      <AlertDialog open={openCloseAlert} onOpenChange={setOpenCloseAlert}>
        <AlertDialogContent className={"w-[45ch]"}>
          <AlertDialogHeader>
            <AlertDialogTitle>Napewno chcesz zamknąć te okno?</AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogBody>
            Czy napewno chesz zamknąć formularz tworzenia spotkania. Twoje
            zmiany nie zostaną zachowane.
          </AlertDialogBody>
          <AlertDialogFooter>
            <AlertDialogCancel variantColor={"muted"}>Anuluj</AlertDialogCancel>
            <AlertDialogAction
              variantColor={"destructive"}
              onClick={handleCloseConfirmationAlert}
            >
              Potwierdź
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <DialogHeader
        Close={
          <Button
            onClick={() =>
              handleCloseDialog(tab === "main" ? {} : { isProtected: true })
            }
            variant={"ghost"}
            variantColor={"muted"}
            size={"sm"}
            iconPosition={"only"}
            icon={<X />}
            className={"absolute right-1 top-1 rounded-lg"}
          />
        }
      >
        <DialogTitle>{name}</DialogTitle>
      </DialogHeader>
      <DialogBody className={"relative flex flex-col gap-6 p-0"}>
        <Tabs onValueChange={setTab} value={tab}>
          <TabsContent value={"main"}>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className={"flex flex-col"}
              >
                <div
                  className={cn(
                    "flex flex-col gap-4 p-4",
                    formData && "hidden",
                  )}
                >
                  <div className={"flex h-10 items-center justify-between"}>
                    <Label className={"font-medium"}>Typ:</Label>
                  </div>
                  <div className={"flex flex-col gap-3"}>
                    <FormField
                      name={"method"}
                      control={form.control}
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Tabs
                              onValueChange={field.onChange}
                              value={field.value}
                            >
                              <TabsList className={"grid w-full grid-cols-2"}>
                                <TabsTrigger value={"once"}>
                                  Jednorazowo
                                </TabsTrigger>
                                <TabsTrigger value={"schedule"}>
                                  Harmonogram
                                </TabsTrigger>
                              </TabsList>
                            </Tabs>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div
                  className={"flex flex-col gap-4 border-t-1 border-border p-4"}
                >
                  <div className={"flex h-10 items-center justify-between"}>
                    <Label className={"font-medium"}>Podstawowe dane:</Label>
                  </div>
                  <div className={"flex flex-col gap-3"}>
                    <FormField
                      name={"name"}
                      control={form.control}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Nazwa</FormLabel>
                          <FormControl>
                            <Input placeholder={"Nazwa"} {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name={"desc"}
                      control={form.control}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Opis{" "}
                            <small className={"text-sm text-fg-muted"}>
                              (opcionalne)
                            </small>
                          </FormLabel>
                          <FormControl>
                            <Textarea
                              placeholder={"Opis"}
                              className={"min-h-[3rem]"}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>

                {method === "once" ? (
                  <Fragment>
                    <div
                      className={
                        "flex flex-col gap-4 border-t-1 border-border p-4"
                      }
                    >
                      <div className={"flex h-10 items-center justify-between"}>
                        <Label>Data:</Label>
                      </div>
                      <div className={"flex flex-col gap-3"}>
                        <FormField
                          name={"date"}
                          control={form.control}
                          render={({ field }) => (
                            <FormItem className={"w-full"}>
                              <FormControl>
                                <DatetimePicker
                                  onJsDateChange={field.onChange}
                                  jsDate={field.value}
                                  aria-label={"date-time"}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                        <fieldset className={"flex w-full items-center gap-2"}>
                          <FormField
                            name={"startTime"}
                            control={form.control}
                            render={({ field }) => (
                              <FormItem className={"w-full"}>
                                <FormControl>
                                  <TimePicker
                                    jsTime={field.value}
                                    jsTimeOnChange={field.onChange}
                                    hourCycle={24}
                                    startContent={<Clock className={"ml-2"} />}
                                    aria-label={"time-start"}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                          <ArrowRight
                            className={"h-4 w-4 shrink-0 stroke-fg-muted"}
                          />
                          <FormField
                            name={"endTime"}
                            control={form.control}
                            render={({ field }) => (
                              <FormItem className={"w-full"}>
                                <FormControl>
                                  <TimePicker
                                    jsTime={field.value}
                                    jsTimeOnChange={field.onChange}
                                    hourCycle={24}
                                    startContent={<Clock className={"ml-2"} />}
                                    aria-label={"time-end"}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </fieldset>
                        <FormMessages
                          keys={["date", "startTime", "endTime"]}
                          listView
                        />
                      </div>
                    </div>
                    <div
                      className={
                        "flex flex-col gap-4 border-t-1 border-border p-4"
                      }
                    >
                      <div className={"flex h-10 items-center justify-between"}>
                        <Label>Lokalizacja:</Label>
                        <div className={"flex gap-2"}>
                          <FormField
                            name={"type"}
                            control={form.control}
                            render={({ field }) => {
                              const handleChange = (value: string) => {
                                if (value.length > 0) field.onChange(value);
                              };
                              return (
                                <FormItem>
                                  <FormControl>
                                    <ToggleGroup
                                      type={"single"}
                                      className={"gap-1"}
                                      onValueChange={handleChange}
                                      value={field.value}
                                    >
                                      <ToggleGroupItem value={"remote"}>
                                        Zdalnie
                                      </ToggleGroupItem>
                                      <ToggleGroupItem value={"stationary"}>
                                        Stacionarnie
                                      </ToggleGroupItem>
                                    </ToggleGroup>
                                  </FormControl>
                                </FormItem>
                              );
                            }}
                          />
                        </div>
                      </div>

                      {type === "remote" && (
                        <FormField
                          name={"meetingURL"}
                          control={form.control}
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <Input
                                  placeholder={"Link do spotkania"}
                                  endContent={
                                    <InputActionButton
                                      icon={<ClipboardPaste />}
                                      onClick={handlePaste}
                                    />
                                  }
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}
                      {type === "stationary" && (
                        <FormField
                          name={"locationId"}
                          control={form.control}
                          render={({ field }) => (
                            <FormItem className={"flex flex-col"}>
                              <Popover modal={true}>
                                <PopoverTrigger asChild>
                                  <FormControl>
                                    <Button
                                      variant={"outline"}
                                      variantColor={"muted"}
                                      role={"combobox"}
                                      className={cn(
                                        "justify-start",
                                        !field.value && "text-fg-muted",
                                      )}
                                      iconPosition={"right"}
                                      icon={
                                        <ChevronDown className={"ml-auto"} />
                                      }
                                    >
                                      {field.value
                                        ? locations.find(
                                            ({ id }) => id === field.value,
                                          )?.name
                                        : "Wybierz lokalizację..."}
                                    </Button>
                                  </FormControl>
                                </PopoverTrigger>
                                <PopoverContent
                                  align={"start"}
                                  className={
                                    "h-[25vh] max-h-[300px] border-none p-0"
                                  }
                                >
                                  <Command>
                                    <CommandInput
                                      placeholder={"Wyszukaj lokalizację..."}
                                    />
                                    <CommandEmpty>
                                      Nie znaleziono lokalizacji.
                                    </CommandEmpty>
                                    <CommandGroup className={"overflow-auto"}>
                                      {locations.map(({ id, name }) => (
                                        <CommandItem
                                          value={name}
                                          key={id}
                                          onSelect={() => {
                                            form.setValue("locationId", id);
                                          }}
                                        >
                                          <Check
                                            className={cn(
                                              "mr-2 h-4 w-4",
                                              id === field.value
                                                ? "opacity-100"
                                                : "opacity-0",
                                            )}
                                          />
                                          {name}
                                        </CommandItem>
                                      ))}
                                    </CommandGroup>
                                  </Command>
                                </PopoverContent>
                              </Popover>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div
                      className={
                        "flex flex-col gap-4 border-t-1 border-border p-4"
                      }
                    >
                      <div className={"flex h-10 items-center justify-between"}>
                        <Label>Data:</Label>
                      </div>
                      <div className={"flex flex-col gap-3"}>
                        <fieldset
                          className={"flex w-full flex-wrap items-center gap-3"}
                        >
                          <FormField
                            name={"startDate"}
                            control={form.control}
                            render={({ field }) => (
                              <FormItem
                                className={"w-full flex-row items-center gap-3"}
                              >
                                <FormLabel className={"text-sm"}>Od:</FormLabel>
                                <FormControl>
                                  <DatetimePicker
                                    onJsDateChange={field.onChange}
                                    jsDate={field.value}
                                    aria-label={"date-time-start"}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                          <FormField
                            name={"endDate"}
                            control={form.control}
                            render={({ field }) => (
                              <FormItem
                                className={"w-full flex-row items-center gap-3"}
                              >
                                <FormLabel className={"text-sm"}>Do:</FormLabel>
                                <FormControl>
                                  <DatetimePicker
                                    onJsDateChange={field.onChange}
                                    jsDate={field.value}
                                    aria-label={"date-time-end"}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                          <FormMessages
                            keys={["startDate", "endDate"]}
                            listView
                          />
                        </fieldset>
                        <FormField
                          name={"excludeHolidays"}
                          control={form.control}
                          render={({ field }) => (
                            <FormItem
                              className={"flex flex-row items-center gap-2"}
                            >
                              <FormControl>
                                <Checkbox
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                              <FormLabel>Pomiń święta i dni wolne</FormLabel>
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        "flex flex-col gap-4 border-t-1 border-border p-4"
                      }
                    >
                      <div className={"flex h-10 items-center justify-between"}>
                        <Label>Spotkania:</Label>
                        <Button
                          size={"sm"}
                          variant={"flat"}
                          variantColor={"muted"}
                          iconPosition={"left"}
                          icon={<Plus />}
                          onClick={handleAddNewScheduleEntity}
                        >
                          Dodaj
                        </Button>
                      </div>
                      <div className={"flex flex-col gap-3"}>
                        <Accordion
                          type={"single"}
                          collapsible
                          className={"flex flex-col gap-3"}
                        >
                          {fields.map((item, index) => {
                            return (
                              <ScheduleElement
                                key={item.id}
                                form={form}
                                index={index}
                                remove={remove}
                              />
                            );
                          })}
                        </Accordion>
                      </div>
                    </div>
                  </Fragment>
                )}
                <div
                  className={"flex flex-col gap-4 border-t-1 border-border p-4"}
                >
                  <div className={"flex h-10 items-center justify-between"}>
                    <Label>Użytkownicy:</Label>
                    <Button
                      size={"sm"}
                      variant={"flat"}
                      variantColor={"muted"}
                      iconPosition={"left"}
                      icon={<Plus />}
                      onClick={() => {
                        setTab("usersSelect");
                      }}
                    >
                      Dodaj
                    </Button>
                  </div>
                  <div className={"flex flex-wrap gap-1"}>
                    <ToggleGroup
                      type={"multiple"}
                      className={"flex-wrap justify-start"}
                      removable={true}
                      value={selectedUsersValue}
                      onValueChange={handleDeleteUser}
                    >
                      {selectedUsers.map(({ id, name, surname }) => (
                        <ToggleGroupItem
                          size={"md"}
                          variant={"outline"}
                          key={id.toString()}
                          value={id.toString()}
                        >
                          {name + " " + surname}
                        </ToggleGroupItem>
                      ))}
                    </ToggleGroup>
                  </div>
                </div>
                <div
                  className={"flex flex-col gap-4 border-t-1 border-border p-4"}
                >
                  <div className={"flex h-10 items-center justify-between"}>
                    <Label>Grupy:</Label>
                    <Button
                      size={"sm"}
                      variant={"flat"}
                      variantColor={"muted"}
                      iconPosition={"left"}
                      icon={<Plus />}
                      onClick={() => {
                        setTab("groupsSelect");
                      }}
                    >
                      Dodaj
                    </Button>
                  </div>
                  <div className={"flex flex-wrap gap-1"}>
                    <ToggleGroup
                      type={"multiple"}
                      className={"flex-wrap justify-start"}
                      removable={true}
                      value={selectedGroupsValue}
                      onValueChange={handleDeleteGroup}
                    >
                      {selectedGroups.map(({ id, name }) => (
                        <ToggleGroupItem
                          size={"md"}
                          variant={"outline"}
                          key={id.toString()}
                          value={id.toString()}
                        >
                          {name}
                        </ToggleGroupItem>
                      ))}
                    </ToggleGroup>
                  </div>
                </div>
              </form>
            </Form>
          </TabsContent>
          <TabsContent value={"usersSelect"}>
            <div className={"sticky top-0 z-10 flex gap-3 bg-bg-container p-4"}>
              <Button
                variant={"outline"}
                variantColor={"muted"}
                icon={<ChevronLeft />}
                iconPosition={"only"}
                onClick={() => {
                  setTab("main");
                }}
              />

              <Input
                placeholder={"Wyszukaj..."}
                value={searchUserValue}
                onChange={(e) => setSearchUserValue(e.target.value)}
                startContent={<Search className={"ml-2"} />}
                endContent={
                  <InputActionButton
                    disabled={!searchUserValue.length}
                    onClick={() => setSearchUserValue("")}
                    icon={<X />}
                  />
                }
              />
            </div>
            <div className={"px-4"}>
              <SelectDataList
                query={getUsersInfiniteQuery}
                selected={selectedUsers}
                setSelected={setSelectedUsers}
                isEmpty={usersIsEmpty}
                isNoResults={usersNoResults}
                emptyMessage={"Wyszukaj użytkownika"}
                searchNoResultsMessage={"Nie znaleziono użytkownika"}
                loadingElementPros={{
                  hasAvatar: true,
                  hasDetail: true,
                  hasTitle: true,
                }}
              >
                {!searchUserDebouncedValue && (
                  <>
                    {selectedUsers.map((entity) => (
                      <LiElement
                        key={entity.id}
                        entity={entity}
                        data={{
                          title: entity?.name + " " + entity?.surname,
                          detail: entity?.email,
                          avatarURL: entity?.avatarURL,
                        }}
                      />
                    ))}
                    <LiElementSeparator />
                  </>
                )}
                {users.map((entity) => (
                  <LiElement
                    key={entity.id}
                    entity={entity}
                    data={{
                      title: entity.name + " " + entity.surname,
                      detail: entity.email,
                      avatarURL: entity.avatarURL,
                    }}
                  />
                ))}
              </SelectDataList>
            </div>
          </TabsContent>
          <TabsContent value={"groupsSelect"}>
            <div className={"sticky top-0 z-10 flex gap-3 bg-bg-container p-4"}>
              <Button
                variant={"outline"}
                variantColor={"muted"}
                icon={<ChevronLeft />}
                iconPosition={"only"}
                onClick={() => {
                  setTab("main");
                }}
              />

              <Input
                placeholder={"Wyszukaj..."}
                value={searchGroupValue}
                onChange={(e) => setSearchGroupValue(e.target.value)}
                startContent={<Search className={"ml-2"} />}
                endContent={
                  <InputActionButton
                    disabled={!searchGroupValue.length}
                    onClick={() => setSearchGroupValue("")}
                    icon={<X />}
                  />
                }
              />
            </div>
            <div className={"px-4"}>
              <SelectDataList
                query={getGroupsInfiniteQuery}
                selected={selectedGroups}
                setSelected={setSelectedGroups}
                isEmpty={groupsIsEmpty}
                isNoResults={groupsNoResults}
                emptyMessage={"Wyszukaj grupy"}
                searchNoResultsMessage={"Nie znaleziono grupy o podanej nazwie"}
                loadingElementPros={{
                  hasAvatar: true,
                  hasDetail: true,
                  hasTitle: true,
                }}
              >
                {!searchGroupDebouncedValue && (
                  <>
                    {selectedGroups.map((entity) => (
                      <LiElement
                        key={entity.id}
                        entity={entity}
                        data={{
                          title: entity.name,
                          avatarURL: "",
                        }}
                      />
                    ))}
                    <LiElementSeparator />
                  </>
                )}

                {groups.map((entity) => (
                  <LiElement
                    key={entity.id}
                    entity={entity}
                    data={{
                      title: entity.name,
                      detail: entity.users.length + " użytkownik/ów",
                      avatarURL: "",
                    }}
                  />
                ))}
              </SelectDataList>
            </div>
          </TabsContent>
        </Tabs>
      </DialogBody>
      <DialogFooter>
        {tab === "main" && (
          <Fragment>
            <Button
              variant={"flat"}
              variantColor={"muted"}
              onClick={() => handleCloseDialog({ isProtected: false })}
            >
              Anuluj
            </Button>
            <Button
              onClick={form.handleSubmit(onSubmit)}
              variant={"flat"}
              variantColor={"brand"}
            >
              Zapisz
            </Button>
          </Fragment>
        )}
      </DialogFooter>
    </>
  );
}

import { httpErrorHandler } from "@/api/api";
import {
  PostMeetingPropsT,
  PostMeetingSchedulePropsT,
} from "@/api/endpoints/meetings";
import { usePostMeetingMutation as usePostMeeting } from "@/api/queries/meetingsQuery";
import { usePostScheduleMeetingMutation as usePostScheduleMeeting } from "@/api/queries/meetingsQuery";
import ManipulateMeetingContent from "@/components/features/calendar/form/ManipulateMeetingContent";
import { convertFormToData } from "@/components/features/calendar/form/convert-data";
import { DialogContent } from "@/components/ui/dialog/Dialog";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { useToast } from "@/components/ui/toast/useToast";
import { CalendarFormSchemaType } from "@/schemas/meeting.schema";
import { GroupsI } from "@/types/groups";
import { UsersI } from "@/types/users";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router";

type CreateMeetingProps = {
  onClose?: () => void;
  onOpenChange: (open: boolean) => void;
  defaultValues?: Partial<CalendarFormSchemaType> & {
    groups?: GroupsI[];
    users?: UsersI[];
  };
};

export default function CreateMeeting({
  onClose,
  onOpenChange,
  defaultValues,
}: CreateMeetingProps) {
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const { mutateAsync: MeetingMutate, isPending: MeetingPending } =
    usePostMeeting();
  const {
    mutateAsync: MeetingScheduleMutate,
    isPending: MeetingSchedulePending,
  } = usePostScheduleMeeting();
  const isLoading = MeetingPending || MeetingSchedulePending;

  const clearLocationState = useCallback(() => {
    navigate(
      { pathname: location.pathname, search: location.search },
      { replace: true },
    );
  }, [navigate, location.pathname, location.search]);

  const handleClose = () => {
    clearLocationState();
    onClose?.();
  };

  const onSubmit = (data: CalendarFormSchemaType) => {
    if (data.method === "once") {
      const meeting = convertFormToData(data) as PostMeetingPropsT;
      MeetingMutate(meeting)
        .then(() => {
          onOpenChange(false);
        })
        .catch((error) => {
          const { title, detail } = httpErrorHandler(error);
          toast({
            title: title,
            description: detail,
            variant: "destructive",
          });
        });
    } else {
      const meeting = convertFormToData(data) as PostMeetingSchedulePropsT;
      MeetingScheduleMutate(meeting)
        .then(() => {
          onOpenChange(false);
        })
        .catch((error) => {
          const { title, detail } = httpErrorHandler(error);
          toast({
            title: title,
            description: detail,
            variant: "destructive",
          });
        });
    }
  };

  return (
    <DialogContent
      className={"h-[calc(100dvh-var(--space-11))] max-w-[35rem]"}
      onInteractOutside={(e) => e.preventDefault()}
      onPointerDownOutside={(e) => e.preventDefault()}
      onCloseAutoFocus={handleClose}
    >
      {isLoading && (
        <div
          className={
            "absolute inset-0 z-20 m-auto flex flex-wrap items-center justify-center gap-3 bg-bg-container"
          }
        >
          <Spinner size={"lg"} />
          <h5 className={"font-medium"}>Trwa tworzenie spotkania...</h5>
        </div>
      )}
      <ManipulateMeetingContent
        callback={onSubmit}
        onOpenChange={onOpenChange}
        defaultValues={defaultValues}
        defaultSelectedGroups={defaultValues?.groups}
        defaultSelectedUsers={defaultValues?.users}
      />
    </DialogContent>
  );
}

import { MeetingsI } from "@/types/meetings";

type StylingI = Record<number, { size: number; left: number }>;

export function eventOverlap(meetingsArray: MeetingsI[]): StylingI {
  const OVERLAP = [];
  const STYLING: StylingI = {};

  const meetings = meetingsArray.toSorted(
    (a, b) =>
      new Date(a?.startDate).getMilliseconds() -
      new Date(b?.startDate).getMilliseconds(),
  );

  for (const parent of meetings) {
    const START_OVERLAP = [];
    const END_OVERLAP = [];

    for (const child of meetings) {
      if (child !== parent) {
        if (
          parent.startDate > child.startDate &&
          parent.startDate < child.endDate
        )
          END_OVERLAP.push(child);
        else if (
          parent.startDate < child.startDate &&
          parent.endDate > child.startDate
        )
          START_OVERLAP.push(child);
      }
    }

    OVERLAP.push({
      ID: parent.id,
      START_OVERLAP: START_OVERLAP,
      END_OVERLAP: END_OVERLAP,
    });
  }

  for (const meeting of OVERLAP) {
    const { ID, START_OVERLAP, END_OVERLAP } = meeting;

    const UNIQ_OVERLAP = START_OVERLAP.length + END_OVERLAP.length;
    const SIZE = 100 / (UNIQ_OVERLAP + 1);
    const ROW_STYLES = [];
    let POS = 0;

    for (const child of END_OVERLAP) {
      if (STYLING[child.id]) ROW_STYLES.push(STYLING[child.id]);
    }

    ROW_STYLES.sort((a, b) => {
      return a.left - b.left;
    });

    let end = 0;

    for (const style of ROW_STYLES) {
      if (style.left - end - SIZE >= 0) {
        break;
      } else {
        POS += style.size;
        end = style.left + style.left;
      }
    }

    STYLING[ID] = {
      size: Math.floor(SIZE * 100) / 100,
      left: Math.floor(POS * 100) / 100,
    };
  }

  return STYLING;
}

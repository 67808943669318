import { useGetDirectoryPermissionsQuery } from "@/api/queries/directoryQueries";
import { useGetFilePermissionsQuery } from "@/api/queries/filesQueries";
import useFSEntryPermissions from "@/components/features/files/hooks/useFSEntryPermissions";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { Badge } from "@/components/ui/badge/Badge";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
} from "@/components/ui/drawer/Drawer";
import { Label } from "@/components/ui/label/Label";
import { Separator } from "@/components/ui/separator/Separator";
import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet/Sheet";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { DirectoryDirI, DirectoryFileI, PermissionsI } from "@/types/files";
import { getIsFSEntityFile } from "@/utils/files";
import { getAvatar } from "@/utils/getAvatar";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { User, Users } from "lucide-react";
import { ReactNode, useMemo } from "react";

type StorageElementMoreInfoProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  element?: DirectoryDirI | DirectoryFileI;
};

export default function InfoFSEntry({
  element,
  open,
  onOpenChange,
}: StorageElementMoreInfoProps) {
  const breakpoint = useMediaQueryHook("sm");

  const isFile = getIsFSEntityFile(element);

  const { currentPermission } = useFSEntryPermissions({
    permissions: element?.permissions,
  });

  const {
    data: fileData,
    isSuccess: FileIsSuccess,
    isLoading: FileIsLoading,
  } = useGetFilePermissionsQuery(element?.id, !!element && isFile);

  const {
    data: DirData,
    isSuccess: DirIsSuccess,
    isLoading: DirIsLoading,
  } = useGetDirectoryPermissionsQuery(element?.id, !!element && !isFile);

  const { data, isSuccess, isLoading } = useMemo(() => {
    if (isFile) {
      return {
        data: fileData,
        isSuccess: FileIsSuccess,
        isLoading: FileIsLoading,
      };
    } else {
      return {
        data: DirData,
        isSuccess: DirIsSuccess,
        isLoading: DirIsLoading,
      };
    }
  }, [
    isFile,
    fileData,
    FileIsSuccess,
    DirData,
    DirIsSuccess,
    DirIsLoading,
    FileIsLoading,
  ]);

  const content = useMemo(() => {
    return (
      <>
        <div className={"flex flex-col gap-2"}>
          <Label className={"text-md font-medium"}>Informacje:</Label>
          <div className={"flex flex-col gap-1"}>
            {element?.name && (
              <div className={"flex min-h-9 items-center"}>
                <span className={"min-w-[14ch] text-fg-muted"}>Nazwa:</span>
                <p className={"truncate text-fg-primary"}>{element.name}</p>
              </div>
            )}
            {element?.createdAt && (
              <div className={"flex min-h-9 items-center"}>
                <span className={"min-w-[14ch] text-fg-muted"}>Utworzono:</span>
                <p className={"flex items-center text-fg-primary"}>
                  {format(new Date(element.createdAt), "dd MMM yyyy", {
                    locale: pl,
                  })}
                </p>
              </div>
            )}
            {element?.updatedAt && (
              <div className={"flex min-h-9 items-center"}>
                <span className={"min-w-[14ch] text-fg-muted"}>Edytowano:</span>
                <p className={"flex items-center text-fg-primary"}>
                  {format(new Date(element.updatedAt), "dd MMM yyyy", {
                    locale: pl,
                  })}
                </p>
              </div>
            )}
            {element?.permissions && (
              <div className={"flex min-h-9 items-center"}>
                <span className={"min-w-[14ch] text-fg-muted"}>
                  Poświadczenia:
                </span>
                <div className={"flex flex-wrap gap-1"}>
                  <Badge size={"sm"} variant={"accent"}>
                    {currentPermission.title}
                  </Badge>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={"flex flex-col gap-2"}>
          <Label className={"text-md font-medium"}>Uprawnienia:</Label>
          <div className={"flex w-full flex-col"}>
            {!data?.users.length && !data?.groups.length && isSuccess && (
              <p className="text-sm italic text-fg-muted">
                Brak dodanych poświadczeń
              </p>
            )}
            {isSuccess &&
              data &&
              data.groups.map((group) => (
                <PermissionListElement
                  key={group.id}
                  permissions={group.permissions}
                  data={{
                    title: group.name,
                    avatarURL: "",
                    fallbackIcon: <Users className="h-4 w-4" />,
                  }}
                  type={isFile ? "file" : "directory"}
                />
              ))}
            <Separator className="my-4" />
            {isSuccess &&
              data &&
              data.users.map((user) => (
                <PermissionListElement
                  key={user.id}
                  permissions={user.permissions}
                  data={{
                    title: user.name + " " + user.surname,
                    avatarURL: user.avatarURL,
                    fallbackIcon: <User className="h-4 w-4" />,
                  }}
                  type={isFile ? "file" : "directory"}
                />
              ))}

            {isLoading && (
              <>
                <div className="flex items-center gap-2 py-1">
                  <Skeleton className="h-10 w-10 rounded-sm" />
                  <Skeleton className="h-3 w-14 rounded-sm" />
                  <Skeleton className="ml-auto h-3 w-11 rounded-sm" />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }, [element, data, isSuccess]);

  if (breakpoint) {
    return (
      <Drawer open={open} onOpenChange={onOpenChange}>
        <DrawerContent>
          <DrawerBody className="h-fit overflow-auto">{content}</DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }
  return (
    <Sheet onOpenChange={onOpenChange} open={open}>
      <SheetContent className={"max-w-[45ch]"}>
        <SheetHeader>
          <SheetTitle>{element?.name}</SheetTitle>
        </SheetHeader>
        <SheetBody className="flex h-auto flex-col gap-6 pb-4">
          {content}
        </SheetBody>
      </SheetContent>
    </Sheet>
  );
}

type PermissionListElementProps = {
  fallbackIcon?: ReactNode;
  data: { title: string; avatarURL: string; fallbackIcon?: ReactNode };
  permissions: PermissionsI;
  type: "file" | "directory";
};

function PermissionListElement({
  data,
  permissions,
}: PermissionListElementProps) {
  const { title, avatarURL, fallbackIcon } = data;
  const { currentPermission } = useFSEntryPermissions({ permissions });

  return (
    <div className="flex items-center gap-2 py-1">
      <Avatar size={"sm"}>
        <AvatarImage src={getAvatar(avatarURL)} />
        <AvatarFallback>
          {fallbackIcon ? fallbackIcon : <User className={"h-4 w-4"} />}
        </AvatarFallback>
      </Avatar>
      <p className="shrink truncate">{title}</p>
      <Badge variant={"outline"} size={"sm"} className="ml-auto">
        {currentPermission.title}
      </Badge>
    </div>
  );
}

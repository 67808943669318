import { httpErrorHandler } from "@/api/api";
import {
  useDeleteUserAvatarMutation,
  usePostUserAvatarMutation,
} from "@/api/queries/usersQueries";
import { Alert, AlertDescription } from "@/components/ui/alert/Alert";
import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import { useGetURLParams } from "@/hooks/useGetURLParams";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { useCredentials, useSetCredentials } from "@/store/authStore";
import { getAvatar } from "@/utils/getAvatar";
import { motion } from "framer-motion";
import {
  AlertCircle,
  ImagePlus,
  RefreshCcw,
  RotateCcw,
  RotateCw,
  Trash,
} from "lucide-react";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FixedCropper,
  FixedCropperRef,
  ImageRestriction,
  RectangleStencil,
} from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import "react-advanced-cropper/dist/themes/classic.css";

interface Image {
  type?: string;
  src: string;
}

interface ImageCropperPopupProps {
  defaultSrc?: string | undefined;
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
}

export default function ImageCropper({
  defaultSrc: src,
  open,
  onOpenChange,
}: ImageCropperPopupProps) {
  const isMobile = useMediaQueryHook("sm");
  const inputRef = useRef<HTMLInputElement>(null);
  const cropperRef = useRef<FixedCropperRef>(null);

  const [image, setImage] = useState<Image | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const authToken = useGetURLParams().get("t");

  const setCredentials = useSetCredentials();
  const credentials = useCredentials();

  const { avatarURL } = credentials;

  const {
    mutateAsync: deleteUserAvatar,
    isPending: isPendingDeleteUserAvatar,
  } = useDeleteUserAvatarMutation();

  const { mutateAsync: postUserAvatar } = usePostUserAvatarMutation();

  const defaultSrc = useMemo(() => {
    return src ? src : getAvatar(avatarURL);
  }, [avatarURL, src]);

  useEffect(() => {
    if (defaultSrc) {
      setImage({ src: defaultSrc });
    }
  }, [defaultSrc]);

  const onClose = () => {
    setImage(null);
    onOpenChange(false);
  };

  const clickFileInput = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const rotate = (angle: number) => {
    if (cropperRef.current) {
      cropperRef.current.rotateImage(angle);
    }
  };

  const reset = () => {
    if (cropperRef.current) {
      cropperRef.current.reset();
    }
  };

  const onLoadImage = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files[0]) {
      const blob = URL.createObjectURL(files[0]);

      setImage({
        src: blob,
        type: files[0].type,
      });
    }
    event.target.value = "";
  };

  useEffect(() => {
    return () => {
      if (image && image.src) {
        URL.revokeObjectURL(image.src);
      }
    };
  }, [image]);

  const remove = () => {
    deleteUserAvatar({ authToken: authToken ? authToken : undefined })
      .then(() => {
        setCredentials({ ...credentials, avatarURL: undefined });
      })
      .catch((error) => {
        const { detail } = httpErrorHandler(error);
        setErrorMessage(detail);
      });
  };

  const onSubmit = () => {
    setIsLoading(true);
    if (!cropperRef.current) return;
    const canvas = cropperRef.current.getCanvas({
      minHeight: 1,
      minWidth: 1,
      maxHeight: 256,
      maxWidth: 256,
    });
    if (canvas) {
      canvas.toBlob(async (blob) => {
        if (blob) {
          await postUserAvatar({
            b64: blob,
            authToken: authToken ? authToken : undefined,
          })
            .then(({ avatarURL }) => {
              setCredentials({ ...credentials, avatarURL: avatarURL });
              setIsLoading(false);
              onClose();
            })
            .catch((error) => {
              let errorMessage =
                "Nie udało się przetworzyć obrazu. Zdjęcie jest zbyt duże.";
              if (error.response.status === 403) {
                errorMessage = "Token logowania wygasł, Zaloguj się ponownie.";
              }
              setErrorMessage(errorMessage);
            });
          setIsLoading(false);
        }
      }, "image/png");
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edytuj awatar</DialogTitle>
        </DialogHeader>

        <DialogBody
          className={"flex flex-row justify-between gap-6 sm:flex-col sm:gap-4"}
        >
          <input
            className={"hidden"}
            ref={inputRef}
            type={"file"}
            accept={"image/*"}
            onChange={onLoadImage}
          />
          <div
            className={
              "relative flex h-full max-h-[400px] min-h-[256px] w-full items-center justify-center overflow-hidden rounded-lg"
            }
          >
            <FixedCropper
              ref={cropperRef}
              className={"min-h-full min-w-full bg-bg-element"}
              src={image && image.src}
              stencilProps={{
                grid: true,
                aspectRatio: 1,
              }}
              stencilComponent={RectangleStencil}
              stencilSize={{ width: 256, height: 256 }}
              imageRestriction={ImageRestriction.stencil}
            />
          </div>
          <div
            className={
              "flex shrink-0 flex-col gap-1 sm:flex-row sm:justify-center"
            }
          >
            <Button
              className={"justify-start sm:justify-center"}
              iconPosition={isMobile ? "only" : "left"}
              icon={<RotateCcw />}
              variant={"ghost"}
              variantColor={"muted"}
              onClick={() => rotate(-45)}
            >
              Obróć +45°
            </Button>
            <Button
              className={"shrink-0 justify-start sm:justify-center"}
              iconPosition={isMobile ? "only" : "left"}
              icon={<RotateCw />}
              variant={"ghost"}
              variantColor={"muted"}
              onClick={() => rotate(45)}
            >
              Obróć +45°
            </Button>
            <Button
              className={"shrink-0 justify-start sm:justify-center"}
              iconPosition={isMobile ? "only" : "left"}
              icon={<RefreshCcw />}
              variant={"ghost"}
              variantColor={"muted"}
              onClick={() => reset()}
            >
              Reset
            </Button>
            <Button
              className={"shrink-0 justify-start sm:justify-center"}
              iconPosition={isMobile ? "only" : "left"}
              icon={<ImagePlus />}
              variant={"ghost"}
              variantColor={"brand"}
              onClick={clickFileInput}
            >
              Dodaj
            </Button>
            <Button
              className={"shrink-0 justify-start sm:justify-center"}
              iconPosition={isMobile ? "only" : "left"}
              icon={<Trash />}
              variant={"ghost"}
              variantColor={"destructive"}
              isLoading={{ state: isPendingDeleteUserAvatar }}
              onClick={() => remove()}
            >
              Usuń
            </Button>
          </div>
        </DialogBody>
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: errorMessage ? 1 : 0,
            height: errorMessage ? "auto" : 0,
          }}
          transition={{ duration: 0.2 }}
          className={"overflow-hidden px-6 py-4 sm:px-4"}
        >
          <Alert variant={"destructive"}>
            <AlertCircle className={"h-3 w-3"} />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        </motion.div>
        <DialogFooter>
          <Button
            className={"sm:w-full"}
            type={"button"}
            variant={"flat"}
            variantColor={"muted"}
            onClick={onClose}
          >
            Zamknij
          </Button>
          <Button
            className={"sm:w-full"}
            type={"button"}
            variant={"flat"}
            variantColor={"brand"}
            iconPosition={"none"}
            isLoading={{ state: isLoading }}
            onClick={onSubmit}
          >
            Zapisz
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import { formatBytes } from "@/lib/utils";
import { FilesI } from "@/types/files";
import { UseMutationResult } from "@tanstack/react-query";
import { isCancel } from "axios";
import { Check, RefreshCw, X } from "lucide-react";
import { HTMLAttributes, useCallback, useMemo } from "react";

export type useFSEntryProps = {
  variant?: "file" | "directory";
  abort?: () => void;
  retry?: () => void;
  status?: UseMutationResult["status"];
  error?: Error;
  file?: FilesI | File;
  progress?: number;
} & HTMLAttributes<HTMLDivElement>;

export const useFSEntry = (props: useFSEntryProps) => {
  const { variant, retry, abort, status, file, progress, error } = props;
  const isFileVariant = useMemo(() => variant === "file", [variant]);
  const isFileInstance = useMemo(() => file instanceof File, [file]);

  const retryCb = useCallback(() => {
    retry?.();
  }, [retry]);

  const abortCb = useCallback(() => {
    abort?.();
  }, [abort]);

  const statusProps = useMemo(() => {
    const styles = "text-xs ";
    switch (status) {
      case "idle": {
        if (isFileVariant) {
          return {
            children: "Odrzucono plik",
            className: styles + "text-fg-muted",
            icon: <RefreshCw />,
            callback: retryCb,
          };
        } else {
          return {
            children: "Odrzucono folder",
            className: styles + "text-fg-muted",
          };
        }
      }
      case "pending": {
        if (isFileVariant) {
          return {
            children: isFileInstance
              ? `${formatBytes((file as File).size)} | ${progress}%`
              : progress
                ? `${progress}%`
                : null,
            className: styles + "text-fg-muted",
            icon: <X />,
            callback: abortCb,
          };
        } else {
          return {
            children: progress ? `${progress}%` : null,
            className: styles + "text-fg-muted",
            icon: <X />,
            callback: abortCb,
          };
        }
      }
      case "success": {
        if (isFileVariant) {
          return {
            children: "Plik został przesłany",

            className: styles + "text-fg-muted",
            icon: <Check className={"h-4 w-4 stroke-fg-success"} />,
          };
        } else {
          return {
            children: "Folder został przesłany",
            className: styles + "text-fg-muted",
            icon: <Check className={"h-4 w-4 stroke-fg-success"} />,
          };
        }
      }
      case "error": {
        if (isFileVariant) {
          if (isCancel(error)) {
            return {
              children: "Odrzucono plik",
              className: styles + "text-fg-muted",
              icon: <RefreshCw />,
              callback: retryCb,
            };
          } else {
            return {
              children: "Wystąpił błąd",
              className: styles + "text-fg-destructive",
            };
          }
        } else {
          if (isCancel(error)) {
            return {
              children: "Odrzucono Folder",
              className: styles + "text-fg-muted",
            };
          } else {
            return {
              children: "Wystąpił błąd",
              className: styles + "text-fg-destructive",
            };
          }
        }
      }
      default:
        return {
          className: styles + "text-fg-muted",
        };
    }
  }, [file, isFileInstance, progress, status, error]);

  return {
    isFileInstance,
    statusProps,
  };
};

import { httpErrorHandler } from "@/api/api";
import { useGetAssignedMeetingsQuery } from "@/api/queries/meetingsQuery";
import { useMapMeetingsByDate } from "@/components/features/calendar/layout/content/map-meetings-by-date";
import Meeting from "@/components/features/meeting/Meeting";
import MeetingCalendarCard from "@/components/features/meeting/MeetingCalendarCard";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover/Popover";
import { ToastAction } from "@/components/ui/toast/Toast";
import { useToast } from "@/components/ui/toast/useToast";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { VIEW_KEY } from "@/page/pages/calendar/CalendarPage";
import { setNavigateT } from "@/types/calendar";
import { MeetingsI } from "@/types/meetings";
import { getMonth } from "@/utils/dateFormat";
import { PopoverClose } from "@radix-ui/react-popover";
import {
  endOfDay,
  endOfMonth,
  format,
  getDate,
  isToday,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { pl } from "date-fns/locale";
import { X } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface CalendarContentWeekProps {
  date: Date;
  setNavigate: setNavigateT;
}

export default function CalendarContentMonth({
  date,
  setNavigate,
}: CalendarContentWeekProps) {
  const month = getMonth(date, { fixedWeeks: true });
  const { toast } = useToast();

  const queryParams = useMemo(
    () => ({
      after: startOfDay(startOfMonth(date)).toISOString(),
      before: endOfDay(endOfMonth(date)).toISOString(),
    }),
    [date],
  );

  const {
    data = [],
    isError,
    error,
    refetch,
  } = useGetAssignedMeetingsQuery(queryParams);
  const meetings = useMapMeetingsByDate(month, data);

  useEffect(() => {
    if (isError) {
      const { title, detail } = httpErrorHandler(error);
      toast({
        variant: "destructive",
        title: title,
        description: detail,
        action: (
          <ToastAction altText="Try again" onClick={() => refetch()}>
            Ponów
          </ToastAction>
        ),
      });
    }
  }, [error, isError, refetch, toast]);

  const handleOnClick = useCallback(
    (monthDayDate: Date): void => {
      setNavigate({ newDate: monthDayDate, newViewKey: VIEW_KEY.DAY });
    },
    [setNavigate],
  );
  const isSameMonth = useCallback(
    (monthDayDate: Date) => monthDayDate.getMonth() === date.getMonth(),
    [date],
  );
  return (
    <div
      className={
        "relative grid h-full w-full grid-cols-7 grid-rows-6 border-t-1 p-0 [&>*:nth-child(7n)]:!border-r-0 [&>*:nth-child(n)]:border-b-1 [&>*:nth-child(n)]:border-r-1"
      }
    >
      {month.map((monthDayDate) => (
        <CalendarContentMonthBox
          date={monthDayDate}
          params={queryParams}
          meetings={meetings[monthDayDate.toISOString()]}
          onClick={() => handleOnClick(monthDayDate)}
          isSameMonth={() => isSameMonth(monthDayDate)}
          key={monthDayDate.toISOString()}
        />
      ))}
    </div>
  );
}

type CalendarContentMonthBoxProps = {
  date: Date;
  onClick: () => void;
  isSameMonth: () => boolean;
  meetings: MeetingsI[] | [];
  params: { after: string; before: string };
};

function CalendarContentMonthBox(props: CalendarContentMonthBoxProps) {
  const { date, onClick, isSameMonth, params, meetings } = props;
  const breakPoint = useMediaQueryHook("sm");

  const handleOnClickMobile = useCallback(() => {
    if (!breakPoint) return;
    onClick();
  }, [breakPoint, onClick]);

  return (
    <div className="relative overflow-hidden">
      <div
        className={cn(
          "relative flex h-full flex-col justify-between border-border p-px",
          !isSameMonth() && "bg-bg-muted-subtle/40 text-fg-muted",
        )}
        onClick={handleOnClickMobile}
      >
        <CalendarContentMonthBoxContent
          date={date}
          queryParams={params}
          meetings={meetings}
          onClick={onClick}
        />
      </div>
    </div>
  );
}

interface CalendarContentMonthBoxContentProps {
  date: Date;
  meetings: MeetingsI[] | [];
  queryParams: { after: string; before: string };
  onClick: () => void;
}

function CalendarContentMonthBoxContent(
  props: CalendarContentMonthBoxContentProps,
) {
  const { queryParams, meetings, date, onClick } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [moreNumber, setMoreNumber] = useState<number>(0);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const observer = new IntersectionObserver(
      (entries) => {
        let count = 0;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            count++;
          }
        });
        setMoreNumber(meetings.length - count);
      },
      { threshold: 0.95, root: container },
    );

    container.childNodes.forEach((child) => {
      if (child instanceof HTMLElement) {
        observer.observe(child);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [containerRef, meetings.length]);

  return (
    <Popover>
      <div
        ref={containerRef}
        className="relative flex grow flex-col overflow-hidden"
      >
        {meetings.map((meeting) => (
          <Meeting key={meeting.id} meeting={meeting} queryParams={queryParams}>
            <MeetingCalendarCard meeting={meeting} />
          </Meeting>
        ))}
      </div>
      <CalendarContentMonthBoxFooter
        date={date}
        onClick={onClick}
        moreNumber={moreNumber}
      />
      <CalendarContentDataMonthPopover
        date={date}
        meetings={meetings}
        queryParams={queryParams}
      />
    </Popover>
  );
}

type CalendarContentMonthBoxFooterProps = {
  date: Date;
  onClick: () => void;
  moreNumber: number;
};

function CalendarContentMonthBoxFooter(
  props: CalendarContentMonthBoxFooterProps,
) {
  const { date, onClick, moreNumber } = props;
  const breakpointSm = useMediaQueryHook("sm");
  const breakpointMd = useMediaQueryHook("md");

  return (
    <div className={"relative flex justify-between p-1 sm:p-0.5"}>
      <PopoverTrigger asChild>
        <Badge
          variant={"outline"}
          className={cn(
            "px-1 text-fg-muted",
            moreNumber > 0
              ? "cursor-pointer opacity-100"
              : "w-0 min-w-0 cursor-default opacity-0",
          )}
        >
          {!breakpointSm ? "+" : ""}
          {moreNumber} {!breakpointMd ? "więcej" : ""}
        </Badge>
      </PopoverTrigger>
      <button
        className={cn(
          "m-px text-xs",
          isToday(date) && "text-fg-accent",
          "flex h-5 w-5 shrink-0 cursor-pointer items-center justify-center rounded-xs p-0.5 hover:bg-bg-muted-subtle hover:opacity-80",
        )}
        onClick={onClick}
      >
        {getDate(date)}
      </button>
    </div>
  );
}

function CalendarContentDataMonthPopover({
  meetings,
  date,
  queryParams,
}: {
  meetings: MeetingsI[] | [];
  date: Date;
  queryParams: { after: string; before: string };
}) {
  const meetingDataRef = useRef<HTMLDivElement>(null);

  return (
    <PopoverContent
      className="z-10 flex max-h-[20rem] flex-col overflow-hidden p-0"
      collisionPadding={8}
      align={"start"}
    >
      <div className={"flex h-full items-center justify-between p-2"}>
        <p className={"ml-2 font-medium text-fg-secondary"}>
          {format(date, "do", { locale: pl })}{" "}
          {format(date, "EEE", { locale: pl })}
        </p>
        <PopoverClose asChild>
          <Button
            className={""}
            size={"sm"}
            variant={"ghost"}
            variantColor={"muted"}
            icon={<X />}
            iconPosition={"only"}
          />
        </PopoverClose>
      </div>
      <div className={"flex flex-col gap-1 overflow-y-auto p-2"}>
        {meetings.map((meeting) => (
          <Meeting
            ref={meetingDataRef}
            key={meeting.id}
            meeting={meeting}
            queryParams={queryParams}
          >
            <MeetingCalendarCard meeting={meeting} />
          </Meeting>
        ))}
      </div>
    </PopoverContent>
  );
}

import { httpErrorHandler } from "@/api/api";
import { useCreateDirectoryMutation } from "@/api/queries/directoryQueries";
import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form/Form";
import { Input } from "@/components/ui/input/Input";
import {
  CreateDirectorySchema,
  CreateDirectorySchemaType,
} from "@/schemas/directory.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

type DirectoryAddFormProps = {
  parentDirId: string;
  onOpenChange: (open: boolean) => void;
  open: boolean;
};

export default function CreateFSDir({
  parentDirId,
  onOpenChange,
  open,
}: DirectoryAddFormProps) {
  const { mutateAsync, isPending } = useCreateDirectoryMutation();

  const handleClose = () => {
    onOpenChange(false);
  };

  const onSubmit = (data: CreateDirectorySchemaType) => {
    mutateAsync(data)
      .then(() => {
        onOpenChange(false);
      })
      .catch((error) => {
        const { title } = httpErrorHandler(error);

        form.setError("name", { type: "custom", message: title });
      });
  };

  const form = useForm<CreateDirectorySchemaType>({
    mode: "onBlur",
    defaultValues: {
      name: "Nowy Folder",
      parentDirId: parentDirId,
    },
    resolver: yupResolver(CreateDirectorySchema),
  });

  useEffect(() => {
    if (open) {
      form.reset({
        name: "Nowy Folder",
        parentDirId: parentDirId,
      });
    }
  }, [open, parentDirId]);

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <DialogContent className={"max-w-[45ch]"}>
        <DialogHeader>
          <DialogTitle>Dodaj folder</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <DialogBody className={"py-1"}>
            <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
              <FormField
                name={"name"}
                control={form.control}
                render={({ field }) => (
                  <FormItem className={"col-span-1"}>
                    <FormControl>
                      <Input {...field} placeholder={"Nazwa folderu"} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </DialogBody>
        </Form>
        <DialogFooter>
          <Button
            onClick={handleClose}
            type={"button"}
            variant={"flat"}
            variantColor={"muted"}
            className={"sm:w-full"}
          >
            Anuluj
          </Button>
          <Button
            isLoading={{ state: isPending }}
            onClick={form.handleSubmit(onSubmit)}
            type={"submit"}
            variant={"flat"}
            variantColor={"brand"}
            className={"sm:w-full"}
          >
            Utwórz
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

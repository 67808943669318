import FileCard from "@/components/features/files/FileCard";
import ActivatePost from "@/components/features/manage/manage-posts/ActivatePost";
import DeletePost from "@/components/features/manage/manage-posts/DeletePost";
import EditPost from "@/components/features/manage/manage-posts/EditPost";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion/Accordion";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar/Avatar";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel/Carousel";
import { AlertDialog } from "@/components/ui/dialog/AlertDialog";
import { Dialog } from "@/components/ui/dialog/Dialog";
import Image from "@/components/ui/image/Image";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import AudioPlayer from "@/components/ui/media-viewer/AudioPlayer";
import VideoPlayer from "@/components/ui/media-viewer/VideoPlayer";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import useGetAvatar from "@/hooks/useGetAvatar";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { FilesI } from "@/types/files";
import { PostsI } from "@/types/posts";
import { FileTypeE, getFileType } from "@/utils/getFileType";
import getFileUrl from "@/utils/getFileUrl";
import { AudioMimeType, VideoMimeType } from "@vidstack/react/types/vidstack";
import { formatDistance, parseISO } from "date-fns";
import pl from "date-fns/locale/pl";
import {
  ChevronLeft,
  ChevronRight,
  DownloadIcon,
  MoreVertical,
  User,
} from "lucide-react";
import { Fragment, Ref, forwardRef, useEffect, useMemo, useState } from "react";

export const PostSkeleton = forwardRef<HTMLDivElement>(
  (props = {}, ref: Ref<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        className={
          "flex w-full flex-col gap-4 rounded-lg border-1 border-border bg-bg-container p-4"
        }
        {...props}
      >
        <div className={"flex gap-4"}>
          <Skeleton className={"h-10 min-h-10 w-10 min-w-10 rounded-sm"} />
          <div className={"flex w-full flex-col justify-center gap-2"}>
            <Skeleton className={"h-3 w-14 rounded-sm"} />
            <Skeleton className={"h-3 w-10 rounded-sm"} />
          </div>
        </div>
        <div className={"flex flex-col gap-2"}>
          <Skeleton className={"h-3 w-[100%] rounded-sm"} />
          <Skeleton className={"h-3 w-[100%] rounded-sm"} />
          <Skeleton className={"h-3 w-[80%] rounded-sm"} />
          <Skeleton className={"h-3 w-[20%] rounded-sm"} />
        </div>
      </div>
    );
  },
);
PostSkeleton.displayName = "PostSkeleton";

type PostType = {
  className?: string;
  post: PostsI;
};

type PostMediaProps = {
  file: FilesI;
  serveType: FileTypeE;
  mimeType: string;
};

export function Post({ className, post }: PostType) {
  const { lvl } = useCredentials();

  const avatar = useGetAvatar(post.creator.avatarURL);

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openChangeActive, setOpenChangeActive] = useState<boolean>(false);

  const [postMedia, postFiles] = useMemo(() => {
    const media: PostMediaProps[] = [];
    const files: FilesI[] = [];
    if (post.files && post.files.length > 0) {
      post.files.forEach((file) => {
        const { serveType, mimeType } = getFileType(file, {
          pick: [FileTypeE.IMAGE, FileTypeE.AUDIO, FileTypeE.VIDEO],
        });
        if (serveType === FileTypeE.FILE) {
          files.push(file);
        } else {
          media.push({
            file: file,
            serveType: serveType,
            mimeType: mimeType,
          });
        }
      });
    }
    return [media, files];
  }, [post]);

  return (
    <Fragment>
      {lvl > 1 ? (
        <Fragment>
          <Dialog open={openEdit} onOpenChange={setOpenEdit}>
            <EditPost
              open={openEdit}
              post={post}
              onClose={() => {
                setOpenEdit(false);
              }}
            />
          </Dialog>
          <AlertDialog
            open={openChangeActive}
            onOpenChange={setOpenChangeActive}
          >
            <ActivatePost post={post} onOpenChange={setOpenChangeActive} />
          </AlertDialog>

          <AlertDialog open={openDelete} onOpenChange={setOpenDelete}>
            <DeletePost
              selectedRows={post}
              open={openDelete}
              onOpenChange={setOpenDelete}
            />
          </AlertDialog>
        </Fragment>
      ) : null}

      <div
        className={cn(
          "flex w-full flex-col gap-4 rounded-lg border-1 border-border bg-bg-container p-4",
          !post.active && "opacity-80",
          className,
        )}
      >
        <header className={"flex items-center gap-2"}>
          <Avatar size={"sm"}>
            <AvatarImage src={avatar} alt="avatar" />
            <AvatarFallback>
              <User className={"h-4 w-4"} />
            </AvatarFallback>
          </Avatar>
          <div className={"flex w-full grow flex-col"}>
            <h3 className={"text-md font-semibold text-fg-primary"}>
              {post.creator.name} {post.creator.surname}
            </h3>
            <p className={"text-sm text-fg-muted"}>
              {formatDistance(parseISO(post.createdAt), new Date(), {
                addSuffix: true,
                locale: pl,
              })}
            </p>
          </div>
          {!post.active ? (
            <Badge variant={"destructive"} className={"shrink-0"}>
              Nieaktywny
            </Badge>
          ) : null}
          {lvl > 1 ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  size={"sm"}
                  icon={<MoreVertical />}
                  iconPosition={"only"}
                  variant={"ghost"}
                  variantColor={"muted"}
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent align={"end"}>
                <DropdownMenuGroup>
                  <DropdownMenuItem onClick={() => setOpenEdit(true)}>
                    Edytuj
                  </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  <DropdownMenuItem
                    className={"text-fg-destructive"}
                    onClick={() => setOpenChangeActive(true)}
                  >
                    {post.active ? "Dezaktywuj" : "Aktywuj"}
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className={"text-fg-destructive"}
                    onClick={() => setOpenDelete(true)}
                  >
                    Usuń
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : null}
        </header>
        <p
          className={
            "hyphens-auto whitespace-pre-wrap text-pretty break-words text-sm text-fg-secondary"
          }
        >
          {post.content}
        </p>
        <PostCarousel media={postMedia} />
        {postFiles.length ? (
          <Accordion type={"single"} collapsible>
            <AccordionItem value={"item-1"} className={"border-b-0"}>
              <AccordionTrigger
                className={"w-full border-b-1 text-sm text-fg-muted"}
              >
                Załączniki
              </AccordionTrigger>
              <AccordionContent className={"mt-3 border-b-0 pb-0"}>
                <div className={"flex flex-col gap-2"}>
                  {postFiles.map((file) => (
                    <DropdownMenu key={file.id}>
                      <FileCard file={file} menuButton />
                      <DropdownMenuContent>
                        <DropdownMenuGroup>
                          <DropdownMenuItem
                            onClick={() => {
                              const url = getFileUrl(file.id);
                              const link = document.createElement("a");
                              link.href = url;
                              link.setAttribute("download", file.name);
                              document.body.appendChild(link);
                              link.click();
                              link.remove();
                            }}
                          >
                            <DownloadIcon className={"h-4 w-4"} />
                            Pobierz
                          </DropdownMenuItem>
                        </DropdownMenuGroup>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ) : null}
      </div>
    </Fragment>
  );
}

function PostCarousel({ media }: { media: PostMediaProps[] }) {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  const { isEmpty, isOne } = useMemo(() => {
    return {
      isEmpty: media.length === 0,
      isOne: media.length === 1,
    };
  }, [media.length]);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  if (isEmpty) return null;

  return (
    <Carousel setApi={setApi} className={"relative w-full"}>
      <span
        className={cn(
          "absolute right-2 top-2 z-40 flex items-center gap-1 rounded-md bg-bg-container/50 px-2 py-0.5 text-xs font-medium tracking-wide text-fg-primary media-buffering:text-fg-muted",
          isOne && "hidden",
        )}
      >
        {current}/{count}
      </span>
      <CarouselContent>
        {media.map((props) => (
          <CarouselItem key={props.file.id}>
            <PostMedia {...props} />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious
        icon={<ChevronLeft />}
        variant={"flat"}
        className={cn("h-8 min-h-8 w-8 min-w-8", isOne && "hidden")}
      />
      <CarouselNext
        icon={<ChevronRight />}
        variant={"flat"}
        className={cn("h-8 min-h-8 w-8 min-w-8", isOne && "hidden")}
      />
    </Carousel>
  );
}

function PostMedia({ file, mimeType, serveType }: PostMediaProps) {
  const { id, name, fileType: type, thumbnail } = file;
  const content = useMemo(() => {
    switch (serveType) {
      case FileTypeE.IMAGE:
        return (
          <Image
            src={getFileUrl(id)}
            alt={name}
            thumbnail={thumbnail}
            type={type}
            className={"h-full w-full rounded-sm object-contain"}
          />
        );
      case FileTypeE.VIDEO:
        return (
          <VideoPlayer
            src={{
              src: getFileUrl(id),
              type: mimeType as VideoMimeType,
            }}
            title={name}
            className={"h-full w-full rounded-sm object-contain"}
          />
        );
      case FileTypeE.AUDIO:
        return (
          <AudioPlayer
            src={{
              src: getFileUrl(id),
              type: mimeType as AudioMimeType,
            }}
            title={name}
            className={"flex h-full w-full rounded-sm"}
          />
        );
    }
  }, [serveType, id, name, thumbnail, type, mimeType]);

  return (
    <div
      className={
        "relative flex h-[40vh] justify-center gap-2 overflow-hidden rounded-md"
      }
    >
      {content}
    </div>
  );
}

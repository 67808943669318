import { memo } from "react";

const FilesPageError = memo(() => {
  return (
    <div className="flex justify-center py-4">
      <p className="text-sm italic text-fg-muted">
        Wystąpił błąd podczas ładowania folderu
      </p>
    </div>
  );
});

FilesPageError.displayName = "FilesPageError";

export default FilesPageError;

import { deleteFile } from "@/api/endpoints/files";
import {
  deletePost,
  editPost,
  editPostT,
  getAssignedPosts,
  getPost,
  getPosts,
  postPost,
} from "@/api/endpoints/posts";
import { queryClient } from "@/api/query-client";
import { PostAddSchemaType } from "@/schemas/post.schema";
import { PostsI } from "@/types/posts";
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

export const useGetPostsQuery = () => {
  return useQuery({
    queryKey: ["posts"],
    queryFn: () => getPosts(),
  });
};

export const useGetAssignedPostsInfiniteQuery = () => {
  return useInfiniteQuery({
    queryKey: ["postsInfinite"],
    queryFn: ({ pageParam }) => getAssignedPosts(pageParam),
    initialPageParam: { page: 0, pageSize: 20, sortOrder: "DESC" },
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length < lastPageParam.pageSize) {
        return undefined;
      }
      return { ...lastPageParam, page: lastPageParam.page + 1 };
    },
  });
};

export const useGetPostQuery = (id?: number) => {
  return useQuery({
    queryKey: ["posts", id],
    queryFn: () => getPost(id),
    enabled: !!id,
  });
};

export const usePostPostMutation = () => {
  return useMutation({
    mutationKey: ["postPost"],
    mutationFn: (data: PostAddSchemaType) => postPost(data),
    onSuccess: async (data) => {
      queryClient.setQueryData(["posts"], (curr: PostsI[]) => {
        if (curr) {
          return [...curr, data];
        }
        return [data];
      });
      await queryClient.invalidateQueries({
        queryKey: ["postsInfinite"],
        exact: true,
      });
    },
  });
};

export const useEditPostMutation = () => {
  return useMutation({
    mutationFn: (data: editPostT) => editPost(data),
    onSuccess: (data) => {
      queryClient.setQueryData(["posts", data.id], data);
      queryClient.setQueryData(["posts"], (curr: PostsI[] | undefined) => {
        if (!curr) return;
        return curr.map((p) => (p.id === data.id ? data : p));
      });
      queryClient.setQueryData(
        ["postsInfinite"],
        (curr: InfiniteData<PostsI[], unknown> | undefined) => {
          if (!curr) return;
          return {
            ...curr,
            pages: curr.pages.map((page) => {
              return page.map((p) => (p.id === data.id ? data : p));
            }),
          };
        },
      );
    },
  });
};

export const useDeletePostMutation = () => {
  return useMutation({
    mutationFn: (data: { id: number }) => deletePost(data),
    onSuccess: (data, { id }) => {
      queryClient.setQueryData(["posts"], (curr: PostsI[] | undefined) => {
        if (!curr) return;
        return curr.filter((post: PostsI) => post.id !== id);
      });

      queryClient.invalidateQueries({ queryKey: ["postsInfinite"] });
    },
  });
};

export const useDeletePostFileMutation = (id?: number) => {
  return useMutation({
    mutationKey: ["deletePostFile"],
    mutationFn: (id: string) => deleteFile(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["posts", id] });
    },
  });
};

import { Button } from "@/components/ui/button/Button";
import Image from "@/components/ui/image/Image";
import AudioPlayer from "@/components/ui/media-viewer/AudioPlayer";
import VideoPlayer from "@/components/ui/media-viewer/VideoPlayer";
import { cn, formatBytes } from "@/lib/utils";
import { FilesI } from "@/types/files";
import { FileTypeE, getServeFileType } from "@/utils/getFileType";
import getFileUrl from "@/utils/getFileUrl";
import { AudioMimeType, VideoMimeType } from "@vidstack/react/types/vidstack";
import { FileUp, X } from "lucide-react";
import { forwardRef, useMemo } from "react";

interface FileCardProps {
  className?: string;
  contentClassName?: string;
  file: File | FilesI;
  onRemove: () => void;
}

const FileInputCard = forwardRef<HTMLDivElement, FileCardProps>(
  ({ className, contentClassName, file, onRemove, ...props }, ref) => {
    const isFileInstance = file instanceof File;

    const { fileType, mimeType } = useMemo(() => {
      const type = isFileInstance ? file.type : file.fileType;
      return {
        fileType: getServeFileType(type, {
          pick: [FileTypeE.IMAGE, FileTypeE.VIDEO, FileTypeE.AUDIO],
        }),
        mimeType: type,
      };
    }, [file, isFileInstance]);

    const fileURL = useMemo(() => {
      if (isFileInstance) {
        return URL.createObjectURL(file);
      } else if (!isFileInstance && file.id) {
        return getFileUrl(file.id);
      }
      return "";
    }, [file, isFileInstance]);

    const content = useMemo(() => {
      switch (fileType) {
        case FileTypeE.IMAGE:
          return (
            <Image
              src={fileURL}
              alt={file?.name}
              thumbnail={!isFileInstance ? file?.thumbnail : undefined}
              type={mimeType}
              className={"object-fit h-full w-full"}
              layoutClassName={"w-full h-full justify-center"}
            />
          );
        case FileTypeE.VIDEO:
          return (
            <VideoPlayer
              src={{
                src: fileURL,
                type: mimeType as VideoMimeType,
              }}
              title={"Video"}
              className={"aspect-square z-10 h-full w-full object-fill"}
              variant={"minimal"}
              autoPlay={true}
              muted={true}
            />
          );
        case FileTypeE.AUDIO:
          return (
            <AudioPlayer
              src={{
                src: fileURL,
                type: mimeType as AudioMimeType,
              }}
              title={"Audio"}
              variant={"minimal"}
              className={"z-10 h-full w-full"}
            />
          );
        default:
          return (
            <div
              className={cn(
                "flex h-full w-full flex-col items-center justify-center gap-2",
                contentClassName,
              )}
            >
              <FileUp className={"h-5 w-5 stroke-fg-secondary"} />
              <div className={"flex max-w-full flex-col gap-1 p-1"}>
                <p className="truncate text-xs font-medium text-fg-secondary">
                  {file.name}
                </p>
                <span className="text-center text-xs font-medium text-fg-muted">
                  {isFileInstance && formatBytes(file.size)}
                </span>
              </div>
            </div>
          );
      }
    }, [fileType]);

    return (
      <div
        ref={ref}
        className={cn(
          "relative flex h-[200px] gap-2 overflow-hidden rounded-md border-1 border-border p-1",
          className,
        )}
        {...props}
      >
        {content}
        <Button
          size={"sm"}
          type={"button"}
          variant={"outline"}
          variantColor={"muted"}
          className={
            "absolute right-1 top-1 z-10 h-7 min-h-7 w-7 min-w-7 bg-bg-container"
          }
          icon={<X />}
          iconPosition={"only"}
          onClick={(e) => {
            onRemove();
            e.preventDefault();
          }}
        />
      </div>
    );
  },
);

FileInputCard.displayName = "FileInputCard";
export default FileInputCard;

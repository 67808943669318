import TanstackTableSearch from "@/components/features/manage/tanstack-table/TanstackTableSearch";
import { Button } from "@/components/ui/button/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Label } from "@/components/ui/label/Label";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { Table } from "@tanstack/react-table";
import { ArrowDownIcon, ArrowUpDown, ArrowUpIcon } from "lucide-react";
import { Dispatch, SetStateAction } from "react";

type FsFiltersProps<T> = {
  className?: string;
  dataModel: Table<T>;
  setGlobalFilter: Dispatch<SetStateAction<string>>;
};

export default function FsFilters<ModelType>({
  className,
  dataModel,
  setGlobalFilter,
  ...props
}: FsFiltersProps<ModelType>) {
  const breakpoint = useMediaQueryHook("md");

  const sortingObject = dataModel.getState().sorting[0];

  if (breakpoint) {
    return (
      <div
        className={cn(
          "flex w-full flex-col gap-3 border-t-1 border-border p-1",
          className,
        )}
        {...props}
      >
        <div className="flex h-10 shrink-0 items-end justify-between">
          <Label className={"font-medium"}>Filtry:</Label>
        </div>
        <TanstackTableSearch
          setGlobalFilter={setGlobalFilter}
          className={"max-w-full"}
        />
        <div className={"flex flex-wrap gap-2"}>
          {dataModel
            .getAllColumns()
            .filter(
              (column) =>
                typeof column.accessorFn !== "undefined" && column.getCanSort(),
            )
            .map((column) => {
              return (
                <Button
                  key={column.id}
                  variant={"outline"}
                  variantColor={"muted"}
                  size={"sm"}
                  className={"w-fit"}
                  iconPosition={"left"}
                  icon={
                    column.getCanSort() && column.getIsSorted() === "desc" ? (
                      <ArrowDownIcon aria-hidden="true" />
                    ) : column.getIsSorted() === "asc" ? (
                      <ArrowUpIcon aria-hidden="true" />
                    ) : (
                      <ArrowUpDown aria-hidden="true" />
                    )
                  }
                  onClick={() => {
                    column.toggleSorting(column.getIsSorted() === "asc");
                  }}
                >
                  {column?.columnDef?.meta?.toString().toUpperCase() ?? ""}
                </Button>
              );
            })}
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn("flex items-center justify-end gap-2", className)}
      {...props}
    >
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            size={"sm"}
            variant={"ghost"}
            variantColor={"muted"}
            iconPosition={"right"}
            className="shrink-0"
            icon={sortingObject?.desc ? <ArrowDownIcon /> : <ArrowUpIcon />}
          >
            {sortingObject?.id ?? "Sortuj"}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align={"end"}>
          <DropdownMenuGroup>
            {dataModel
              .getAllColumns()
              .filter(
                (column) =>
                  typeof column.accessorFn !== "undefined" &&
                  column.getCanSort(),
              )
              .map((column) => {
                return (
                  <DropdownMenuItem
                    key={column.id}
                    onSelect={(e) => e.preventDefault()}
                    onClick={() => {
                      column.toggleSorting(column.getIsSorted() === "asc");
                    }}
                  >
                    {column.getCanSort() && column.getIsSorted() === "desc" ? (
                      <ArrowDownIcon className="h-4 w-4" aria-hidden="true" />
                    ) : column.getIsSorted() === "asc" ? (
                      <ArrowUpIcon className="h-4 w-4" aria-hidden="true" />
                    ) : (
                      <ArrowUpDown className="h-4 w-4" aria-hidden="true" />
                    )}
                    {column?.columnDef?.meta?.toString() ?? ""}
                  </DropdownMenuItem>
                );
              })}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <TanstackTableSearch
        setGlobalFilter={setGlobalFilter}
        className="min-w-[10rem] max-w-[16rem] shrink"
      />
    </div>
  );
}

import { Input, InputActionButton } from "@/components/ui/input/Input";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import { cn } from "@/lib/utils";
import { Search, X } from "lucide-react";
import { useEffect, useState } from "react";

type TanstackTableSearchProps = {
  className?: string;
  placeholder?: string;
  setGlobalFilter: (value: string) => void;
};

export default function TanstackTableSearch({
  className,
  placeholder = "Wyszykaj...",
  setGlobalFilter,
}: TanstackTableSearchProps) {
  const [searchValue, setSearchValue] = useState<string>("");
  const searchDebouncedValue = useDebounceValue(searchValue, 400);

  useEffect(() => {
    setGlobalFilter(searchDebouncedValue);
  }, [searchDebouncedValue, setGlobalFilter]);

  return (
    <Input
      startContent={<Search className={"mx-2"} />}
      endContent={
        <InputActionButton
          disabled={!searchValue}
          onClick={() => setSearchValue("")}
          icon={<X />}
        />
      }
      className={cn("max-w-[20rem]", className)}
      placeholder={placeholder}
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
    />
  );
}

import { buttonVariants } from "@/components/ui/button/Button";
import { dialogContentProps } from "@/components/ui/dialog/useDialog";
import { cn } from "@/lib/utils";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { VariantProps } from "class-variance-authority";
import { X } from "lucide-react";
import * as React from "react";

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = ({ ...props }: DialogPrimitive.DialogPortalProps) => (
  <DialogPrimitive.Portal {...props} />
);
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-40 bg-overlay data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> &
    VariantProps<typeof dialogContentProps> & { overflowTransparent?: boolean }
>(({ className, overflowTransparent = false, size = "2xl", ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay
      className={cn(overflowTransparent && "bg-transparent")}
      onContextMenu={(e) => e.preventDefault()}
    />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "duration-400 fixed bottom-[50%] left-[50%] z-40 flex max-h-[calc(100dvh-var(--space-11))] translate-x-[-50%] translate-y-[50%] flex-col overflow-y-hidden rounded-xl border-1 border-border bg-bg-container p-0 shadow-lg outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-bottom-[48%] data-[state=closed]:slide-out-to-left-1/2 data-[state=open]:slide-in-from-bottom-[48%] data-[state=open]:slide-in-from-left-1/2 sm:bottom-[0%] sm:min-h-[calc(100dvh-12rem)] sm:max-w-full sm:translate-y-[0%] sm:rounded-b-none",
        dialogContentProps({ size }),
        className,
      )}
      {...props}
    ></DialogPrimitive.Content>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

type DialogHeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  Close?: React.ReactNode;
};

const DialogHeader = ({
  className,
  children,
  Close,
  ...props
}: DialogHeaderProps) => (
  <div className={cn("flex h-13 flex-row p-4 text-left", className)} {...props}>
    {children}
    {Close ? (
      Close
    ) : (
      <DialogPrimitive.Close
        className={cn(
          buttonVariants({
            variant: "ghost",
            variantColor: "muted",
            size: "sm",
            iconPosition: "only",
          }),
          "absolute right-1 top-1 rounded-lg",
        )}
      >
        <X className={"h-5 w-5"} />
      </DialogPrimitive.Close>
    )}
  </div>
);
DialogHeader.displayName = "DialogHeader";

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "mr-9 w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-semibold tracking-tight",
      className,
    )}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogBody = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ children, className, ...props }, ref) => (
  <div
    ref={ref}
    className={"relative h-0 flex-[1_1_100%] overflow-auto"}
    {...props}
  >
    <div className={cn("min-h-full px-4", className)}>{children}</div>
  </div>
));
DialogBody.displayName = DialogPrimitive.Description.displayName;

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex justify-end space-x-3 p-4", className)} {...props} />
);
DialogFooter.displayName = "DialogFooter";

export {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogBody,
};

import { Button } from "@/components/ui/button/Button";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/ui/drawer/Drawer";
import { InputProps, InputVariants } from "@/components/ui/input/Input";
import { useDatetimePicker } from "@/components/ui/input/datetime-picker/useDatetimePicker";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover/Popover";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import { dataAttr } from "@/utils/assertion";
import { useDOMRef } from "@/utils/dom";
import {
  CalendarDate,
  isToday as _isToday,
  createCalendar,
  fromDate,
  getLocalTimeZone,
  parseDateTime,
  parseTime,
  toCalendarDate,
  toCalendarDateTime,
} from "@internationalized/date";
import { useFocusable } from "@react-aria/focus";
import { usePress } from "@react-aria/interactions";
import { filterDOMProps } from "@react-aria/utils";
import { DateSegment as IDateSegment } from "@react-stately/datepicker";
import { format } from "date-fns";
import pl from "date-fns/locale/pl";
import {
  CalendarIcon,
  ChevronDown,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "lucide-react";
import React, {
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AriaButtonProps,
  AriaDatePickerProps,
  AriaTimeFieldProps,
  CalendarProps,
  DateValue,
  TimeValue,
  mergeProps,
  useButton,
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
  useDateField,
  useDatePicker,
  useDateSegment,
  useFocusRing,
  useHover,
  useLocale,
  useTimeField,
} from "react-aria";
import {
  CalendarState,
  DateFieldState,
  DatePickerState,
  DatePickerStateOptions,
  useCalendarState,
  useDateFieldState,
  useDatePickerState,
  useTimeFieldState,
} from "react-stately";

const EMPTY_ITEMS_OFFSET = 4;

function CalendarPicker({
  state: _state,
  isHeaderExpanded: _isHeaderExpanded,
  setIsHeaderExpanded: _setIsHeaderExpanded,
  headerRef,
  ...props
}: {
  state: CalendarState;
  isHeaderExpanded: boolean;
  setIsHeaderExpanded: (expanded: boolean) => void;
  date: CalendarDate;
  currentMonth: CalendarDate;
  headerRef: RefObject<any>;
}) {
  const {
    months,
    years,
    highlightRef,
    monthsListRef,
    yearsListRef,
    getItemRef,
    onPickerItemPressed,
    onPickerItemKeyDown,
  } = useDatetimePicker({
    state: _state,
    isHeaderExpanded: _isHeaderExpanded,
    setIsHeaderExpanded: _setIsHeaderExpanded,
    headerRef,
    ...props,
  });

  const EmptyItem = useCallback(
    () => (
      <div
        aria-hidden={"true"}
        className={"min-h-9 w-full"}
        data-slot={"picker-item-empty"}
        tabIndex={-1}
      >
        &nbsp;
      </div>
    ),
    [],
  );

  const PickerItemWrapper = useCallback(
    ({ children }: any) => (
      <>
        {Array.from({ length: EMPTY_ITEMS_OFFSET }, (_, i) => (
          <EmptyItem key={i} />
        ))}
        {children}
        {Array.from({ length: EMPTY_ITEMS_OFFSET }, (_, i) => (
          <EmptyItem key={i} />
        ))}
      </>
    ),
    [EmptyItem],
  );

  return (
    <div
      className={
        "!duration-250 pointer-events-auto absolute inset-x-0 z-40 flex h-full w-full justify-center bg-bg-container p-2 opacity-100 transition-opacity"
      }
      data-slot="picker-wrapper"
    >
      <div
        ref={highlightRef}
        className={
          "pointer-events-none absolute top-1/2 z-0 h-8 w-[calc(100%_-_16px)] -translate-y-1/2 rounded-md bg-bg-muted-subtle/50"
        }
        data-slot={"picker-highlight"}
      ></div>
      <div
        ref={monthsListRef}
        className={
          "!scrollbar-none flex !snap-y !snap-mandatory flex-col items-start !overflow-y-scroll px-4"
        }
        data-slot={"picker-month-list"}
      >
        <PickerItemWrapper>
          {months.map((month) => (
            <CalendarPickerItem
              key={`picker-month-${month.value}`}
              ref={(node) => getItemRef(node, month.value, "months")}
              data-value={month.value}
              onKeyDown={(e) => onPickerItemKeyDown(e, month.value, "months")}
              onPress={(e) => onPickerItemPressed(e, "months")}
            >
              {month.label}
            </CalendarPickerItem>
          ))}
        </PickerItemWrapper>
      </div>
      <div
        ref={yearsListRef}
        className={
          "!scrollbar-none flex !snap-y !snap-mandatory flex-col items-start !overflow-y-scroll px-4"
        }
        data-slot={"picker-year-list"}
      >
        <PickerItemWrapper>
          {years.map((year) => (
            <CalendarPickerItem
              key={`picker-year-${year.value}`}
              ref={(node) => getItemRef(node, year.value, "years")}
              data-value={year.value}
              onKeyDown={(e) => onPickerItemKeyDown(e, year.value, "years")}
              onPress={(e) => onPickerItemPressed(e, "years")}
            >
              {year.label}
            </CalendarPickerItem>
          ))}
        </PickerItemWrapper>
      </div>
    </div>
  );
}

const CalendarPickerItem = forwardRef<HTMLButtonElement, AriaButtonProps>(
  ({ children, autoFocus, isDisabled, onPress, ...otherProps }, ref) => {
    const domRef = useDOMRef(ref);

    const { pressProps, isPressed } = usePress({
      onPress,
      isDisabled,
      ref: domRef,
    });

    const { focusableProps } = useFocusable(otherProps, domRef);

    const buttonProps = mergeProps(
      focusableProps,
      pressProps,
      filterDOMProps(otherProps, { labelable: true }),
    );

    const { isFocusVisible, isFocused, focusProps } = useFocusRing({
      autoFocus,
    });

    const { isHovered, hoverProps } = useHover({ isDisabled });

    return (
      <button
        ref={domRef}
        data-disabled={dataAttr(isDisabled)}
        data-focus={dataAttr(isFocused)}
        data-focus-visible={dataAttr(isFocusVisible)}
        data-hover={dataAttr(isHovered)}
        data-pressed={dataAttr(isPressed)}
        data-slot={"picker-item"}
        type={"button"}
        {...mergeProps(focusProps, hoverProps, buttonProps, otherProps)}
        className={"min-h-9 w-full snap-center"}
      >
        {children}
      </button>
    );
  },
);

CalendarPickerItem.displayName = "CalendarPickerItem";

function Calendar({
  showMonthAndYearPickers,
  ...props
}: CalendarProps<DateValue> & { showMonthAndYearPickers?: boolean }) {
  const prevButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const nextButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const headerRef = React.useRef<HTMLDivElement | null>(null);
  const [isHeaderExpanded, setIsHeaderExpanded] = useState<boolean>(false);

  const { locale } = useLocale();
  const state = useCalendarState({
    ...props,
    minValue:
      props?.minValue || new CalendarDate(new Date().getFullYear() - 100, 1, 1),
    maxValue:
      props?.maxValue || new CalendarDate(new Date().getFullYear() + 100, 1, 1),
    locale,
    createCalendar,
  });

  const currentMonth = state.visibleRange.start;

  const {
    calendarProps,
    prevButtonProps: _prevButtonProps,
    nextButtonProps: _nextButtonProps,
    title,
  } = useCalendar(props, state);

  const { buttonProps: prevButtonProps } = useButton(
    _prevButtonProps,
    prevButtonRef,
  );
  const { buttonProps: nextButtonProps } = useButton(
    _nextButtonProps,
    nextButtonRef,
  );

  return (
    <div {...calendarProps}>
      <div
        ref={headerRef}
        className="relative flex h-10 items-center justify-center"
      >
        <Button
          {...prevButtonProps}
          ref={prevButtonRef}
          type={"button"}
          size={"sm"}
          variant={"outline"}
          variantColor={"muted"}
          iconPosition={"only"}
          icon={<ChevronLeftIcon />}
          className={cn("absolute left-0", isHeaderExpanded && "hidden")}
        />
        {showMonthAndYearPickers ? (
          <div className="text-sm font-medium text-fg-primary">{title}</div>
        ) : (
          <Button
            size={"sm"}
            variant={"ghost"}
            variantColor={"muted"}
            icon={<ChevronDown />}
            iconPosition={"right"}
            onClick={() => setIsHeaderExpanded((prev) => !prev)}
          >
            {title}
          </Button>
        )}

        <Button
          {...nextButtonProps}
          ref={nextButtonRef}
          size={"sm"}
          variant={"outline"}
          variantColor={"muted"}
          iconPosition={"only"}
          icon={<ChevronRightIcon />}
          className={cn(
            "absolute right-0 bg-transparent",
            isHeaderExpanded && "hidden",
          )}
        />
      </div>
      <div className={"relative"}>
        {isHeaderExpanded && (
          <CalendarPicker
            state={state}
            headerRef={headerRef}
            isHeaderExpanded={isHeaderExpanded}
            setIsHeaderExpanded={setIsHeaderExpanded}
            date={currentMonth}
            currentMonth={currentMonth}
            {...props}
          />
        )}
        <CalendarGrid state={state} />
      </div>
    </div>
  );
}

interface CalendarGridProps {
  state: CalendarState;
}

function CalendarGrid({ state, ...props }: CalendarGridProps) {
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);
  return (
    <table
      {...gridProps}
      className={cn(gridProps.className, "w-full border-collapse")}
    >
      <thead {...headerProps}>
        <tr className={"flex py-2"}>
          {weekDays.map((day, index) => (
            <th
              className={"w-10 rounded-md text-xs font-normal text-fg-muted"}
              key={index}
            >
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(6).keys()].map((weekIndex) => (
          <tr className={"flex w-full"} key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? (
                  <CalendarCell key={i} state={state} date={date} />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

interface CalendarCellProps {
  state: CalendarState;
  date: CalendarDate;
}

function CalendarCell({ state, date }: CalendarCellProps) {
  const ref = React.useRef<HTMLButtonElement | null>(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    formattedDate,
  } = useCalendarCell({ date }, state, ref);

  const isToday = useMemo(() => {
    const timezone = getLocalTimeZone();
    return _isToday(date, timezone);
  }, [date]);
  return (
    <td
      {...cellProps}
      className={cn(
        cellProps.className,
        "relative p-0 text-center text-sm focus-within:relative focus-within:z-10 [&:has([aria-selected])]:bg-bg-brand-subtle first:[&:has([aria-selected])]:rounded-l-sm last:[&:has([aria-selected])]:rounded-r-sm",
      )}
    >
      <Button
        {...buttonProps}
        type={"button"}
        variant={"ghost"}
        variantColor={"muted"}
        size={"sm"}
        ref={ref}
        className={cn(
          buttonProps.className,
          "h-8 w-8",
          isToday && "text-fg-accent",
          isOutsideVisibleRange && "text-fg-muted opacity-50",
          isDisabled && "text-fg-muted opacity-50",
          isSelected && "bg-bg-brand-subtle text-fg-brand",
        )}
      >
        {formattedDate}
      </Button>
    </td>
  );
}

interface DateSegmentProps {
  segment: IDateSegment;
  state: DateFieldState;
}

function DateSegment({ segment, state }: DateSegmentProps) {
  const ref = useRef(null);

  const { segmentProps } = useDateSegment(segment, state, ref);
  return (
    <div
      {...segmentProps}
      ref={ref}
      className={cn(
        "truncate text-md focus:bg-bg-brand-subtle focus:text-fg-brand focus:outline-none",
        segment.type !== "literal" && "px-0.5",
        segment.isPlaceholder && "text-fg-muted",
      )}
    >
      {segment.text}
    </div>
  );
}

function DateField(props: AriaDatePickerProps<DateValue>) {
  const ref = useRef<HTMLDivElement | null>(null);

  const { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });
  const { fieldProps } = useDateField(props, state, ref);
  return (
    <div
      ref={ref}
      {...fieldProps}
      className={cn(
        "border-input ring-offset-background inline-flex h-10 flex-1 items-center rounded-l-md bg-transparent text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
        props.isDisabled && "cursor-not-allowed opacity-50",
      )}
    >
      {state.segments.map((segment, i) => (
        <DateSegment key={i} segment={segment} state={state} />
      ))}
      {state.isInvalid && <span aria-hidden={"true"}>🚫</span>}
    </div>
  );
}

function TimeField(props: AriaTimeFieldProps<TimeValue>) {
  const ref = useRef<HTMLDivElement | null>(null);

  const { locale } = useLocale();
  const state = useTimeFieldState({
    ...props,
    locale,
  });

  const { fieldProps } = useTimeField(props, state, ref);
  return (
    <div
      ref={ref}
      {...fieldProps}
      className={cn(
        "transition-color inline-flex h-12 min-h-12 w-full items-center gap-0.5 rounded-md border-1 border-border bg-bg-container py-1 pl-4 pr-1 text-md text-fg-primary duration-100 hover:bg-bg-element/20 [&:disabled]:cursor-not-allowed [&:disabled]:opacity-50 [&:has(:focus-visible)]:outline [&:has(:focus-visible)]:outline-2 [&:has(:focus-visible)]:outline-offset-2 [&:has(:focus-visible)]:outline-ring [&>svg]:h-4 [&>svg]:w-4 [&>svg]:flex-shrink-0 [&>svg]:stroke-fg-muted",
        props.isDisabled && "cursor-not-allowed opacity-50",
      )}
    >
      {state.segments.map((segment, i) => (
        <DateSegment key={i} segment={segment} state={state} />
      ))}
    </div>
  );
}

type TimePickerProps = {
  jsTime?: string | null;
  jsTimeOnChange?: (time: string | null) => void;
  startContent?: React.ReactElement;
  endContent?: React.ReactElement;
} & AriaTimeFieldProps<TimeValue> &
  InputProps;

function TimePicker({
  jsTime = null,
  jsTimeOnChange,
  startContent,
  endContent,
  ...props
}: TimePickerProps) {
  const [timeValue, setTimeValue] = useState<TimeValue | null>(null);
  const [jsDateTime, setJsDateTime] = useState<string | null>(jsTime);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (jsDateTime) {
      const parsed = parseTime(jsDateTime);
      setTimeValue(parsed);
    }
  }, [jsDateTime, jsTimeOnChange]);

  const handleOnChange = (time: TimeValue) => {
    setTimeValue(time);

    const stringTime = time ? time.toString() : null;
    setJsDateTime(stringTime);
    jsTimeOnChange?.(stringTime);
  };

  const startJSXElement = useMemo(() => {
    return startContent || null;
  }, [startContent]);
  const endJSXElement = useMemo(() => {
    return endContent || null;
  }, [endContent]);

  const updatedProps = {
    value: timeValue,
    onChange: handleOnChange,
    ...props,
  };

  const { locale } = useLocale();
  const state = useTimeFieldState({
    ...updatedProps,
    locale,
  });

  const { fieldProps } = useTimeField(updatedProps, state, ref);

  return (
    <div
      ref={ref}
      className={cn(
        InputVariants({
          startPadding: !!startJSXElement,
          endPadding: !!endJSXElement,
        }),
      )}
      {...fieldProps}
    >
      {startJSXElement}
      <div className={"flex w-full flex-row"}>
        {state.segments.map((segment, i) => (
          <DateSegment key={i} segment={segment} state={state} />
        ))}
      </div>
      {endJSXElement}
    </div>
  );
}

export type DateTimePickerRef = {
  divRef: HTMLDivElement | null;
  buttonRef: HTMLButtonElement | null;
  contentRef: HTMLDivElement | null;
  state: DatePickerState;
};

const DatetimePicker = React.forwardRef<
  DateTimePickerRef,
  DatePickerStateOptions<DateValue> & {
    jsDate?: Date | null;
    onJsDateChange?: (date: Date | null) => void;
    showClearButton?: boolean;
    button?: boolean;
    dateFormat?: string;
    showMonthAndYearPickers?: boolean;
    max?: Date;
    min?: Date;
    className?: string;
    align?: "center" | "start" | "end";
  }
>(
  (
    {
      button = false,
      showMonthAndYearPickers = false,
      jsDate,
      onJsDateChange,
      dateFormat = "MMM yyyy",
      max,
      min,
      className,
      align = "end",
      ...props
    },
    ref,
  ) => {
    const breakpoint = useMediaQueryHook("sm");
    const divRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const state = useDatePickerState({
      minValue: min ? fromDate(min, getLocalTimeZone()) : undefined,
      maxValue: max ? fromDate(max, getLocalTimeZone()) : undefined,
      ...props,
    });
    useImperativeHandle(ref, () => ({
      divRef: divRef.current,
      buttonRef: buttonRef.current,
      contentRef: contentRef.current,
      state,
    }));

    const {
      groupProps,
      fieldProps,
      buttonProps: _buttonProps,
      dialogProps,
      calendarProps,
    } = useDatePicker(
      {
        minValue: min ? fromDate(min, getLocalTimeZone()) : undefined,
        maxValue: max ? fromDate(max, getLocalTimeZone()) : undefined,
        ...props,
      },
      state,
      divRef,
    );

    const { buttonProps } = useButton(_buttonProps, buttonRef);

    const onValueChange = (newValue: DateValue): void => {
      let date = null;
      if (newValue) {
        date = parseDateTime(newValue.toString()).toDate(getLocalTimeZone());
      }
      onJsDateChange?.(date);
      fieldProps.onChange?.(newValue);
      calendarProps.onChange?.(newValue);
    };

    const currentValue = useCallback((): DateValue | null | undefined => {
      if (!jsDate) {
        return state?.value;
      }

      const parsed = fromDate(jsDate, getLocalTimeZone());
      if (state.hasTime) {
        return toCalendarDateTime(parsed);
      }

      return toCalendarDate(parsed);
    }, [jsDate, state.hasTime, state.value]);

    const formatedDate = (date: DateValue | null | undefined) => {
      if (!date) return <p className={"text-muted"}> Wybierz datę </p>;
      return format(date.toDate(getLocalTimeZone()), dateFormat, {
        locale: pl,
      });
    };

    const calendarContent = (
      <div {...dialogProps} className={"space-y-3"}>
        <Calendar
          {...calendarProps}
          showMonthAndYearPickers={showMonthAndYearPickers}
          onChange={onValueChange}
        />
        {state.hasTime && (
          <TimeField value={state.timeValue} onChange={state.setTimeValue} />
        )}
      </div>
    );

    const trigger = button ? (
      <Button
        className={className}
        {...buttonProps}
        variant={"outline"}
        variantColor={"muted"}
        iconPosition={"right"}
        icon={<ChevronDown />}
        disabled={props.isDisabled}
        onClick={() => {
          state.setOpen(true);
        }}
      >
        {formatedDate(currentValue())}
      </Button>
    ) : (
      <Button
        className={cn("h-9 min-h-9 w-9 min-w-9", className)}
        {...buttonProps}
        variant={"ghost"}
        variantColor={"muted"}
        size={"sm"}
        iconPosition={"only"}
        icon={<CalendarIcon />}
        disabled={props.isDisabled}
        onClick={() => {
          state.setOpen(true);
        }}
      />
    );

    const content = breakpoint ? (
      <Drawer open={props.isOpen} onOpenChange={props.onOpenChange}>
        <DrawerTrigger asChild>{trigger}</DrawerTrigger>
        <DrawerContent ref={contentRef}>
          <div className={"flex w-full justify-center pb-4"}>
            {calendarContent}
          </div>
        </DrawerContent>
      </Drawer>
    ) : (
      <Popover open={props.isOpen} onOpenChange={props.onOpenChange}>
        <PopoverTrigger asChild>{trigger}</PopoverTrigger>
        <PopoverContent
          ref={contentRef}
          className={"w-full p-2"}
          disablePortal={true}
          align={align}
        >
          {calendarContent}
        </PopoverContent>
      </Popover>
    );

    if (button) return content;
    return (
      <div
        ref={divRef}
        className={cn(
          InputVariants({
            endPadding: true,
          }),
          groupProps.className,
        )}
        {...groupProps}
      >
        <DateField
          {...fieldProps}
          value={currentValue()}
          onChange={onValueChange}
        />
        {content}
      </div>
    );
  },
);

DatetimePicker.displayName = "DateTimePicker";

export { DatetimePicker, TimePicker };

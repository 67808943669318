import { DirIcon } from "@/components/features/files/FilesIcon";
import { useFSEntry } from "@/components/features/files/hooks/useFSEntry";
import { Button } from "@/components/ui/button/Button";
import { DropdownMenuTrigger } from "@/components/ui/input/dropdown-menu/DropdownMenu";
import { Progress } from "@/components/ui/progress/Progress";
import { Skeleton } from "@/components/ui/skeleton/Skeleton";
import { cn } from "@/lib/utils";
import { DirectoryDirI } from "@/types/files";
import { UseMutationResult } from "@tanstack/react-query";
import { MoreVertical } from "lucide-react";
import {
  HTMLAttributes,
  ReactElement,
  forwardRef,
  isValidElement,
  memo,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";

interface DirectoryBasicDataI {
  name: string;
}

type DirectoryCardProps = HTMLAttributes<HTMLDivElement> & {
  directory: DirectoryDirI | DirectoryBasicDataI;
  className?: string;
  menuButton?: ReactElement | boolean;
  variant?: "default" | "upload";
  progress?: number;
  status?: UseMutationResult["status"];
  error?: Error;
  abort?: () => void;
};

type DirectorySmallCardProps = DirectoryCardProps & {
  icon?: ReactElement;
};

const DirectoryCard = forwardRef<HTMLDivElement, DirectoryCardProps>(
  (props, ref) => {
    const navigate = useNavigate();

    const {
      directory,
      variant = "default",
      className,
      onDoubleClick,
      menuButton,
      progress,
      status,
      error,
      abort,
      ...rest
    } = props;

    const isDirectoryDirI = useCallback(
      (dir: DirectoryDirI | DirectoryBasicDataI): dir is DirectoryDirI => {
        return (dir as DirectoryDirI).id !== undefined;
      },
      [],
    );

    const { statusProps } = useFSEntry({
      variant: "directory",
      abort,
      status,
      progress,
      error,
    });

    const dirNavigate = useCallback(() => {
      if (isDirectoryDirI(directory)) {
        navigate(`/fs/dir/${directory.id}`);
      }
    }, [directory, navigate]);

    return (
      <div
        ref={ref}
        className={cn(
          "relative flex w-full flex-col gap-2 rounded-md border-1 border-border p-2 opacity-100 transition-all duration-100 ease-out hover:bg-bg-muted-subtle/45 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ring disabled:opacity-50",
          className,
        )}
        onDoubleClick={onDoubleClick || dirNavigate}
        {...rest}
      >
        <div className={"flex w-full flex-col gap-2"}>
          <div className={"flex h-10 w-full items-center gap-2"}>
            <DirIcon />
            <div className={"flex w-full flex-col justify-center gap-0.5"}>
              <p className={"select-none truncate"}>{directory.name}</p>
              {status ? (
                <p className={statusProps.className}>{statusProps.children}</p>
              ) : null}
            </div>
            <div className={"flex shrink-0"}>
              {status ? (
                <Button
                  onClick={statusProps?.callback}
                  variant={"ghost"}
                  variantColor={"muted"}
                  icon={statusProps.icon}
                  iconPosition={"only"}
                  size={"sm"}
                />
              ) : null}
              {menuButton && isValidElement(menuButton) ? menuButton : null}
              {menuButton === true && (
                <DropdownMenuTrigger asChild>
                  <Button
                    variant={"ghost"}
                    variantColor={"muted"}
                    icon={<MoreVertical />}
                    iconPosition={"only"}
                    size={"sm"}
                  />
                </DropdownMenuTrigger>
              )}
            </div>
          </div>
          {variant === "upload" && status === "pending" ? (
            <Progress value={progress} valueClassName={"hidden"} />
          ) : null}
        </div>
      </div>
    );
  },
);

DirectoryCard.displayName = "DirectoryCard";

const DirectorySmallCard = forwardRef<HTMLDivElement, DirectorySmallCardProps>(
  (props, ref) => {
    const { menuButton, className, icon, directory, ...rest } = props;

    return (
      <div
        ref={ref}
        className={cn(
          "relative cursor-pointer rounded-md p-2 opacity-100 transition-all duration-100 ease-out hover:bg-bg-muted-subtle/45 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ring disabled:opacity-50",
          className,
        )}
        {...rest}
      >
        <div className={"flex h-10 w-full items-center gap-2"}>
          <div className={"flex w-full items-center gap-2"}>
            {icon}
            <p className={"select-none truncate"}>{directory?.name}</p>
          </div>
          <div className={"shrink-0"}>{menuButton}</div>
        </div>
      </div>
    );
  },
);
DirectorySmallCard.displayName = "DirectorySmallCard";

const DirectorySmallCardSkeleton = memo(() => (
  <div className={"relative rounded-md p-2 opacity-100"}>
    <div className={"flex h-10 w-full items-center gap-2"}>
      <div className={"flex w-full items-center gap-2"}>
        <Skeleton className="h-4 w-4 rounded-xs" />
        <Skeleton className="h-3 w-14 rounded-sm" />
      </div>
      <Skeleton className="h-10 w-10 rounded-sm" />
    </div>
  </div>
));

DirectorySmallCardSkeleton.displayName = "DirectorySmallCardSkeleton";

const DirectoryCardSkeleton = memo(() => (
  <div
    className={
      "inline-flex w-full items-center gap-2 rounded-md border-1 border-border bg-bg-container p-2 opacity-50"
    }
  >
    <Skeleton className={"h-11 w-11 shrink-0 rounded-sm"} />
    <div className={"w-full"}>
      <Skeleton className={"h-3 w-14 rounded-md"} />
    </div>
  </div>
));

DirectoryCardSkeleton.displayName = "DirectoryCardSkeleton";

export default DirectoryCard;
export {
  DirectoryCardSkeleton,
  DirectorySmallCardSkeleton,
  DirectorySmallCard,
};

import GroupElementHeader from "@/components/features/groups/layout/GroupElementHeader";
import { Button } from "@/components/ui/button/Button";
import { Label } from "@/components/ui/label/Label";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area/ScrollArea";
import { cn } from "@/lib/utils";
import { useCredentials } from "@/store/authStore";
import { GroupI } from "@/types/groups";
import { Book, CalendarPlus, ClipboardSignature, Folder } from "lucide-react";
import { Fragment, useCallback } from "react";
import { useNavigate } from "react-router";

type GroupQuickMenuProps = {
  className?: string;
  group?: GroupI;
};

export default function GroupQuickMenu({
  className,
  group,
}: GroupQuickMenuProps) {
  const navigate = useNavigate();
  const { lvl } = useCredentials();

  const handleOpenMeetingForm = useCallback(() => {
    navigate(`/calendar/?viewKey=day`, {
      state: {
        action: "createMeeting",
        defaultValues: { groups: [group] },
      },
    });
  }, [group, navigate]);

  return (
    <Fragment>
      <div
        className={cn(
          "flex flex-col rounded-lg border-1 border-border bg-bg-container",
          className,
        )}
      >
        <GroupElementHeader label={"Menu wyboru"} />
        <div
          className={cn(
            "grid px-4 pb-4",
            lvl > 0 ? "grid-cols-2 gap-4 sm:grid-cols-1" : "grid-cols-1",
          )}
        >
          {lvl > 0 && (
            <div className="flex flex-col gap-3">
              <Label className={cn("flex font-medium text-fg-muted")}>
                Szybkie akcje
              </Label>

              <ScrollArea className="pb-2">
                <div className={"flex w-full flex-col gap-2 sm:flex-row"}>
                  <Button
                    variant={"outline"}
                    variantColor={"muted"}
                    icon={<CalendarPlus />}
                    iconPosition={"left"}
                    className={"shrink-0 justify-start sm:w-fit"}
                    onClick={handleOpenMeetingForm}
                  >
                    Dodaj spotkanie
                  </Button>
                  <Button
                    disabled
                    variant={"outline"}
                    variantColor={"muted"}
                    icon={<Book />}
                    iconPosition={"left"}
                    className={"shrink-0 justify-start sm:w-fit"}
                  >
                    Dodaj lekcję
                  </Button>
                  <Button
                    disabled
                    variant={"outline"}
                    variantColor={"muted"}
                    icon={<ClipboardSignature />}
                    iconPosition={"left"}
                    className={"shrink-0 justify-start sm:w-fit"}
                  >
                    Dodaj zadanie
                  </Button>
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
            </div>
          )}
          <div className="flex flex-col gap-3">
            <Label
              className={cn("font-medium text-fg-muted", lvl > 0 || "hidden")}
            >
              Menu
            </Label>

            <ScrollArea className="pb-2">
              <div className={"flex w-full flex-col gap-2 sm:flex-row"}>
                <Button
                  disabled={!group?.directoryId}
                  variant={"outline"}
                  variantColor={"muted"}
                  icon={<Folder />}
                  iconPosition={"left"}
                  className={"shrink-0 justify-start sm:w-fit"}
                  onClick={() => navigate(`/fs/dir/${group?.directoryId}`)}
                >
                  Pliki
                </Button>
                <Button
                  disabled={true}
                  variant={"outline"}
                  variantColor={"muted"}
                  icon={<Book />}
                  iconPosition={"left"}
                  className={"shrink-0 justify-start sm:w-fit"}
                >
                  Lekcje
                </Button>
                <Button
                  disabled={true}
                  variant={"outline"}
                  variantColor={"muted"}
                  icon={<ClipboardSignature />}
                  iconPosition={"left"}
                  className={"shrink-0 justify-start sm:w-fit"}
                >
                  Zadania
                </Button>
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export function SerializeData(data: any): Record<string, any> | undefined {
  if (data === null || typeof data !== "object") {
    return undefined;
  }

  const result: Record<string, any> = {};
  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      result[key] = value.map((item) => (item === null ? undefined : item));
    } else if (typeof value === "object") {
      result[key] = SerializeData(value);
    } else {
      result[key] = value === null ? undefined : value;
    }
  }
  return result;
}
